export const TimeUnits = ['millisecond', 'second', 'minute', 'hour', 'day'] as const;
export type TimeUnit = typeof TimeUnits[number];
export const weekdays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

export const hasUTCTimeZoneIndicator = (date: Date | string): boolean => {
  const stringDate = date.toString();
  return /[Zz]$|([+-]\d{2}:\d{2})$/.test(stringDate);
};

export const convertServerUTCDateStrToLocalDate = (date: Date | string | undefined): Date | undefined => {
  return date ? new Date(`${date?.toString()}Z`) : undefined;
};

export const getUTCDate = (date: Date | string): Date =>
  hasUTCTimeZoneIndicator(date) ? new Date(date) : new Date(`${date}Z`);

const getStdTimezoneOffset = (date: Date): number => {
  const jan = new Date(date.getFullYear(), 0, 1);
  const jul = new Date(date.getFullYear(), 6, 1);
  return Math.max(jan.getTimezoneOffset(), jul.getTimezoneOffset());
};

const isDstObserved = (): boolean => {
  const date = new Date();
  return date.getTimezoneOffset() < getStdTimezoneOffset(date);
};

export const getLocalDateAndLocalTime = (
  date: Date | string,
  dateOptions?: Intl.DateTimeFormatOptions | undefined,
  timeOptions?: Intl.DateTimeFormatOptions | undefined
): { localDate: string; localTime: string } => {
  const utcDate = getUTCDate(date);
  return {
    localDate: utcDate.toLocaleDateString(undefined, dateOptions),
    localTime: utcDate.toLocaleTimeString(undefined, timeOptions),
  };
};

export const toLocalISOString = (date: Date | string): string => {
  const { localDate, localTime } = getLocalDateAndLocalTime(date, undefined, {
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    timeZoneName: 'short',
  });

  const getLocalISODate = (_localDate: Date) => {
    const dd = _localDate.getDate();
    const mm = _localDate.getMonth() + 1; //January is 0!
    const yyyy = _localDate.getFullYear();

    let ddString = dd.toString();
    let mmString = mm.toString();

    if (dd < 10) ddString = '0' + ddString;
    if (mm < 10) mmString = '0' + mmString;

    return yyyy + '-' + ddString + '-' + mmString;
  };

  const isoDate = getLocalISODate(new Date(localDate));
  return `${isoDate} ${localTime}`;
};

export const toNoOffsetISOString = (date: Date) => {
  // Creates a version of toIsoString() with timezone offsetting removed
  const localDate = new Date(date.getTime() - date.getTimezoneOffset() * 60000);
  return localDate.toISOString().slice(0, -1);
};

export const toLocalDateString = (date: Date | string | undefined, offset?: boolean): string => {
  if (!date) return '';
  if (!offset) return new Date(date).toLocaleDateString([], { month: '2-digit', day: '2-digit', year: '2-digit' });
  else {
    const dt = new Date(date);
    dt.setMinutes(dt.getMinutes() + dt.getTimezoneOffset());
    return dt.toLocaleDateString([], { month: '2-digit', day: '2-digit', year: '2-digit' });
  }
};

/*
Certain UTC dates coming back from our database are missing the zero UTC offset indicator 'Z'
Which can cause the value to be incorrectly interpretted as a localized date by Javascript
*/
export const toLocalDateStringWithUtcCheck = (date: Date | string | undefined, ignoreDst = false): string => {
  if (!date) return '';

  let utcDate: Date;

  if (typeof date === 'string') {
    const hasTimeZoneIndicator = hasUTCTimeZoneIndicator(date);
    const utcDateStr = `${date}${hasTimeZoneIndicator ? '' : 'Z'}`;
    utcDate = new Date(utcDateStr);
  } else {
    utcDate = new Date(date);
  }

  if (isNaN(utcDate.getTime())) {
    return 'Invalid Date';
  }

  if (ignoreDst && isDstObserved()) {
    utcDate.setHours(utcDate.getHours() - 1);
  }

  const localizedDate = toLocalDateString(utcDate, false);

  return localizedDate;
};

export const toYMDDateString = (date: Date | string, offset?: boolean): string => {
  if (!offset) return new Date(date).toISOString().split('T')[0];
  else {
    const dt = new Date(date);
    dt.setMinutes(dt.getMinutes() + dt.getTimezoneOffset());
    return dt.toISOString().split('T')[0];
  }
};

export const toLocalFullDateString = (date: Date | string, offset?: boolean): string => {
  if (!offset) return new Date(date).toLocaleDateString([], { month: '2-digit', day: '2-digit', year: 'numeric' });
  else {
    const dt = new Date(date);
    dt.setMinutes(dt.getMinutes() + dt.getTimezoneOffset());
    return dt.toLocaleDateString([], { month: '2-digit', day: '2-digit', year: 'numeric' });
  }
};

export const toLocalDateStringLong = (date: Date | string, offset?: boolean): string => {
  if (!offset) return new Date(date).toLocaleDateString([], { month: 'long', day: '2-digit', year: 'numeric' });
  else {
    const dt = new Date(date);
    dt.setMinutes(dt.getMinutes() + dt.getTimezoneOffset());
    return dt.toLocaleDateString([], { month: 'long', day: '2-digit', year: 'numeric' });
  }
};

export const toShortLocalTimeString = (date: Date | string): string => {
  const localDate = new Date(date);
  return localDate.toLocaleTimeString([], { hour: 'numeric', minute: 'numeric' });
};

export const toLocalDateTimeString = (date: Date | string, ignoreDst = false, separator = ' '): string => {
  const hasTimeZoneIndicator = hasUTCTimeZoneIndicator(date);
  const utcDate = new Date(`${date}${hasTimeZoneIndicator ? '' : 'Z'}`);

  if (ignoreDst && isDstObserved()) utcDate.setHours(utcDate.getHours() - 1);
  return (
    utcDate.toLocaleDateString([], { month: '2-digit', day: '2-digit', year: '2-digit' }) +
    separator +
    utcDate.toLocaleTimeString([], { hour: 'numeric', minute: '2-digit' })
  );
};

export const getSecondsBetween = (date1: Date | string, date2: Date | string): number => {
  const nDate1 = new Date(date1);
  const nDate2 = new Date(date2);

  const milSecondsBetween = nDate1.getTime() - nDate2.getTime();
  return milSecondsBetween / getTimeUnitMillisecondFactor('second');
};

export const getHoursBetween = (date1: Date | string, date2: Date | string): number => {
  const nDate1 = new Date(date1);
  const nDate2 = new Date(date2);

  const milSecondsBetween = nDate1.getTime() - nDate2.getTime();
  return milSecondsBetween / getTimeUnitMillisecondFactor('hour');
};

export const subtractMonths = (date: Date | string, count: number): Date => {
  const localDate = new Date(date);
  localDate.setMonth(localDate.getMonth() - count);
  return localDate;
};

export const toWeekdayFormat = (date: Date | string): string => {
  const localDate = new Date(date);
  return `${localDate.toLocaleString('default', {
    weekday: 'short',
  })} ${localDate.getMonth() + 1}/${localDate.getDate()} `;
};

export const toWeekdayFormatLong = (date: Date | string, short?: boolean): string => {
  const digits = short ? 'numeric' : '2-digit';
  const localDate = new Date(date);
  return `${localDate.toLocaleString('default', {
    weekday: 'short',
  })}, ${localDate.toLocaleDateString([], { month: digits, day: digits, year: '2-digit' })} `;
};

export const toShortYearDateString = (date: Date | string, short?: boolean): string => {
  const digits = short ? 'numeric' : '2-digit';
  const localDate = new Date(date);
  return localDate.toLocaleDateString([], { month: digits, day: digits, year: '2-digit' });
};

export const toUTC = (d: Date | string): Date => {
  const date = new Date(d);
  date.setUTCFullYear(date.getFullYear());
  date.setUTCMonth(date.getMonth());
  date.setUTCDate(date.getDate());
  date.setUTCHours(date.getHours());
  date.setUTCMinutes(date.getMinutes());
  date.setUTCSeconds(date.getSeconds());
  date.setUTCMilliseconds(date.getMilliseconds());
  return date;
};

export const truncateNullableTime = (date: Date | null): Date | null => {
  if (!date) return date;

  return truncateTime(date);
};

export const truncateTime = (date: Date): Date => {
  date.setHours(0);
  date.setMinutes(0);
  date.setSeconds(0);
  date.setMilliseconds(0);
  return date;
};

// Returns day of year [1, 366] (366 days for leap years)
export const toDayOfYear = (date: Date): number => {
  return (
    (Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()) - Date.UTC(date.getFullYear(), 0, 0)) /
    getTimeUnitMillisecondFactor('day')
  );
};

export const addDays = (date: Date | string, days: number): Date => {
  const result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
};

export const addTime = (date: Date | string, time: number, unit: TimeUnit) => {
  date = new Date(date);
  date.setMilliseconds(date.getMilliseconds() + convertToMilliseconds(time, unit));
  return date;
};

export const parseYMD = (ymd: string | undefined): Date | undefined => {
  if (!ymd?.match(/^\d{4}-\d{2}-\d{2}$/)) return undefined;
  const parts = ymd.split(/\D/);
  return new Date(+parts[0], +parts[1] - 1, +parts[2]);
};

export const getDateFromEvent = (
  e:
    | React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
    | React.KeyboardEvent<HTMLTextAreaElement | HTMLInputElement>
): Date | null => {
  const dateString = e?.currentTarget?.value;
  if (!dateString || !isValidDateString(dateString)) return null;

  const d = new Date(`${dateString}`);
  const utc = new Date(`${d.getUTCFullYear()}/${d.getUTCMonth() + 1}/${d.getUTCDate()}`);
  return utc;
};

export const isValidDateString = (str: string): boolean => {
  const num = Date.parse(str);
  return !isNaN(num);
};

export const getDateString = (date?: Date | null, options?: Intl.DateTimeFormatOptions): string => {
  if (!date) return '';
  return options ? date.toLocaleDateString('en', options) : date.toISOString().split('T')[0];
};

export const getTimestampDisplay = (timestamp: string): string => {
  const date = new Date(timestamp);
  const offset = -date.getTimezoneOffset();
  let offsetString = '(UTC';
  if (offset !== 0) {
    const sign = offset < 0 ? '-' : '+';
    offsetString += ' ' + sign;
    offsetString += `${(Math.abs(offset) / 60).toString().padStart(2, '0')}:${(Math.abs(offset) % 60)
      .toString()
      .padStart(2, '0')}`;
  }
  offsetString += ')';
  return `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date
    .getDate()
    .toString()
    .padStart(2, '0')} at ${toShortLocalTimeString(date)} ${offsetString}`;
};
export const DateFormatOptions: Intl.DateTimeFormatOptions = {
  year: '2-digit',
  month: '2-digit',
  day: '2-digit',
  formatMatcher: 'basic',
};

export const getTimeZone = (): string => Intl.DateTimeFormat().resolvedOptions().timeZone;

export const getTimeUnitMillisecondFactor = (unit: TimeUnit): number => {
  const i = TimeUnits.findIndex((u) => u === unit);
  const millsecondFactor = getTimeUnitFactors('millisecond', unit).reduce((p, c) => p * c, 1);
  return millsecondFactor;
};

export const getTimeUnitFactors = (fromUnit: TimeUnit, toUnit: TimeUnit): number[] => {
  const start = TimeUnits.indexOf(fromUnit);
  const end = TimeUnits.indexOf(toUnit);
  const unitFactors = [1, 1000, 60, 60, 24].slice(start, end + 1);
  return unitFactors;
};

export const getTimeUnitModulus = (unit: TimeUnit): number => {
  const nextUnit = TimeUnits[TimeUnits.indexOf(unit) + 1];
  return nextUnit ? getTimeUnitFactors(nextUnit, nextUnit).reduce((p, c) => p * c, 1) : 365;
};

export const convertToMilliseconds = (fromValue: number, fromUnit: TimeUnit): number => {
  return fromValue * getTimeUnitMillisecondFactor(fromUnit);
};

export const convertTimeToUnit = (fromValue: number, fromUnit: TimeUnit, toUnit: TimeUnit, round?: boolean): number => {
  const milliseconds = convertToMilliseconds(fromValue, fromUnit);
  const factor = getTimeUnitMillisecondFactor(toUnit);
  const result = milliseconds / factor;
  return round ? Math.round(result) : result;
};

export const getTimeBetween = (
  start: Date | string | undefined,
  end: Date | string | undefined,
  unit?: TimeUnit,
  round?: boolean
): number => {
  start = start ? new Date(start) : new Date();
  end = end ? new Date(end) : start;
  const totalTime = end.getTime() - start.getTime();
  const result = convertTimeToUnit(totalTime, 'millisecond', unit ?? 'millisecond', round);

  return result;
};
