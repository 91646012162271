import { Box, Button, Dialog, styled, Typography } from '@mui/material';

export const StyledBasicDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiPaper-root.MuiDialog-paper': {
    borderRadius: 8,
    margin: theme.spacing(3),
  },
}));

export const StyledDialog = styled(StyledBasicDialog)(({ theme }) => ({
  '& .MuiPaper-root.MuiDialog-paper': {
    [theme.breakpoints.up('xs')]: {
      maxWidth: 'calc(100vw)',
    },

    [theme.breakpoints.up('xl')]: {
      maxWidth: theme.custom?.contentWidths?.['xl'] ?? '',
    },
  },
}));

export const StyledDialogContainer = styled(Box)({
  overflow: 'hidden',
});

export const StyledDialogHeader = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
}));

export const StyledDialogHeaderTypography = styled(Typography)<{ ignoreTextTransform?: boolean }>(
  ({ theme, ignoreTextTransform }) => ({
    '&': { ...theme.typography.h4 },
    textTransform: ignoreTextTransform ? 'initial' : 'lowercase',
    '&:first-letter': {
      textTransform: ignoreTextTransform ? 'initial' : 'uppercase',
    },
  })
);

export const StyledDialogFooter = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'flex-end',
  gap: theme.spacing(1),
}));

export const StyledDialogButton = styled(Button)(({ theme }) => ({
  [theme.breakpoints.up('xs')]: {
    height: 40,
  },
  [theme.breakpoints.up('xl')]: {
    height: 32,
  },
}));

export const StyledDialogButtonTypographyBox = styled(Box)(({ theme }) => ({
  textTransform: 'lowercase',
  '&:first-letter': {
    textTransform: 'uppercase',
  },
  [theme.breakpoints.up('xs')]: {
    ...theme.typography.mobileParagraphBold,
  },
  [theme.breakpoints.up('xl')]: {
    ...theme.typography.paragraphBold,
  },
}));

export const StyledDialogContent = styled(Box)(({ theme }) => ({
  paddingBottom: theme.spacing(1),
  paddingRight: theme.spacing(2),
  [theme.breakpoints.up('xs')]: {
    ...theme.typography.mobileParagraph,
  },
  [theme.breakpoints.up('xl')]: {
    ...theme.typography.paragraph,
  },
}));
