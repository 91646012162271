import { ServiceResult } from '../models/api-shared.models';
import {
  GetInventoryEntrySearchByProductNumberRequest,
  GetInventoryEntrySearchByProductNumberResultDetail,
  GetInventoryEntrySearchRequest,
  GetInventoryEntrySearchResultDetail,
} from '../models/inventory-entry-search.models';
import ApiService from './api-service';

class InventoryEntrySearchService extends ApiService {
  private static classInstance: InventoryEntrySearchService;

  private constructor() {
    super('InventoryEntrySearch');
  }

  public getInventoryEntrySearch(
    request: GetInventoryEntrySearchRequest
  ): ServiceResult<GetInventoryEntrySearchResultDetail> {
    return this.post(`${this.persistUrl}/ProductListInventoryEntrySearch`, request);
  }

  public getInventoryEntrySearchByProductNumber(
    request: GetInventoryEntrySearchByProductNumberRequest
  ): ServiceResult<GetInventoryEntrySearchByProductNumberResultDetail> {
    return this.post(`${this.persistUrl}/InventoryEntrySearchByProductNumber`, request);
  }

  public static getInstance(): InventoryEntrySearchService {
    if (!this.classInstance) {
      this.classInstance = new InventoryEntrySearchService();
    }
    return this.classInstance;
  }
}

export default InventoryEntrySearchService;
