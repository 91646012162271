import { FileResult, ServiceResult } from '../models/api-shared.models';
import {
  CreateOrderEntryHeaderRequest,
  DeleteOrderEntryHeaderRequest,
  DeliveryDateResult,
  GetActiveOrderRequest,
  GetActiveOrderResult,
  GetConfirmationOrderEntryHeaderByCustomersRequest,
  GetConfirmationOrderEntryHeadersRequest,
  GetCustomerOrderEntryHeadersRequest,
  GetCustomersOrderEntryHeadersRequest,
  GetFutureOrderEntryHeadersRequest,
  OrderEntryHeader,
  OrderEntryHeaderName,
  PurchaseOrderNumberResult,
  RecallFutureOrderRequest,
  SubmitOrderEntryHeaderRequest,
  SubmitOrderEntryHeaderResultObject,
  SubmitOrderEntryHeaderToProcurementRequest,
  UpdateOrderEntryHeaderDeliveryDateRequest,
  UpdateOrderEntryHeaderOrderEntryHeaderNameRequest,
  UpdateOrderEntryHeaderPurchaseOrderNumberRequest,
} from '../models/order-entry-header.models';
import {
  CreateSmartOrderEntryHeaderRequest,
  DownloadOrderImportSpreadsheetTemplateRequest,
  ImportOrderDelimitedRequest,
  ImportOrderDelimitedResultData,
  ImportOrderSpreadsheetRequest,
  ImportOrderSpreadsheetResultData,
  ValidateImportOrderDelimitedRequest,
  ValidateImportOrderSpreadsheetRequest,
  ValidateImportOrderSpreadsheetResultData,
} from '../models/order-import.models';
import { GetOrderRequest } from '../models/order.models';
import ApiService from './api-service';

class OrderEntryHeaderService extends ApiService {
  private static classInstance: OrderEntryHeaderService;
  private getCustomersOrderEntryHeadersAbortController: AbortController;
  private getConfirmationOrderEntryHeaderByCustomersAbortController: AbortController;

  private constructor() {
    super('OrderEntryHeader');
    this.getCustomersOrderEntryHeadersAbortController = new AbortController();
    this.getConfirmationOrderEntryHeaderByCustomersAbortController = new AbortController();
  }

  public abortGetCustomersOrderEntryHeaders = (): void => {
    this.getCustomersOrderEntryHeadersAbortController.abort();
  };

  public getCustomersOrderEntryHeaders(
    request: GetCustomersOrderEntryHeadersRequest
  ): ServiceResult<OrderEntryHeader[]> {
    this.getCustomersOrderEntryHeadersAbortController = new AbortController();
    return this.post(
      `${this.persistUrl}/GetCustomersOrderEntryHeaders`,
      request.customerIds,
      undefined,
      this.getCustomersOrderEntryHeadersAbortController.signal
    );
  }

  public abortGetConfirmationOrderEntryHeaderByCustomers = (): void => {
    this.getConfirmationOrderEntryHeaderByCustomersAbortController.abort();
  };

  public getConfirmationOrderEntryHeaderByCustomers(
    request: GetConfirmationOrderEntryHeaderByCustomersRequest
  ): ServiceResult<OrderEntryHeader[]> {
    this.getConfirmationOrderEntryHeaderByCustomersAbortController = new AbortController();
    return this.post(
      `${this.persistUrl}/GetConfirmationOrderEntryHeaderByCustomers`,
      request,
      undefined,
      this.getConfirmationOrderEntryHeaderByCustomersAbortController.signal
    );
  }

  public updateOrderEntryHeaderName(
    request: UpdateOrderEntryHeaderOrderEntryHeaderNameRequest
  ): ServiceResult<OrderEntryHeaderName> {
    return this.post(`${this.baseUrl}/UpdateOrderEntryHeaderOrderEntryHeaderName`, request);
  }

  public updateOrderEntryHeaderPurchaseOrderNumber(
    request: UpdateOrderEntryHeaderPurchaseOrderNumberRequest
  ): ServiceResult<PurchaseOrderNumberResult> {
    return this.post(`${this.baseUrl}/UpdateOrderEntryHeaderPurchaseOrderNumber`, request);
  }

  public createOrderEntryHeader(request: CreateOrderEntryHeaderRequest): ServiceResult<OrderEntryHeader> {
    return this.post(`${this.baseUrl}/CreateOrderEntryHeader`, request);
  }

  public getOrderEntryHeader(request: GetOrderRequest): ServiceResult<OrderEntryHeader> {
    return this.get(`${this.baseUrl}/GetOrderEntryHeader`, request);
  }

  public getCustomerOrderEntryHeaders(request: GetCustomerOrderEntryHeadersRequest): ServiceResult<OrderEntryHeader[]> {
    return this.get(`${this.persistUrl}/GetCustomerOrderEntryHeaders`, request);
  }

  public getConfirmationOrderEntryHeaders(
    request: GetConfirmationOrderEntryHeadersRequest
  ): ServiceResult<OrderEntryHeader[]> {
    return this.get(`${this.persistUrl}/GetConfirmationOrderEntryHeaders`, request);
  }

  public GetFutureOrderEntryHeaders(request: GetFutureOrderEntryHeadersRequest): ServiceResult<OrderEntryHeader[]> {
    return this.post(`${this.persistUrl}/GetFutureOrderEntryHeaders`, request.customerId);
  }

  public updateDeliveryDate(request: UpdateOrderEntryHeaderDeliveryDateRequest): ServiceResult<DeliveryDateResult> {
    return this.post(`${this.baseUrl}/UpdateOrderEntryHeaderDeliveryDate`, request);
  }

  public submitOrderEntryHeader(
    request: SubmitOrderEntryHeaderRequest
  ): ServiceResult<SubmitOrderEntryHeaderResultObject> {
    return this.post(`${this.persistUrl}/SubmitOrderEntryHeader`, request, 'queryParams');
  }

  public submitOrderEntryHeaderToProcurement(
    request: SubmitOrderEntryHeaderToProcurementRequest
  ): ServiceResult<SubmitOrderEntryHeaderResultObject> {
    return this.post(`${this.persistUrl}/SubmitOrderEntryHeaderToProcurement`, request, 'queryParams');
  }

  public deleteOrderEntryHeader(request: DeleteOrderEntryHeaderRequest): ServiceResult<undefined> {
    return this.post(`${this.baseUrl}/DeleteOrderEntryHeader`, request, 'queryParams');
  }

  public getActiveOrder(request: GetActiveOrderRequest): ServiceResult<GetActiveOrderResult> {
    return this.get(`${this.baseUrl}/GetActiveOrder`, request);
  }

  public validateImportOrderSpreadsheet(
    request: ValidateImportOrderSpreadsheetRequest
  ): ServiceResult<ValidateImportOrderSpreadsheetResultData> {
    return this.post(`${this.baseUrl}/ValidateImportOrderSpreadsheet`, request);
  }

  public getOrderImportSpreadsheetTemplate(
    request: DownloadOrderImportSpreadsheetTemplateRequest
  ): ServiceResult<FileResult> {
    return this.get(`${this.baseUrl}/DownloadOrderImportSpreadsheetTemplate`, request);
  }

  public validateImportOrderDelimited(
    request: ValidateImportOrderDelimitedRequest
  ): ServiceResult<ValidateImportOrderSpreadsheetResultData> {
    return this.post(`${this.baseUrl}/ValidateImportOrderDelimited`, request);
  }

  public importOrderSpreadsheet(
    request: ImportOrderSpreadsheetRequest
  ): ServiceResult<ImportOrderSpreadsheetResultData> {
    return this.post(`${this.persistUrl}/ImportOrderSpreadsheet`, request);
  }

  public importOrderDelimited(request: ImportOrderDelimitedRequest): ServiceResult<ImportOrderDelimitedResultData> {
    return this.post(`${this.persistUrl}/ImportOrderDelimited`, request);
  }
  public recallFutureOrder(request: RecallFutureOrderRequest): ServiceResult<OrderEntryHeader> {
    return this.post(`${this.baseUrl}/RecallFutureOrder`, request);
  }

  public createSmartOrderEntryHeader(request: CreateSmartOrderEntryHeaderRequest): ServiceResult<OrderEntryHeader> {
    return this.post(`${this.baseUrl}/CreateSmartOrderEntryHeader`, request);
  }

  public static getInstance(): OrderEntryHeaderService {
    if (!this.classInstance) {
      this.classInstance = new OrderEntryHeaderService();
    }
    return this.classInstance;
  }
}

export default OrderEntryHeaderService;
