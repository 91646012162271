import { ServiceResult } from '../models/api-shared.models';
import {
  InventorySnapshotDetail,
  ResetInventorySnapshotDetailCustomPackSizesRequest,
  ResetInventorySnapshotDetailsCustomPackSizesRequest,
  UpdateInventorySnapshotDetailRequest,
} from '../models/inventory-snapshot.models';
import ApiService from './api-service';

class InventorySnapshotDetailService extends ApiService {
  private static classInstance: InventorySnapshotDetailService;

  private constructor() {
    super('InventorySnapshotDetail');
  }

  public updateInventorySnapshotDetail(
    request: UpdateInventorySnapshotDetailRequest
  ): ServiceResult<InventorySnapshotDetail> {
    return this.post(`${this.baseUrl}/UpdateInventorySnapshotDetail`, request);
  }

  public resetInventorySnapshotDetailCustomPackSizes(request: ResetInventorySnapshotDetailCustomPackSizesRequest) {
    return this.post(`${this.baseUrl}/ResetInventorySnapshotDetailCustomPackSizes`, request);
  }

  public resetInventorySnapshotDetailsCustomPackSizes(request: ResetInventorySnapshotDetailsCustomPackSizesRequest) {
    return this.post(`${this.baseUrl}/ResetInventorySnapshotDetailsCustomPackSizes`, request);
  }

  public static getInstance(): InventorySnapshotDetailService {
    if (!this.classInstance) {
      this.classInstance = new InventorySnapshotDetailService();
    }
    return this.classInstance;
  }
}

export default InventorySnapshotDetailService;
