import { ServiceResult } from '../models/api-shared.models';
import {
  GetInventorySnapshotSearchRequest,
  GetInventorySnapshotSearchResultDetail,
  InventorySnapshotSearchSortOption,
} from '../models/inventory-snapshot.models';
import ApiService from './api-service';

class InventorySnapshotSearchService extends ApiService {
  private static classInstance: InventorySnapshotSearchService;

  private constructor() {
    super('InventorySnapshotSearch');
  }

  public getInventorySnapshotSearch(
    request: GetInventorySnapshotSearchRequest
  ): ServiceResult<GetInventorySnapshotSearchResultDetail> {
    return this.post(`${this.persistUrl}/ProductListInventorySnapshotSearch`, request);
  }

  public getInventorySnapshotSearchSort(): ServiceResult<InventorySnapshotSearchSortOption[]> {
    return this.post(`${this.persistUrl}/GetInventorySnapshotSearchSort`, undefined);
  }

  public static getInstance(): InventorySnapshotSearchService {
    if (!this.classInstance) {
      this.classInstance = new InventorySnapshotSearchService();
    }
    return this.classInstance;
  }
}

export default InventorySnapshotSearchService;
