import { CatalogProduct, FileResult, ServiceResult } from '../models/api-shared.models';
import {
  GetProductDetailDocumentHeadersRequest,
  GetProductDetailDocumentRequest,
  GetProductDetailPdfRequest,
  GetProductDetailRequest,
  GetProductInformationAllergensAndAdditionalRequest,
  GetProductInformationDescriptionRequest,
  GetProductInformationPackingAndStorageRequest,
  ProductDetailDocument,
  ProductInformationAllergensAndAdditional,
  ProductInformationDescription,
  ProductInformationPackingAndStorage,
  ReportIncorrectProductInformation,
  RequestProductNutritionalInformation,
} from '../models/product-detail.models';
import ApiService from './api-service';

class ProductDetailService extends ApiService {
  private static classInstance: ProductDetailService;

  private constructor() {
    super('ProductDetail');
  }

  public requestProductNutritionalInformation(request: RequestProductNutritionalInformation): ServiceResult<undefined> {
    return this.post(`${this.baseUrl}/RequestProductNutritionalInformation`, request);
  }

  public reportIncorrectProductInformation(request: ReportIncorrectProductInformation): ServiceResult<undefined> {
    return this.post(`${this.baseUrl}/ReportIncorrectProductInformation`, request);
  }

  public getProductDetail(request: GetProductDetailRequest): ServiceResult<CatalogProduct> {
    return this.get(`${this.baseUrl}/GetProductDetail`, request);
  }

  public getProductInformationDescription(
    request: GetProductInformationDescriptionRequest
  ): ServiceResult<ProductInformationDescription> {
    return this.get(`${this.baseUrl}/GetProductInformationDescription`, request);
  }

  public getProductInformationAllergensAndAdditional(
    request: GetProductInformationAllergensAndAdditionalRequest
  ): ServiceResult<ProductInformationAllergensAndAdditional> {
    return this.get(`${this.baseUrl}/GetProductInformationAllergensAndAdditional`, request);
  }

  public getProductInformationPackingAndStorage(
    request: GetProductInformationPackingAndStorageRequest
  ): ServiceResult<ProductInformationPackingAndStorage> {
    return this.get(`${this.baseUrl}/GetProductInformationPackingAndStorage`, request);
  }

  public getProductDetailPdf(request: GetProductDetailPdfRequest): ServiceResult<FileResult> {
    return this.post(`${this.baseUrl}/GetProductDetailReportPDF`, request);
  }

  public getProductDetailDocumentHeaders(
    request: GetProductDetailDocumentHeadersRequest
  ): ServiceResult<ProductDetailDocument[]> {
    return this.get(`${this.baseUrl}/GetProductDetailDocumentHeaders`, request);
  }

  public getProductDetailDocument(request: GetProductDetailDocumentRequest): ServiceResult<FileResult> {
    return this.get(`${this.baseUrl}/GetProductDetailDocument`, request);
  }

  public static getInstance(): ProductDetailService {
    if (!this.classInstance) {
      this.classInstance = new ProductDetailService();
    }
    return this.classInstance;
  }
}

export default ProductDetailService;
