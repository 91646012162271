import { ServiceResult } from '../models/api-shared.models';
import {
  CreateProductListDetailRequest,
  DeleteProductListDetailByProductKeyRequest,
  DeleteProductListDetailRequest,
  GetProductHistorySettingRequest,
  GetProductHistorySettingResultData,
  GetProductListDetailsCountRequest,
  GetProductListsDetailCountResultData,
  GetProductListsDetailsCountRequest,
  MoveToCategoryProductListDetailRequest,
  ProductListDetail,
  ReplaceProductListDetailForMultiUnitAccountRequest,
  ReplaceProductListDetailRequest,
  SequenceProductListDetailRequest,
  SequenceProductListDetailResultDetail,
  SequenceProductListDetailsRequest,
  UpdateHideProductRequest,
  UpdateProductHistoryRetentionRequest,
} from '../models/product-list-detail.models';
import ApiService from './api-service';
class ProductListDetailService extends ApiService {
  private static classInstance: ProductListDetailService;

  private constructor() {
    super('ProductListDetail');
  }

  public getProductHistorySetting(
    request: GetProductHistorySettingRequest
  ): ServiceResult<GetProductHistorySettingResultData> {
    return this.get(`${this.baseUrl}/GetProductHistorySetting`, request);
  }

  public getProductListDetailsCount(request: GetProductListDetailsCountRequest): ServiceResult<number> {
    return this.get(`${this.baseUrl}/GetProductListDetailsCount`, request);
  }

  public getProductListsDetailsCount(
    request: GetProductListsDetailsCountRequest
  ): ServiceResult<GetProductListsDetailCountResultData[]> {
    return this.post(`${this.baseUrl}/GetProductListsDetailsCount`, request);
  }

  public updateHideProductResult(request: UpdateHideProductRequest): ServiceResult<undefined> {
    return this.post(`${this.baseUrl}/UpdateHideProduct`, request);
  }

  public updateProductHistoryRetention(request: UpdateProductHistoryRetentionRequest): ServiceResult<undefined> {
    return this.post(`${this.baseUrl}/UpdateProductHistoryRetention`, request);
  }

  public async sequenceProductListDetail(
    request: SequenceProductListDetailRequest
  ): ServiceResult<SequenceProductListDetailResultDetail> {
    return this.post(`${this.baseUrl}/SequenceProductListDetail`, request);
  }

  public async sequenceProductListDetails(request: SequenceProductListDetailsRequest): ServiceResult<undefined> {
    return this.post(`${this.baseUrl}/SequenceProductListDetails`, request);
  }

  public deleteProductListDetail(request: { productListDetailId: string }): ServiceResult<undefined> {
    return this.post(`${this.baseUrl}/DeleteProductListDetail`, request, 'queryParams');
  }

  public createProductListDetail(request: CreateProductListDetailRequest): ServiceResult<ProductListDetail[]> {
    return this.post(`${this.baseUrl}/CreateProductListDetail`, request);
  }

  public deleteProductListDetails(request: string[]): ServiceResult<undefined> {
    return this.post(`${this.baseUrl}/DeleteProductListDetails`, request);
  }

  public deleteProductListDetailByProductKey(
    request: DeleteProductListDetailByProductKeyRequest
  ): ServiceResult<undefined> {
    return this.post(`${this.baseUrl}/DeleteProductListDetailByProductKey`, request);
  }

  public deleteProductListDetailByProductKeys(request: DeleteProductListDetailRequest[]): ServiceResult<undefined> {
    return this.post(`${this.baseUrl}/DeleteProductListDetailByProductKeys`, request);
  }

  public replaceProductListDetail(request: ReplaceProductListDetailRequest): ServiceResult<undefined> {
    return this.post(`${this.baseUrl}/ReplaceProductListDetail`, request);
  }

  public replaceProductListDetailForMultiUnitAccount(
    request: ReplaceProductListDetailForMultiUnitAccountRequest
  ): ServiceResult<undefined> {
    return this.post(`${this.baseUrl}/ReplaceProductListDetailForMultiUnitAccount`, request);
  }

  public moveToCategoryProductListDetail(request: MoveToCategoryProductListDetailRequest): ServiceResult<undefined> {
    return this.post(`${this.baseUrl}/MoveToCategoryProductListDetail`, request);
  }

  public static getInstance(): ProductListDetailService {
    if (!this.classInstance) {
      this.classInstance = new ProductListDetailService();
    }
    return this.classInstance;
  }
}

export default ProductListDetailService;
