import { createEntityAdapter, createSelector, createSlice, EntityState, PayloadAction } from '@reduxjs/toolkit';
import {
  UnitOfMeasures,
  VendorProduct,
  VendorProductListRequest,
  VendorProductListSortOption,
} from '../../api/models/vendor-products.models';
import { normalizeKey, padCurrencyValue } from '../../helpers';
import { RootState } from '../store';

const vendorProductAdapter = createEntityAdapter<VendorProduct>({
  selectId: (product: VendorProduct) => normalizeKey(product.ThirdPartyVendorProductId),
});

// State
interface VendorProductState {
  vendorProductListRequest: VendorProductListRequest | undefined;
  isLoadingVendorProductList: boolean;
  isCreateOrUpdateProductLoading: boolean;
  product: VendorProduct | undefined;
  vendorProductManage: EntityState<VendorProduct>;
  isLoadingVendorProduct: boolean;
  uoms: UnitOfMeasures[];
  sortByOptions: VendorProductListSortOption[];
  isDeleteProductLoading: boolean;

  checkedProducts: EntityState<VendorProduct>;
}

const initialState: VendorProductState = {
  vendorProductListRequest: undefined,
  isLoadingVendorProductList: false,
  product: undefined,
  isCreateOrUpdateProductLoading: false,
  isLoadingVendorProduct: false,
  uoms: [],
  vendorProductManage: vendorProductAdapter.getInitialState(),
  isDeleteProductLoading: false,
  sortByOptions: [
    {
      Title: 'Alphabetical',
      Value: 0,
    },
    {
      Title: 'Product number',
      Value: 1,
    },
  ],
  checkedProducts: vendorProductAdapter.getInitialState(),
};

// Reducers
export const vendorProductSlice = createSlice({
  name: 'vendorProduct',
  initialState: initialState,
  reducers: {
    resetState: () => initialState,
    setIsLoadingVendorProduct: (state: VendorProductState, action: PayloadAction<boolean>) => {
      state.isLoadingVendorProduct = action.payload;
    },
    setIsLoadingVendorList: (state: VendorProductState, action: PayloadAction<boolean>) => {
      state.isLoadingVendorProductList = action.payload;
    },
    resetVendorList: (state: VendorProductState) => {
      state.vendorProductListRequest = initialState.vendorProductListRequest;
      state.vendorProductManage = initialState.vendorProductManage;
      state.isLoadingVendorProductList = initialState.isLoadingVendorProductList;
    },
    setVendorProductListAdapter: (state: VendorProductState, action: PayloadAction<VendorProduct[]>) => {
      const products = action.payload;

      for (const product of products) {
        for (const uom of product.ThirdPartyVendorProductUnitOfMeasures) {
          uom.Price = padCurrencyValue(uom.Price);
        }
      }
      vendorProductAdapter.setAll(state.vendorProductManage, products);
    },
    setProduct: (state: VendorProductState, action: PayloadAction<VendorProduct>) => {
      const product = action.payload;

      for (const uom of product.ThirdPartyVendorProductUnitOfMeasures) {
        uom.Price = padCurrencyValue(uom.Price);
      }

      state.product = product;
      vendorProductAdapter.upsertOne(state.vendorProductManage, product);
    },
    resetProduct: (state: VendorProductState) => {
      state.product = initialState.product;
      state.isLoadingVendorProduct = initialState.isLoadingVendorProduct;
    },
    setIsDeleteProductLoading: (state: VendorProductState, action: PayloadAction<boolean>) => {
      state.isDeleteProductLoading = action.payload;
    },
    setIsCreateOrUpdateProductLoading: (state: VendorProductState, action: PayloadAction<boolean>) => {
      state.isCreateOrUpdateProductLoading = action.payload;
    },
    setUOMs: (state: VendorProductState, action: PayloadAction<UnitOfMeasures[]>) => {
      state.uoms = action.payload;
    },
    resetUOMs: (state: VendorProductState) => {
      state.uoms = initialState.uoms;
    },

    updateProductIsChecked: (
      state: VendorProductState,
      action: PayloadAction<{ isChecked: boolean; id: string }[]>
    ) => {
      action.payload.forEach((item) => {
        const product = state.vendorProductManage.entities[item.id];
        if (!product) return;

        if (item.isChecked) vendorProductAdapter.upsertOne(state.checkedProducts, product);
        else vendorProductAdapter.removeOne(state.checkedProducts, item.id);
      });
    },
    updateAllProductsIsChecked: (state: VendorProductState, action: PayloadAction<boolean>) => {
      state.vendorProductManage.ids.forEach((id) => {
        const product = state.vendorProductManage.entities[id];
        if (!product) return;

        if (action.payload) vendorProductAdapter.upsertOne(state.checkedProducts, product);
        else vendorProductAdapter.removeOne(state.checkedProducts, id);
      });
    },
    setVendorProductIsOnList: (state: VendorProductState, action: PayloadAction<{ id: string; isOnList: boolean }>) => {
      const product = state.vendorProductManage.entities[action.payload.id];
      if (product) product.IsOnList = action.payload.isOnList;
    },
    setVendorProductListRequest: (state: VendorProductState, action: PayloadAction<VendorProductListRequest>) => {
      state.vendorProductListRequest = action.payload;
    },
  },
});
const getState = (state: VendorProductState): VendorProductState => state;
const getId = (state: VendorProductState, id: string): string => id;

export const {
  selectAll: selectAllVendorProducts,
  selectIds: selectAllVendorProductIds,
  selectById: selectVendorProductsId,
} = vendorProductAdapter.getSelectors<RootState>((state: RootState) => state.vendorProductSlice.vendorProductManage);

export const { selectAll: selectAllCheckedVendorProducts } = vendorProductAdapter.getSelectors<RootState>(
  (state: RootState) => state.vendorProductSlice.checkedProducts
);

export const selectVendorProductsLimitedUOMs = createSelector(
  [(state: RootState, productId: string) => selectVendorProductsId(state, productId)],
  (product) => {
    if (!product || !product.ThirdPartyVendorProductUnitOfMeasures) return product;
    const nonHiddenUoms = product.ThirdPartyVendorProductUnitOfMeasures.filter((c) => c.IsVisible);
    const hiddenUoms = product.ThirdPartyVendorProductUnitOfMeasures.filter((c) => !c.IsVisible);

    const limitedUOMs = [...nonHiddenUoms.slice(0, 3)];

    if (limitedUOMs.length < 3) {
      limitedUOMs.push(...hiddenUoms.slice(0, 3 - limitedUOMs.length));
    }
    return {
      ...product,
      ThirdPartyVendorProductUnitOfMeasures: limitedUOMs,
    };
  }
);

export const selectAllVendorProductsLimitedUOMs = (state: RootState) => {
  const productIds = selectAllVendorProductIds(state);
  return productIds.map((id) => selectVendorProductsLimitedUOMs(state, id as string));
};

export const selectIsAddToVendorChecked = createSelector([getState, getId], (state, id) => {
  const checkedProduct = state.checkedProducts.entities[id];
  return Boolean(checkedProduct);
});

export const selectTotalAddFromVendorProducts = createSelector([getState], (state) => {
  let total = state.vendorProductManage.ids.length;
  state.checkedProducts.ids.forEach((id) => {
    const product = state.vendorProductManage.entities[id];
    if (!product) total++;
  });
  return total;
});
