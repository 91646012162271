import { createEntityAdapter, createSelector, createSlice, EntityState, PayloadAction } from '@reduxjs/toolkit';
import {
  GetInventorySnapshotSearchRequest,
  InventorySnapshotDetail,
  InventorySnapshotHeader,
  InventorySnapshotSearchProduct,
  InventorySnapshotSearchSortOption,
  InventorySnapshotSearchUnitOfMeasure,
  ResetInventorySnapshotDetailCustomPackSizesRequest,
  ResetInventorySnapshotDetailsCustomPackSizesRequest,
} from '../../api/models/inventory-snapshot.models';
import { normalizeKey } from '../../helpers';

import { InventorySnapshotProduct } from '../../api/models/inventory.models';
import { InventoryDetailTransfer } from '../../models/inventory.models';
import { InventorySnapshotListGridData } from '../../models/product-list.models';
import { RootState } from '../store';

// Adapters
const inventorySnapshotDetailAdapter = createEntityAdapter<InventorySnapshotSearchProduct>({
  selectId: (i: InventorySnapshotSearchProduct) => normalizeKey(i.ProductId),
});

interface InventorySnapshotState {
  apiRequest?: GetInventorySnapshotSearchRequest;

  isUpdatingInventoryProduct: boolean;
  isInventorySnapshotDetailUpdating: boolean;

  isLoadingInventorySnapshotHeader: boolean;
  inventorySnapshotHeader?: InventorySnapshotHeader;

  isLoadingInventorySnapshotDetails: boolean;
  inventorySnapshotDetails: EntityState<InventorySnapshotSearchProduct>;

  sortByOptions: InventorySnapshotSearchSortOption[];
}

const initialState: InventorySnapshotState = {
  apiRequest: undefined,
  isUpdatingInventoryProduct: false,
  isInventorySnapshotDetailUpdating: false,

  isLoadingInventorySnapshotHeader: false,
  inventorySnapshotHeader: undefined,

  isLoadingInventorySnapshotDetails: false,
  inventorySnapshotDetails: inventorySnapshotDetailAdapter.getInitialState(),

  sortByOptions: [],
};

export const inventorySnapshotSlice = createSlice({
  name: 'inventorySnapshot',
  initialState: initialState,
  reducers: {
    resetState: () => initialState,
    resetSearch: (state) => {
      state.apiRequest = initialState.apiRequest;
      state.inventorySnapshotDetails = initialState.inventorySnapshotDetails;
    },
    setApiRequest: (
      state: InventorySnapshotState,
      action: PayloadAction<GetInventorySnapshotSearchRequest | undefined>
    ) => {
      state.apiRequest = action.payload;
    },

    setIsUpdatingInventoryProduct: (state: InventorySnapshotState, action: PayloadAction<boolean>) => {
      state.isUpdatingInventoryProduct = action.payload;
    },
    setIsLoadingInventorySnapshotDetail: (state: InventorySnapshotState, action: PayloadAction<boolean>) => {
      state.isInventorySnapshotDetailUpdating = action.payload;
    },
    updateInventorySnapshotDetail: (state: InventorySnapshotState, action: PayloadAction<InventoryDetailTransfer>) => {
      const product = state.inventorySnapshotDetails.entities[action.payload.productId];
      if (product) {
        product.InventorySnapshotSearchUnitOfMeasures.forEach((uom: InventorySnapshotSearchUnitOfMeasure) => {
          if (uom.UnitOfMeasureDisplay == action.payload.uomDisplay) {
            uom.QuantityFull = action.payload.quantityFull;
            uom.QuantityPartial = action.payload.quantityPartial;
          }
        });
      }
    },
    setIsLoadingInventorySnapshotHeader: (state: InventorySnapshotState, action: PayloadAction<boolean>) => {
      state.isLoadingInventorySnapshotHeader = action.payload;
    },
    setInventorySnapshotHeader: (state: InventorySnapshotState, action: PayloadAction<InventorySnapshotHeader>) => {
      state.inventorySnapshotHeader = action.payload;
    },
    resetSortByOptions: (state: InventorySnapshotState) => {
      state.sortByOptions = initialState.sortByOptions;
    },
    setSortByOptions: (state: InventorySnapshotState, action: PayloadAction<InventorySnapshotSearchSortOption[]>) => {
      state.sortByOptions = action.payload;
    },
    setIsLoadingInventorySnapshotDetails: (state: InventorySnapshotState, action: PayloadAction<boolean>) => {
      state.isLoadingInventorySnapshotDetails = action.payload;
    },
    setInventorySnapshotDetailNote: (
      state: InventorySnapshotState,
      action: PayloadAction<InventorySnapshotProduct>
    ) => {
      const normalizedId = normalizeKey(action.payload.ProductKey);

      inventorySnapshotDetailAdapter.updateOne(state.inventorySnapshotDetails, {
        id: normalizedId,
        changes: { Note: action.payload.Note },
      });
    },
    setInventorySnapshotDetails: (
      state: InventorySnapshotState,
      action: PayloadAction<InventorySnapshotSearchProduct[]>
    ) => {
      inventorySnapshotDetailAdapter.setAll(state.inventorySnapshotDetails, action.payload);
    },
    setProductCustomPackSize: (state: InventorySnapshotState, action: PayloadAction<InventorySnapshotDetail>) => {
      const product = state.inventorySnapshotDetails.entities[normalizeKey(action.payload.ProductKey)];
      if (product) {
        const uomToUpdate = product.InventorySnapshotSearchUnitOfMeasures.find(
          (uom) => uom.UnitOfMeasureDisplay === action.payload.UnitOfMeasureDisplay
        );

        if (uomToUpdate) {
          uomToUpdate.CustomUnitsPerFull = action.payload.CustomUnitsPerFull;
          uomToUpdate.CustomUnitsPerFullDescription = action.payload.CustomUnitsPerFullDescription;
          uomToUpdate.CustomPackSize = action.payload.CustomPackSize;
        }
      }
    },
    resetProductCustomPackSize: (
      state: InventorySnapshotState,
      action: PayloadAction<ResetInventorySnapshotDetailCustomPackSizesRequest>
    ) => {
      const product = state.inventorySnapshotDetails.entities[normalizeKey(action.payload.ProductKey)];
      if (product) {
        product.InventorySnapshotSearchUnitOfMeasures.forEach((uom: InventorySnapshotSearchUnitOfMeasure) => {
          uom.CustomPackSize = uom.ProductPackSize;
          uom.CustomUnitsPerFull = undefined;
          uom.CustomUnitsPerFullDescription = '';
        });
      }
    },
    resetAllProductsCustomPackSize: (
      state: InventorySnapshotState,
      action: PayloadAction<ResetInventorySnapshotDetailsCustomPackSizesRequest>
    ) => {
      const products = Object.values(state.inventorySnapshotDetails.entities);
      products.forEach((product) => {
        if (product) {
          product.InventorySnapshotSearchUnitOfMeasures.forEach((uom: InventorySnapshotSearchUnitOfMeasure) => {
            uom.CustomPackSize = uom.ProductPackSize;
            uom.CustomUnitsPerFull = undefined;
            uom.CustomUnitsPerFullDescription = '';
          });
        }
      });
    },
  },
});

export const {
  selectAll: selectAllInventorySnapshotDetails,
  selectIds: selectAllInventorySnapshotDetailIds,
  selectById: selectInventorySnapshotDetailById,
} = inventorySnapshotDetailAdapter.getSelectors<InventorySnapshotState>(
  (state: InventorySnapshotState) => state.inventorySnapshotDetails
);

export const selectInventorySnapshotGridDataById = createSelector(
  (state: RootState) => state.inventorySnapshot.inventorySnapshotDetails.entities,
  (state: RootState, id: string) => id,
  (details, id) => {
    const result: InventorySnapshotListGridData = { product: details[id] };
    return result;
  }
);

export const selectSnapshotProductById = (state: RootState, productId?: string) =>
  selectInventorySnapshotDetailById(state.inventorySnapshot, normalizeKey(productId));
