import {
  InventorySnapshotProduct,
  UpdateInventorySnapshotProductMessageRequest,
  UpdateInventorySnapshotProductRequest,
} from '../models';
import { ServiceResult } from '../models/api-shared.models';
import ApiService from './api-service';

class InventorySnapshotProductService extends ApiService {
  private static classInstance: InventorySnapshotProductService;

  private constructor() {
    super('InventorySnapshotProduct');
  }

  public updateInventorySnapshotProduct(
    request: UpdateInventorySnapshotProductRequest
  ): ServiceResult<InventorySnapshotProduct> {
    return this.post(`${this.baseUrl}/UpdateInventorySnapshotProduct`, request);
  }

  public updateInventorySnapshotProductMessage(
    request: UpdateInventorySnapshotProductMessageRequest
  ): ServiceResult<InventorySnapshotProduct> {
    return this.post(`${this.baseUrl}/UpdateInventorySnapshotProductMessage`, request);
  }

  public static getInstance(): InventorySnapshotProductService {
    if (!this.classInstance) {
      this.classInstance = new InventorySnapshotProductService();
    }
    return this.classInstance;
  }
}

export default InventorySnapshotProductService;
