import { createEntityAdapter, createSelector, createSlice, EntityState, PayloadAction } from '@reduxjs/toolkit';
import { InventorySnapshotHeader, InventorySnapshotLog } from '../../api/models/inventory-snapshot.models';
import { InventoryEntryHeader, InventoryViewType } from '../../api/models/inventory.models';
import { getSortComparer, normalizeKey } from '../../helpers';
import { RootState } from '../store';

const sortInventorySnapshots = (a: InventorySnapshotHeader, b: InventorySnapshotHeader): number => {
  const dateComparison = new Date(b.InventoryDate).getTime() - new Date(a.InventoryDate).getTime();

  if (dateComparison !== 0) {
    return dateComparison;
  }

  return a.InventoryName.localeCompare(b.InventoryName);
};

// Adapters
const inventoryEntryHeaderAdapter = createEntityAdapter<InventoryEntryHeader>({
  selectId: (i: InventoryEntryHeader) => normalizeKey(i.InventoryEntryHeaderId),
  sortComparer: getSortComparer((s: InventoryEntryHeader) => new Date(s.ChangeDate), true),
});

const inventorySnapshotHeaderAdapter = createEntityAdapter<InventorySnapshotHeader>({
  selectId: (i: InventorySnapshotHeader) => normalizeKey(i.InventorySnapshotHeaderId),
  sortComparer: sortInventorySnapshots,
});

const inventorySnapshotLogAdapter = createEntityAdapter<InventorySnapshotLog>({
  selectId: (i: InventorySnapshotLog) => normalizeKey(i.InventorySnapshotLogId),
  sortComparer: getSortComparer((s: InventorySnapshotLog) => new Date(s.ChangeDate), true),
});

export interface InventoryManagementGridParameters {
  customerId: string;
  startDate: string;
  endDate: string;
}

interface InventoryManagementState {
  isInventoryEntryHeaderSubmitting: boolean;

  isLoadingInventoryInProgress: boolean;
  inventoryInProgress: EntityState<InventoryEntryHeader>;

  isLoadingInventoryInReview: boolean;
  inventoryInReview: EntityState<InventorySnapshotHeader>;

  isLoadingInventoryCompleted: boolean;
  inventoryCompleted: EntityState<InventorySnapshotHeader>;

  isLoadingInventorySnapshotLogs: boolean;
  inventorySnapshotLogs: EntityState<InventorySnapshotLog>;

  isInventoryReportExporting: boolean;

  gridParameters: InventoryManagementGridParameters | undefined;
}

const initialState: InventoryManagementState = {
  isInventoryEntryHeaderSubmitting: false,

  isLoadingInventoryInProgress: true,
  inventoryInProgress: inventoryEntryHeaderAdapter.getInitialState(),

  isLoadingInventoryInReview: true,
  inventoryInReview: inventorySnapshotHeaderAdapter.getInitialState(),

  isLoadingInventoryCompleted: true,
  inventoryCompleted: inventorySnapshotHeaderAdapter.getInitialState(),

  isLoadingInventorySnapshotLogs: false,
  inventorySnapshotLogs: inventorySnapshotLogAdapter.getInitialState(),

  isInventoryReportExporting: false,

  gridParameters: undefined,
};

export const inventoryManagementSlice = createSlice({
  name: 'inventoryManagement',
  initialState: initialState,
  reducers: {
    resetState: () => initialState,
    resetGridParameters: (state: InventoryManagementState) => {
      state.gridParameters = initialState.gridParameters;
    },
    deleteSnapshotInReview: (state: InventoryManagementState, action: PayloadAction<string>) => {
      inventorySnapshotHeaderAdapter.removeOne(state.inventoryInReview, action.payload);
    },
    deleteSnapshotCompleted: (state: InventoryManagementState, action: PayloadAction<string>) => {
      inventorySnapshotHeaderAdapter.removeOne(state.inventoryCompleted, action.payload);
    },
    setIsLoadingInventorySnapshotLogs: (state: InventoryManagementState, action: PayloadAction<boolean>) => {
      //setIsLoadingInventorySnapshotLogs
      state.isLoadingInventorySnapshotLogs = action.payload;
    },
    setInventorySnapshotLogs: (state: InventoryManagementState, action: PayloadAction<InventorySnapshotLog[]>) => {
      inventorySnapshotLogAdapter.setAll(state.inventorySnapshotLogs, action.payload);
    },
    setIsInventoryEntryHeaderSubmitting: (state: InventoryManagementState, action: PayloadAction<boolean>) => {
      state.isInventoryEntryHeaderSubmitting = action.payload;
    },
    setIsLoadingInventoryInProgress: (state: InventoryManagementState, action: PayloadAction<boolean>) => {
      state.isLoadingInventoryInProgress = action.payload;
    },
    setInventoryInProgress: (state: InventoryManagementState, action: PayloadAction<InventoryEntryHeader[]>) => {
      inventoryEntryHeaderAdapter.setAll(state.inventoryInProgress, action.payload);
    },

    setIsLoadingInventoryInReview: (state: InventoryManagementState, action: PayloadAction<boolean>) => {
      state.isLoadingInventoryInReview = action.payload;
    },
    setInventoryInReview: (state: InventoryManagementState, action: PayloadAction<InventorySnapshotHeader[]>) => {
      inventorySnapshotHeaderAdapter.setAll(state.inventoryInReview, action.payload);
    },
    setInventoryInReviewIsChecked: (
      state: InventoryManagementState,
      action: PayloadAction<{ id: string; isChecked: boolean }>
    ) => {
      const data = state.inventoryInReview.entities[action.payload.id];
      if (data) data.IsChecked = action.payload.isChecked;
    },
    setAllInventoryInReviewIsChecked: (state: InventoryManagementState, action: PayloadAction<boolean>) => {
      state.inventoryInReview.ids.forEach((i) => {
        const data = state.inventoryInReview.entities[i];
        if (data) data.IsChecked = action.payload;
      });
    },
    setIsLoadingInventoryCompleted: (state: InventoryManagementState, action: PayloadAction<boolean>) => {
      state.isLoadingInventoryCompleted = action.payload;
    },
    setInventoryCompleted: (state: InventoryManagementState, action: PayloadAction<InventorySnapshotHeader[]>) => {
      inventorySnapshotHeaderAdapter.setAll(state.inventoryCompleted, action.payload);
    },
    setInventoryReportIsExporting: (state: InventoryManagementState, action: PayloadAction<boolean>) => {
      state.isInventoryReportExporting = action.payload;
    },
    setGridParameters: (
      state: InventoryManagementState,
      action: PayloadAction<InventoryManagementGridParameters | undefined>
    ) => {
      state.gridParameters = action.payload;
    },
  },
});

const { selectAll: selectAllInventoryEntryHeaders } =
  inventoryEntryHeaderAdapter.getSelectors<InventoryManagementState>(
    (state: InventoryManagementState) => state.inventoryInProgress
  );

export const { selectAll: selectAllInventoryInReview } =
  inventorySnapshotHeaderAdapter.getSelectors<InventoryManagementState>(
    (state: InventoryManagementState) => state.inventoryInReview
  );

export const { selectAll: selectAllInventoryCompleted } =
  inventorySnapshotHeaderAdapter.getSelectors<InventoryManagementState>(
    (state: InventoryManagementState) => state.inventoryCompleted
  );

export const selectAllInventoryInProgress = createSelector(
  [
    (s: RootState) => s.inventoryManagement,
    (s: RootState) => s.customer.selectedCustomer?.UserId,
    (_s: RootState, view: InventoryViewType | undefined) => view,
  ],
  (state: InventoryManagementState, userId: string | undefined, view: InventoryViewType | undefined) => {
    let headers = [...selectAllInventoryEntryHeaders(state)];

    const idx = headers.findIndex((i) => i.UserId === userId);
    const header = headers?.[idx];

    if (view != InventoryViewType.All && header) {
      headers = [header];
    } else if (header) {
      headers.splice(idx, 1);
      headers = [header, ...headers];
    }

    return headers;
  }
);

export const selectCurrentUserHasInventoryInProgress = createSelector(
  [(s: RootState) => s.inventoryManagement, (s: RootState) => s.customer.selectedCustomer?.UserId],
  (state: InventoryManagementState, userId: string | undefined) => {
    const headers = selectAllInventoryEntryHeaders(state);
    return headers.filter((i) => i.UserId === userId).length > 0;
  }
);
export const selectHasInventoryInProgress = createSelector(
  [(s: RootState) => s.inventoryManagement],
  (state: InventoryManagementState) => {
    const inProgress = state.inventoryInProgress.ids.length > 0;

    return inProgress;
  }
);

export const selectHasExistingInventory = createSelector(
  [(s: RootState) => s.inventoryManagement],
  (state: InventoryManagementState) => {
    const inProgress = state.inventoryInProgress.ids.length > 0;
    const inReview = state.inventoryInReview.ids.length > 0;
    const completed = state.inventoryCompleted.ids.length > 0;

    return inProgress || inReview || completed;
  }
);

export const selectAllCheckedInventoryInReview = createSelector(
  [(s: RootState) => s.inventoryManagement],
  (state: InventoryManagementState) => {
    const headers = selectAllInventoryInReview(state);
    return headers.filter((i) => i.IsChecked);
  }
);

export default inventoryManagementSlice.reducer;

export const { selectAll: selectAllInventorySnapshotLogs } =
  inventorySnapshotLogAdapter.getSelectors<InventoryManagementState>(
    (state: InventoryManagementState) => state.inventorySnapshotLogs
  );
