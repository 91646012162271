// Custom model reflecting flattened InventoryEntryHeader and InventorySnapshotHeader data
export interface InventoryHeader {
  InventoryHeaderId: string;
  CreateUserId: string;
  ChangeUserId: string;
  UserId: string;
  CustomerId: string;
  ParListHeaderId: string;
  InventoryName: string;
  InventoryDate: Date | string;
  PricingDate: Date | string;
  InventoryStatusTypeEnum: InventoryStatusTypeEnum;
  IsMerged: boolean;
  QuantityFull: string;
  QuantityPartial: string;
  Pricing?: string;
  ChangeUserDisplay: string;
  CreateUserDisplay: string;
  ChangeDate: Date | string;
  CreateDate: Date | string;
  DeleteDate?: Date | string;

  HasDetails?: boolean;
}

export interface GetInventoryEntryHeadersRequest {
  CustomerId: string;
}

export interface GetInventoryEntryHeaderRequest {
  InventoryEntryHeaderId: string;
}

export interface CreateInventoryEntryHeaderRequest {
  CustomerId: string;
  ParListHeaderId?: string;
}

export interface ResetInventoryEntryHeaderRequest {
  InventoryEntryHeaderId: string;
  ResetValuesToZero: boolean;
}

export interface GetInventorySnapshotHeadersInReviewRequest {
  CustomerId: string;
  StartDate?: Date | string;
  EndDate?: Date | string;
}

export enum InventoryStatusTypeEnum {
  InReview = 0,
  Completed = 1,
}

export enum InventoryViewType {
  My = 0,
  All = 1,
}
export interface InventoryEntryHeader {
  InventoryEntryHeaderId: string;
  UserId?: string;
  CustomerId: string;
  ParListHeaderId?: string;
  UserDisplay: string;
  ChangeUserDisplay: string;
  TotalQuantity: number;
  ChangeDate: Date | string;
  CreateDate: Date | string;
  HasDetails?: boolean;
}

export interface InventoryEntryDetail {
  InventoryEntryDetailId: string;
  InventoryEntryHeaderId: string;
  ProductTypeEnum: number; // revisit this, will need enum defs
  ProductKey: string;
  UnitOfMeasureTypeEnum: number; // revisit this, will need enum defs
  ThirdPartyVendorProductId?: string;
  ThirdPartyUnitOfMeasureTypeEnum: number; // revisit this, will need enum defs
  InventoryCustomerProductUnitOfMeasureId?: string;
  QuantityFull: number | undefined;
  QuantityPartial: number | undefined;
  CreateDate: Date | string;
  ChangeDate: Date | string;
  DeleteDate?: Date | string;
  CreateUserId: string;
  ChangeUserId: string;

  InventoryName?: string;
  CreateUserDisplayName?: string;
  ChangeUserDisplayName?: string;
}

export type InventoryCustomerProductUom = {
  CustomPackSize?: string;
  CustomUnitPerFull?: number;
  CustomUnitsPerFullDescription?: string;
};

export interface UpdateInventoryEntryDetailRequest {
  InventoryEntryHeaderId: string;
  ProductKey: string;
  ThirdPartyVendorProductId?: string;
  UnitOfMeasureType: number; // revisit this, will need enum defs
  QuantityFull: number | undefined;
  QuantityPartial: number | undefined;
  ProductBusinessUnitERPKeyType: number;
}

export interface SubmitInventoryEntryHeaderRequest {
  InventoryEntryHeaderId: string;
  InventoryHeaderName: string;
  ResetQuantites: boolean;
  TimeZone: string;
  InventoryDate: Date | string;
}

export interface UpdateInventoryEntryProductRequest {
  InventoryEntryHeaderId: string;
  ProductKey: string;
  ThirdPartyVendorProductId?: string;
  ProductBusinessUnitERPKeyType: number;
  Note: string;
}

export interface ResetInventoryCustomerProductUnitOfMeasureRequest {
  CustomerId: string;
  ProductKey: string;
  BusinessUnitERPKey: number;
}

export interface ResetInventoryCustomerProductUnitOfMeasuresRequest {
  CustomerId: string;
}

export interface UpdateInventoryCustomerProductUnitOfMeasureRequest {
  CustomerId: string;
  ProductKey: string;
  ProductBusinessUnitERPKeyType: number;
  UnitOfMeasureType: number;
  CustomUnitsPerFull?: number | null;
  CustomUnitsPerFullDescription?: string;
}
export interface InventoryEntryProduct {
  InvenotryEntryProductId: string;
  InventoryEntryHeaderId: string;
  ProductKey: string;
  ThirdPartyVendorProductId?: string;
  ProductbusinessUnitERPKeyType: number;
  Note: string;
}

export interface InventoryCustomerProduct {
  InventoryCustomerProductUnitOfMeasureId: string;
  ProductKey: string;
  UnitOfMeasureType: number;
  CustomUnitsPerFull: number;
  CustomUnitsPerFullDescription: string;
  CustomPackSize: string;
  ProductbusinessUnitERPKeyType: number;
}

export interface UpdateInventorySnapshotProductRequest {
  InventorySnapshotHeaderId: string;
  ProductKey: string;
  ThirdPartyVendorProductId?: string;
  ProductbusinessUnitERPKeyType: number;
  Note: string;
}

export interface InventorySnapshotProduct {
  InvenotrySnapshotProductId: string;
  InventorySnapshotHeaderId: string;
  ProductKey: string;
  ThirdPartyVendorProductId?: string;
  ProductbusinessUnitERPKeyType: number;
  Note: string;
}
