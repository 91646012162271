import { openFileInCurrentWindow } from 'web/src/utilities/download-file';

import {
  CostAnalysisRequest,
  CostAnalysisUserOptionsDefaults,
  FileResult,
  InventoryConsumptionRequest,
  InventoryConsumptionUserOptionsDefaults,
  InventoryValuationRequest,
  InventoryValuationUserOptionsDefaults,
} from '../../api';
import {
  GetInventorySnapshotSearchRequest,
  InventorySnapshotSearchSortByType,
  InventorySnapshotSearchSortOption,
  InventorySnapshotSearchUnitOfMeasure,
  ResetInventorySnapshotDetailCustomPackSizesRequest,
  ResetInventorySnapshotDetailsCustomPackSizesRequest,
  UpdateInventorySnapshotDetailRequest,
  UpdateInventorySnapshotProductMessageRequest,
} from '../../api/models/inventory-snapshot.models';
import InventoryReportService from '../../api/services/inventory-report.service';
import InventorySnapshotDetailService from '../../api/services/inventory-snapshot-detail.service';
import InventorySnapshotHeaderService from '../../api/services/inventory-snapshot-header.service';
import InventorySnapshotProductService from '../../api/services/inventory-snapshot-product.service';
import InventorySnapshotSearchService from '../../api/services/inventory-snapshot-search.service';
import { AnalyticsContext, generateStaticId } from '../../helpers';
import { useAppInsightsLogger } from '../../logging';
import { InventoryDetailTransfer, NotificationDisplayType, NotificationKeys, NotificationType } from '../../models';
import { globalSlice, setErrorDialogContent, upsertAppNotification } from '../common';
import { AppDispatch, AppThunk, RootState } from '../store';
import { inventoryManagementSlice } from './inventory-management.slice';
import { getInventorySnapshotHeadersInReview } from './inventory-management.thunks';
import { inventorySnapshotSlice } from './inventory-snapshot.slice';

const inventorySnapshotSearchService = InventorySnapshotSearchService.getInstance();
const inventorySnapshotHeaderService = InventorySnapshotHeaderService.getInstance();
const inventorySnapshotDetailService = InventorySnapshotDetailService.getInstance();
const inventorySnapshotProductService = InventorySnapshotProductService.getInstance();
const inventoryReportService = InventoryReportService.getInstance();

const appInsightsLogger = useAppInsightsLogger();

export const getInventorySnapshotHeader =
  (inventorySnapshotHeaderId: string): AppThunk =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      if (!getState().inventorySnapshot.isLoadingInventorySnapshotHeader)
        dispatch(inventorySnapshotSlice.actions.setIsLoadingInventorySnapshotHeader(true));

      const { data } = await inventorySnapshotHeaderService.getInventorySnapshotHeader({
        InventorySnapshotHeaderId: inventorySnapshotHeaderId,
      });

      if (data.IsSuccess) {
        dispatch(inventorySnapshotSlice.actions.setInventorySnapshotHeader(data.ResultObject));
      } else {
        dispatch(globalSlice.actions.setErrorDialogContent({ messages: data.ErrorMessages }));
      }
    } catch (error) {
      appInsightsLogger.trackException({
        exception: error,
      });
    } finally {
      dispatch(inventorySnapshotSlice.actions.setIsLoadingInventorySnapshotHeader(false));
    }
  };

export const approveInventorySnapshotHeader =
  (inventorySnapshotHeaderId: string, onSuccess?: () => void): AppThunk =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      if (!getState().inventorySnapshot.isLoadingInventorySnapshotHeader)
        dispatch(inventorySnapshotSlice.actions.setIsLoadingInventorySnapshotHeader(true));

      const { data } = await inventorySnapshotHeaderService.approveInventorySnapshotHeader({
        InventorySnapshotHeaderId: inventorySnapshotHeaderId,
      });

      if (data.IsSuccess) {
        dispatch(inventorySnapshotSlice.actions.setInventorySnapshotHeader(data.ResultObject));
        onSuccess?.();
      } else {
        dispatch(globalSlice.actions.setErrorDialogContent({ messages: data.ErrorMessages }));
      }
    } catch (error) {
      appInsightsLogger.trackException({
        exception: error,
      });
    } finally {
      dispatch(inventorySnapshotSlice.actions.setIsLoadingInventorySnapshotHeader(false));
    }
  };

export const reviewInventorySnapshotHeader =
  (inventorySnapshotHeaderId: string, onSuccess?: () => void): AppThunk =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      if (!getState().inventorySnapshot.isLoadingInventorySnapshotHeader)
        dispatch(inventorySnapshotSlice.actions.setIsLoadingInventorySnapshotHeader(true));

      const { data } = await inventorySnapshotHeaderService.reviewInventorySnapshotHeader({
        InventorySnapshotHeaderId: inventorySnapshotHeaderId,
      });

      if (data.IsSuccess) {
        dispatch(inventorySnapshotSlice.actions.setInventorySnapshotHeader(data.ResultObject));
        onSuccess?.();
      } else {
        dispatch(globalSlice.actions.setErrorDialogContent({ messages: data.ErrorMessages }));
      }
    } catch (error) {
      appInsightsLogger.trackException({
        exception: error,
      });
    } finally {
      dispatch(inventorySnapshotSlice.actions.setIsLoadingInventorySnapshotHeader(false));
    }
  };

export const getInventorySnapshotSearchSort =
  (success: (options: InventorySnapshotSearchSortOption[]) => void): AppThunk =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    if (getState().inventorySnapshot.sortByOptions.length > 0) return;
    dispatch(inventorySnapshotSlice.actions.setIsLoadingInventorySnapshotDetails(true));

    const { data } = await inventorySnapshotSearchService.getInventorySnapshotSearchSort();
    if (data.IsSuccess) {
      success(data.ResultObject);
      dispatch(inventorySnapshotSlice.actions.setSortByOptions(data.ResultObject));
    }
  };

export const getProductListInventorySnapshotSearch =
  (
    request: {
      inventorySnapshotHeaderId: string;
      filterText?: string;
      sortBy?: InventorySnapshotSearchSortByType;
    },
    analyticsContext?: AnalyticsContext
  ): AppThunk =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      const filterText = request.filterText;

      const apiRequest: GetInventorySnapshotSearchRequest = {
        InventorySnapshotHeaderId: request.inventorySnapshotHeaderId,
        QueryText: filterText ?? '',
        SortBy: request.sortBy ?? InventorySnapshotSearchSortByType.InventoryEntry,
      };

      // Data in store is for search with different parameters (excluding skip)
      if (JSON.stringify({ ...apiRequest }) !== JSON.stringify({ ...getState().inventorySnapshot.apiRequest })) {
        dispatch(inventorySnapshotSlice.actions.resetSearch());
      }

      dispatch(inventorySnapshotSlice.actions.setApiRequest(apiRequest));

      dispatch(inventorySnapshotSlice.actions.setIsLoadingInventorySnapshotDetails(true));

      const { data } = await inventorySnapshotSearchService.getInventorySnapshotSearch(apiRequest);

      if (data.IsSuccess) {
        dispatch(
          inventorySnapshotSlice.actions.setInventorySnapshotDetails(data.ResultObject.InventorySnapshotSearchProducts)
        );
        dispatch(inventorySnapshotSlice.actions.setIsLoadingInventorySnapshotDetails(false));
      } else {
        dispatch(inventorySnapshotSlice.actions.setApiRequest(undefined));
        dispatch(setErrorDialogContent('Error occurred', data.ErrorMessages));
      }
    } catch (error) {
      appInsightsLogger.trackException({
        exception: error,
      });
      dispatch(inventorySnapshotSlice.actions.setApiRequest(undefined));
      dispatch(inventorySnapshotSlice.actions.setIsLoadingInventorySnapshotDetails(false));
    }
  };

export const updateInventorySnapshotHeader =
  (inventorySnapshotHeaderId: string, inventoryName: string, inventoryDate?: Date | string): AppThunk =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      if (!getState().inventorySnapshot.isLoadingInventorySnapshotHeader)
        dispatch(inventorySnapshotSlice.actions.setIsLoadingInventorySnapshotHeader(true));

      const { data } = await inventorySnapshotHeaderService.updateInventorySnapshotHeader({
        InventorySnapshotHeaderId: inventorySnapshotHeaderId,
        InventoryName: inventoryName,
        InventoryDate: inventoryDate,
      });

      if (data.IsSuccess) {
        dispatch(inventorySnapshotSlice.actions.setInventorySnapshotHeader(data.ResultObject));
      } else {
        dispatch(globalSlice.actions.setErrorDialogContent({ messages: data.ErrorMessages }));
      }
    } catch (error) {
      appInsightsLogger.trackException({
        exception: error,
      });
    } finally {
      dispatch(inventorySnapshotSlice.actions.setIsLoadingInventorySnapshotHeader(false));
    }
  };

export const updateInventorySnapshotDetail =
  (
    inventorySnapshotHeaderId: string,
    unitOfMeasure: number,
    productId: string,
    productKey: string,
    thirdPartyVendorProductId: string | undefined,
    quantityFull: number | undefined,
    quantityPartial: number | undefined,
    uomBackup: InventorySnapshotSearchUnitOfMeasure,
    productBusinessUnitERPKeyType: number
  ): AppThunk =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      if (!getState().inventorySnapshot.isInventorySnapshotDetailUpdating)
        dispatch(inventorySnapshotSlice.actions.setIsLoadingInventorySnapshotDetail(true));

      // pre-emptively ui update state
      const transferDetail: InventoryDetailTransfer = {
        productId: productId,
        uom: unitOfMeasure,
        quantityFull: quantityFull,
        quantityPartial: quantityPartial,
        uomDisplay: uomBackup.UnitOfMeasureDisplay,
      };
      dispatch(inventorySnapshotSlice.actions.updateInventorySnapshotDetail(transferDetail));

      // call api
      const request: UpdateInventorySnapshotDetailRequest = {
        InventorySnapshotHeaderId: inventorySnapshotHeaderId,
        ProductKey: productKey,
        ThirdPartyVendorProductId: thirdPartyVendorProductId,
        UnitOfMeasureDisplay: uomBackup.UnitOfMeasureDisplay,
        QuantityFull: quantityFull,
        QuantityPartial: quantityPartial,
        ProductBusinessUnitERPKeyType: productBusinessUnitERPKeyType,
      };

      const { data } = await inventorySnapshotDetailService.updateInventorySnapshotDetail(request);

      // handle failure, restore old state
      if (!data.IsSuccess) {
        transferDetail.quantityFull = uomBackup.QuantityFull;
        transferDetail.quantityPartial = uomBackup.QuantityPartial;
        dispatch(inventorySnapshotSlice.actions.updateInventorySnapshotDetail(transferDetail));
        dispatch(globalSlice.actions.setErrorDialogContent({ messages: data.ErrorMessages }));
      }
    } catch (error) {
      appInsightsLogger.trackException({
        exception: error,
      });
    } finally {
      dispatch(inventorySnapshotSlice.actions.setIsLoadingInventorySnapshotDetail(false));
    }
  };

export const updateInventorySnapshotProductMessage =
  (
    inventorySnapshotHeaderId: string,
    productKey: string,
    thirdPartyVendorProductId: string | undefined,
    productBusinessUnitERPKeyType: number,
    note: string,
    successCallback?: () => void
  ): AppThunk =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      if (!getState().inventorySnapshot.isInventorySnapshotDetailUpdating) {
        dispatch(inventorySnapshotSlice.actions.setIsLoadingInventorySnapshotDetail(true));
      }

      const request: UpdateInventorySnapshotProductMessageRequest = {
        InventorySnapshotHeaderId: inventorySnapshotHeaderId,
        ProductKey: productKey,
        ThirdPartyVendorProductId: thirdPartyVendorProductId,
        ProductBusinessUnitERPKeyType: productBusinessUnitERPKeyType,
        Note: note,
      };

      const { data } = await inventorySnapshotProductService.updateInventorySnapshotProductMessage(request);

      if (data.IsSuccess) {
        dispatch(inventorySnapshotSlice.actions.setInventorySnapshotDetailNote(data.ResultObject));
        successCallback?.();
      } else {
        dispatch(globalSlice.actions.setErrorDialogContent({ messages: data.ErrorMessages }));
      }
    } catch (error) {
      appInsightsLogger.trackException({
        exception: error,
      });
    } finally {
      dispatch(inventorySnapshotSlice.actions.setIsLoadingInventorySnapshotDetail(false));
    }
  };

export const deleteInventorySnapshotHeader =
  (inventorySnapshotHeaderId: string, customerId: string, successCallback?: () => void): AppThunk =>
  async (dispatch: AppDispatch) => {
    try {
      const { data } = await inventorySnapshotHeaderService.deleteInventorySnapshotHeader({
        InventorySnapshotHeaderId: inventorySnapshotHeaderId,
        CustomerId: customerId,
      });

      if (data.IsSuccess) {
        successCallback?.();
      } else {
        dispatch(globalSlice.actions.setErrorDialogContent({ messages: data.ErrorMessages }));
      }
    } catch (error) {
      appInsightsLogger.trackException({
        exception: error,
      });
    }
  };

export const mergeInventorySnapshotHeaders =
  (
    customerId: string,
    inventorySnapshotHeaderIds: string[],
    inventoryName: string,
    deleteSnapshotsOnMerge: boolean
  ): AppThunk =>
  async (dispatch: AppDispatch) => {
    dispatch(inventoryManagementSlice.actions.setIsLoadingInventoryInReview(true));

    const { data } = await inventorySnapshotHeaderService.mergeInventorySnapshotHeaders({
      CustomerId: customerId,
      InventorySnapshotHeaderIds: inventorySnapshotHeaderIds,
      InventoryName: inventoryName,
      DeleteSnapshotsOnMerge: deleteSnapshotsOnMerge,
    });

    if (data.IsSuccess) {
      dispatch(getInventorySnapshotHeadersInReview(customerId));
    } else {
      dispatch(globalSlice.actions.setErrorDialogContent({ messages: data.ErrorMessages }));
      dispatch(inventoryManagementSlice.actions.setIsLoadingInventoryInReview(false));
    }
  };

// Valuation

export const getInventoryValuationUserOptions =
  (): AppThunk<Promise<InventoryValuationUserOptionsDefaults | undefined>> =>
  async (): Promise<InventoryValuationUserOptionsDefaults | undefined> => {
    try {
      const { data } = await inventoryReportService.getInventoryValuationUserOptions();
      if (data.IsSuccess) {
        return data.ResultObject;
      }
    } catch (error: unknown) {
      appInsightsLogger.trackException({
        exception: error,
      });
    }

    return Promise.resolve(undefined);
  };

export const exportInventoryValuation =
  (request: InventoryValuationRequest): AppThunk =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    if (getState().inventoryManagement.isInventoryReportExporting) {
      return;
    }
    try {
      dispatch(inventoryManagementSlice.actions.setInventoryReportIsExporting(true));

      dispatch(
        upsertAppNotification(
          {
            Id: generateStaticId('toastNotification', ['inventory-report-toast']),
            NotificationType: NotificationType.Information,
            NotificationDisplayType: NotificationDisplayType.Toast,
            AutoDismiss: 10,
            CanUserDismiss: true,
            Key: NotificationKeys.Toast,
            Message: 'We are preparing your download.',
          },
          0
        )
      );

      const { data } = await inventoryReportService.getInventoryValuation(request);
      if (data.IsSuccess) {
        openFileInCurrentWindow(data.ResultObject as FileResult);
      } else {
        dispatch(globalSlice.actions.setErrorDialogContent({ messages: data.ErrorMessages }));
      }
    } catch (error) {
      appInsightsLogger.trackException({
        exception: error,
      });
    } finally {
      dispatch(inventoryManagementSlice.actions.setInventoryReportIsExporting(false));
    }
  };

export const getInventoryValuation =
  (request: InventoryValuationRequest): AppThunk<Promise<FileResult | undefined>> =>
  async (dispatch: AppDispatch): Promise<FileResult | undefined> => {
    try {
      const { data } = await inventoryReportService.getInventoryValuation(request);
      if (data.IsSuccess) {
        return data.ResultObject;
      } else {
        dispatch(globalSlice.actions.setErrorDialogContent({ messages: data.ErrorMessages }));
      }
    } catch (error) {
      appInsightsLogger.trackException({
        exception: error,
      });
    }
  };

// Cost analysis

export const getInventoryCostAnalysisUserOptions =
  (): AppThunk<Promise<CostAnalysisUserOptionsDefaults | undefined>> =>
  async (): Promise<CostAnalysisUserOptionsDefaults | undefined> => {
    try {
      const { data } = await inventoryReportService.getCostAnalysisUserOptions();
      if (data.IsSuccess) {
        return data.ResultObject;
      }
    } catch (error: unknown) {
      appInsightsLogger.trackException({
        exception: error,
      });
    }

    return Promise.resolve(undefined);
  };

export const exportInventoryCostAnalysis =
  (request: CostAnalysisRequest): AppThunk =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    if (getState().inventoryManagement.isInventoryReportExporting) {
      return;
    }
    try {
      dispatch(inventoryManagementSlice.actions.setInventoryReportIsExporting(true));

      dispatch(
        upsertAppNotification(
          {
            Id: generateStaticId('toastNotification', ['inventory-report-toast']),
            NotificationType: NotificationType.Information,
            NotificationDisplayType: NotificationDisplayType.Toast,
            AutoDismiss: 10,
            CanUserDismiss: true,
            Key: NotificationKeys.Toast,
            Message: 'We are preparing your download.',
          },
          0
        )
      );

      const { data } = await inventoryReportService.getCostAnalysis(request);
      if (data.IsSuccess) {
        openFileInCurrentWindow(data.ResultObject as FileResult);
      } else {
        dispatch(globalSlice.actions.setErrorDialogContent({ messages: data.ErrorMessages }));
      }
    } catch (error) {
      appInsightsLogger.trackException({
        exception: error,
      });
    } finally {
      dispatch(inventoryManagementSlice.actions.setInventoryReportIsExporting(false));
    }
  };

export const getInventoryCostAnalysis =
  (request: CostAnalysisRequest): AppThunk<Promise<FileResult | undefined>> =>
  async (dispatch: AppDispatch): Promise<FileResult | undefined> => {
    try {
      const { data } = await inventoryReportService.getCostAnalysis(request);
      if (data.IsSuccess) {
        return data.ResultObject;
      } else {
        dispatch(globalSlice.actions.setErrorDialogContent({ messages: data.ErrorMessages }));
      }
    } catch (error) {
      appInsightsLogger.trackException({
        exception: error,
      });
    }
  };

// Consumption

export const getInventoryConsumptionUserOptions =
  (): AppThunk<Promise<InventoryConsumptionUserOptionsDefaults | undefined>> =>
  async (): Promise<InventoryConsumptionUserOptionsDefaults | undefined> => {
    try {
      const { data } = await inventoryReportService.getInventoryConsumptionUserOptions();
      if (data.IsSuccess) {
        return data.ResultObject;
      }
    } catch (error: unknown) {
      appInsightsLogger.trackException({
        exception: error,
      });
    }

    return Promise.resolve(undefined);
  };

export const exportInventoryConsumptions =
  (request: InventoryConsumptionRequest): AppThunk =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    if (getState().inventoryManagement.isInventoryReportExporting) {
      return;
    }
    try {
      dispatch(inventoryManagementSlice.actions.setInventoryReportIsExporting(true));

      dispatch(
        upsertAppNotification(
          {
            Id: generateStaticId('toastNotification', ['inventory-report-toast']),
            NotificationType: NotificationType.Information,
            NotificationDisplayType: NotificationDisplayType.Toast,
            AutoDismiss: 10,
            CanUserDismiss: true,
            Key: NotificationKeys.Toast,
            Message: 'We are preparing your download.',
          },
          0
        )
      );

      const { data } = await inventoryReportService.getInventoryConsumption(request);
      if (data.IsSuccess) {
        openFileInCurrentWindow(data.ResultObject as FileResult);
      } else {
        dispatch(globalSlice.actions.setErrorDialogContent({ messages: data.ErrorMessages }));
      }
    } catch (error) {
      appInsightsLogger.trackException({
        exception: error,
      });
    } finally {
      dispatch(inventoryManagementSlice.actions.setInventoryReportIsExporting(false));
    }
  };

export const getInventoryConsumptions =
  (request: InventoryConsumptionRequest): AppThunk<Promise<FileResult | undefined>> =>
  async (dispatch: AppDispatch): Promise<FileResult | undefined> => {
    try {
      const { data } = await inventoryReportService.getInventoryConsumption(request);
      if (data.IsSuccess) {
        return data.ResultObject;
      } else {
        dispatch(globalSlice.actions.setErrorDialogContent({ messages: data.ErrorMessages }));
      }
    } catch (error) {
      appInsightsLogger.trackException({
        exception: error,
      });
    }
  };

// Custom Pack Size

export const updateInventorySnapshotDetailProductUnitOfMeasure =
  (request: UpdateInventorySnapshotDetailRequest): AppThunk =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      const { data } = await inventorySnapshotDetailService.updateInventorySnapshotDetail(request);
      if (data.IsSuccess) {
        dispatch(inventorySnapshotSlice.actions.setProductCustomPackSize(data.ResultObject));
      } else {
        dispatch(globalSlice.actions.setErrorDialogContent({ messages: data.ErrorMessages }));
      }
    } catch (error) {
      appInsightsLogger.trackException({
        exception: error,
      });
    }
  };

export const resetInventorySnapshotDetailsCustomPackSizes =
  (request: ResetInventorySnapshotDetailsCustomPackSizesRequest): AppThunk =>
  async (dispatch: AppDispatch) => {
    try {
      const { data } = await inventorySnapshotDetailService.resetInventorySnapshotDetailsCustomPackSizes(request);

      if (data.IsSuccess) {
        dispatch(inventorySnapshotSlice.actions.resetAllProductsCustomPackSize(request));
      } else {
        dispatch(globalSlice.actions.setErrorDialogContent({ messages: data.ErrorMessages }));
      }
    } catch (error) {
      appInsightsLogger.trackException({
        exception: error,
      });
    }
  };

export const resetInventorySnapshotDetailCustomPackSizes =
  (request: ResetInventorySnapshotDetailCustomPackSizesRequest): AppThunk =>
  async (dispatch: AppDispatch) => {
    try {
      const { data } = await inventorySnapshotDetailService.resetInventorySnapshotDetailCustomPackSizes(request);

      if (data.IsSuccess) {
        dispatch(inventorySnapshotSlice.actions.resetProductCustomPackSize(request));
      } else {
        dispatch(globalSlice.actions.setErrorDialogContent({ messages: data.ErrorMessages }));
      }
    } catch (error) {
      appInsightsLogger.trackException({
        exception: error,
      });
    }
  };
