export const validateStringEmptyOrMaxLength = (
  propertyName: string,
  propertyValue?: string,
  maxLength?: number,
  allowEmptyString?: boolean
): string[] => {
  const _propertyValue = propertyValue?.trim?.() ?? '';
  const validationMessages: string[] = [];
  if (!allowEmptyString && !_propertyValue) validationMessages.push(`${propertyName} is required`);
  if (maxLength !== undefined && _propertyValue.length > maxLength)
    validationMessages.push(`${propertyName} must be ${maxLength} characters or less`);
  return validationMessages;
};

export const validateZipCode = (
  propertyName: string,
  propertyValue?: string,
  allowEmptyString: boolean = false
): string[] => {
  const _propertyValue = propertyValue?.trim?.() ?? '';
  const validationMessages: string[] = [];

  if (!allowEmptyString && !_propertyValue) {
    validationMessages.push(`${propertyName} is required`);
  }

  // Check if the ZIP code has 5 digits
  if (_propertyValue.length !== 5) {
    validationMessages.push(`${propertyName} must be 5 digits long`);
  }

  // Check if the ZIP code contains only numeric characters
  if (!/^\d{5}$/.test(_propertyValue)) {
    validationMessages.push(`${propertyName} must contain only numeric characters`);
  }

  return validationMessages;
};

export const validateEmail = (
  propertyName: string,
  propertyValue?: string,
  maxLength?: number,
  allowEmptyString: boolean = false
): string[] => {
  const _propertyValue = propertyValue?.trim?.() ?? '';
  const validationMessages: string[] = [];

  // Check if empty strings are allowed
  if (!allowEmptyString && !_propertyValue) {
    validationMessages.push(`${propertyName} is required`);
  }

  // Email format validation using a simple regex
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/;
  if (_propertyValue && !emailRegex.test(_propertyValue)) {
    validationMessages.push(`${propertyName} must be a valid email address`);
  }

  // Check if the email exceeds the maximum length (if provided)
  if (maxLength !== undefined && _propertyValue.length > maxLength) {
    validationMessages.push(`${propertyName} must be ${maxLength} characters or less`);
  }

  return validationMessages;
};

export const validatePhoneNumber = (
  propertyName: string,
  propertyValue?: string,
  maxLength?: number,
  allowEmptyString: boolean = false
): string[] => {
  const _propertyValue = propertyValue?.trim?.() ?? '';
  const validationMessages: string[] = [];

  // Check if empty strings are allowed
  if (!allowEmptyString && !_propertyValue) {
    validationMessages.push(`${propertyName} is required`);
  }

  // Phone number format validation using a flexible regex for multiple formats
  const phoneRegex = /^\+?(\d[\d-.() ]+)?(\([\d-.() ]+\))?[\d-.() ]+\d$/;
  if (_propertyValue && !phoneRegex.test(_propertyValue)) {
    validationMessages.push(`${propertyName} must be a valid phone number`);
  }

  // Check if the phone number exceeds the maximum length (if provided)
  if (maxLength !== undefined && _propertyValue.length > maxLength) {
    validationMessages.push(`${propertyName} must be ${maxLength} characters or less`);
  }

  return validationMessages;
};
