import { InventoryEntryProduct, UpdateInventoryEntryProductRequest } from '../models';
import { ServiceResult } from '../models/api-shared.models';
import ApiService from './api-service';

class InventoryEntryProductService extends ApiService {
  private static classInstance: InventoryEntryProductService;

  private constructor() {
    super('InventoryEntryProduct');
  }

  public updateInventoryEntryProduct(
    request: UpdateInventoryEntryProductRequest
  ): ServiceResult<InventoryEntryProduct> {
    return this.post(`${this.baseUrl}/UpdateInventoryEntryProduct`, request);
  }

  public static getInstance(): InventoryEntryProductService {
    if (!this.classInstance) {
      this.classInstance = new InventoryEntryProductService();
    }
    return this.classInstance;
  }
}

export default InventoryEntryProductService;
