import { createEntityAdapter, createSlice, EntityState, PayloadAction } from '@reduxjs/toolkit';
import { Vendor, VendorCreateAndUpdateRequest } from '../../api/models/vendor-list-detail-models';
import { RootState } from '../store';

const vendorListAdapter = createEntityAdapter<Vendor>({
  selectId: (header: Vendor) => header.ThirdPartyVendorId.toString(),
  sortComparer: (a, b) => a.VendorName.localeCompare(b.VendorName),
});
// State
interface VendorManagementState {
  isLoadingVendorList: boolean;
  vendorList: Vendor[];
  isCreateOrUpdateVendorLoading: boolean;
  isLoadingVendor: boolean;
  vendor: Vendor | undefined;
  currentVendor: VendorCreateAndUpdateRequest | undefined | Vendor;
  isDeleteVendorLoading: boolean;
  vendorListHeaders: EntityState<Vendor>;
}

const initialState: VendorManagementState = {
  isLoadingVendorList: true,
  vendorList: [],
  isCreateOrUpdateVendorLoading: false,
  isLoadingVendor: true,
  vendor: undefined,
  currentVendor: undefined,
  isDeleteVendorLoading: false,
  vendorListHeaders: vendorListAdapter.getInitialState(),
};

// Reducers
export const vendorManagementSlice = createSlice({
  name: 'vendorManagement',
  initialState: initialState,
  reducers: {
    setIsLoadingVendorList: (state: VendorManagementState, action: PayloadAction<boolean>) => {
      state.isLoadingVendorList = action.payload;
    },
    setVendorList: (state: VendorManagementState, action: PayloadAction<Vendor[]>) => {
      vendorListAdapter.setAll(state.vendorListHeaders, action.payload);
      state.vendorList = action.payload;
    },
    resetVendorList: (state: VendorManagementState) => {
      state.vendorListHeaders = initialState.vendorListHeaders;
      state.vendorList = initialState.vendorList;
      state.isLoadingVendorList = initialState.isLoadingVendorList;
    },
    setIsCreateOrUpdateVendorLoading: (state: VendorManagementState, action: PayloadAction<boolean>) => {
      state.isCreateOrUpdateVendorLoading = action.payload;
    },
    setIsLoadingVendor: (state: VendorManagementState, action: PayloadAction<boolean>) => {
      state.isLoadingVendor = action.payload;
    },
    setVendor: (state: VendorManagementState, action: PayloadAction<Vendor>) => {
      state.vendor = action.payload;
    },
    resetVendor: (state: VendorManagementState) => {
      state.vendor = initialState.vendor;
      state.isLoadingVendor = initialState.isLoadingVendor;
    },
    setIsDeleteVendorLoading: (state: VendorManagementState, action: PayloadAction<boolean>) => {
      state.isDeleteVendorLoading = action.payload;
    },
  },
});

export const { selectAll: selectAllVendorListHeaders } = vendorListAdapter.getSelectors<RootState>(
  (state: RootState) => state.vendorManagement.vendorListHeaders
);
