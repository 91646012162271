import { UserManagementFilterType } from './api-shared.enums';
import { AreaSetting } from './site.models';

export interface ManageUser {
  UserId: string;
  CustomerIds: string[];
  FirstName: string;
  LastName: string;
  Email?: string;
  UserName?: string;
  IsPendingUser: boolean;
  ExpiresDate?: Date | string;
  LastLoginDate?: Date | string;
  ManagedUserStatus: ManagedUserStatusTypes;
}

export interface ManageUserPermissions {
  CanManageUserAccounts?: boolean;
  CanCreateOrder?: boolean;
  CanSubmitOrder?: boolean;
  CanViewPrices?: boolean;
  CanModifyCustomAttributes?: boolean;
  CanAccessListManagement?: boolean;
  CanManageList?: boolean;
  CanAccessInvoiceArea?: boolean;
  CanAccessARStatementArea?: boolean;
  CanSendMessages?: boolean;
  CanOnlyAccessManagedListProducts?: boolean;
  CanOnlyShowLowestUOM?: boolean;
  CanAccessParManagement?: boolean;
  CanViewInvoiceInsightsArea?: boolean;

  CanAccessInventoryManagement?: boolean;
  CanViewThirdPartyVendors?: boolean;
  CanCreateInventory?: boolean;
  CanApproveInventory?: boolean;
  CanViewInventoryPricing?: boolean;
}

export interface ManageUserCustomerPermissions {
  UserId: string;
  IsPendingUser: boolean;
  CustomerId: string;
  Permissions: ManageUserPermissions;
  AreaSettings: AreaSetting;
}

export interface RemoveUserFromLocationRequest {
  UserCustomers: ManageUserCustomer[];
}

export interface ManageUserCustomer {
  UserId: string;
  IsPendingUser: boolean;
  CustomerId: string;
}

export interface ManageUsersReportSpecifications {
  IncludeAlternativeRowShading: boolean;
  IncludeDoubleSpacing: boolean;
  IncludeLastActiveDate: boolean;
  IncludeLocationBreaks: boolean;
  IncludeActiveUsers: boolean;
  IncludePendingUsers: boolean;
  IncludeExpiredUsers: boolean;
  IncludeInactiveUsers: boolean;
  IncludePermissions: boolean;
  FilterText: string;
  LastActive: number;
}

// requests
export interface GetManagedUsersByCustomerRequest {
  CustomerIds: string[];
}

export interface GetUserCustomerPermissionsRequest {
  UserId: string;
  IsPendingUser: boolean;
  CustomerId: string;
}

export interface CreatePendingUserRequest {
  FirstName: string;
  LastName: string;
  Email: string;
  CustomerIds: string[];
  Permissions: ManageUserPermissions;
}

export interface AddUserToLocationRequest {
  UserId: string;
  IsPendingUser: boolean;
  CustomerIds: string[];
}

export interface SetUserCustomerPermissionsRequest {
  UpdatedUserCustomerPermissions: ManageUserCustomerPermissions[];
}

export interface ResendInvitationRequest {
  UserEmail: string;
}

export interface GetManagedUsersReportRequest {
  CustomerIds: string[];
  FileFormat: number;
  ReportSpecifications: ManageUsersReportSpecifications;
  PageOrientation: number;
  SortByType: number;
  TimeZoneIdentifier: string;
}

export interface GetManagedUserExistsRequest {
  Email: string;
}

export interface SetUserCustomersRequest {
  UserId: string;
  CustomerIds: string[];
}
// responses
export type ManageUsersSpecificationProps = keyof ManageUsersReportSpecifications;

export interface UserManagementAdvanceFilter {
  IncludeActiveUsers?: boolean;
  IncludePendingUsers?: boolean;
  IncludeExpiredUsers?: boolean;
  IncludeInactiveUsers?: boolean;
  FilterText?: string;
}

export interface UserManagementFilter {
  position: UserManagementFilterType;
  name: string;
  label?: string;
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  value: any;
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  parameterValue?: any;
  additionalValue?: number;
  disabled?: boolean;
}

export interface UserManagementFilterData {
  Status: { label: string; items: UserManagementFilter[] };
  LastActive: { label: string; items: UserManagementFilter[] };
}

export enum LastActiveSelection {
  All = 0,
  MoreThan30Days = 1,
  MoreThan60Days = 2,
  MoreThan90Days = 3,
  MoreThan6Months = 6,
  MoreThan1Year = 12,
}

export enum ManagedUserStatusTypes {
  Active = 0,
  Inactive = 1,
  Pending = 2,
  Expired = 3,
}
