import { createEntityAdapter, createSelector, createSlice, EntityState, PayloadAction } from '@reduxjs/toolkit';
import { ProductListCategory, ProductListSortByType, ProductListSortOption } from '../../api';
import {
  ProductListProduct,
  SearchProductListRequest,
  SearchProductListResultDetail,
} from '../../api/models/product-list-search.models';
import { normalizeProductKey } from '../../helpers';
import { getNormalizedProductListGridData } from '../../helpers/general/product-list';
import {
  ProductListCategoryCustom,
  ProductListCategoryProduct,
  ProductListGridData,
} from '../../models/product-list.models';

// Adapters
const categoriesAdapter = createEntityAdapter<ProductListCategoryCustom>({
  selectId: (category: ProductListCategoryCustom) => category.Id,
});

const categoryProductsAdapter = createEntityAdapter<ProductListCategoryProduct>({
  selectId: (categoryProduct: ProductListCategoryProduct) => categoryProduct.Id,
});

const productsAdapter = createEntityAdapter<ProductListProduct>({
  selectId: (product: ProductListProduct) => normalizeProductKey(product.Id),
});

const checkedProductsAdapter = createEntityAdapter<ProductListProduct>({
  selectId: (product: ProductListProduct) => normalizeProductKey(product.Id),
});

// Selectors
const getState = (state: ProductListAddFromListState): ProductListAddFromListState => state;
const getId = (state: ProductListAddFromListState, id: string): string => id;
const getSortByType = (state: ProductListAddFromListState, sortByType: ProductListSortByType): ProductListSortByType =>
  sortByType;

// Adapter Selectors
export const { selectAll: selectAllAddToCategories, selectById: selectAddToCategory } =
  categoriesAdapter.getSelectors<ProductListAddFromListState>((state: ProductListAddFromListState) => state.categories);

export const { selectAll: selectAllAddToCategoryProducts, selectById: selectAddToCategoryProduct } =
  categoryProductsAdapter.getSelectors<ProductListAddFromListState>(
    (state: ProductListAddFromListState) => state.categoryProducts
  );

export const { selectAll: selectAllAddToProductListProduct, selectById: selectAddToProductListProduct } =
  productsAdapter.getSelectors<ProductListAddFromListState>((state: ProductListAddFromListState) => state.products);

export const { selectAll: selectAllCheckedAddToProductListProduct } =
  checkedProductsAdapter.getSelectors<ProductListAddFromListState>(
    (state: ProductListAddFromListState) => state.checkedProducts
  );

// Custom selectors
export const selectIsAddToProductChecked = createSelector([getState, getId], (state, id) => {
  const normalizedKey = normalizeProductKey(id);
  const checkedProduct = state.checkedProducts.entities[normalizedKey];
  return Boolean(checkedProduct);
});

// Grid Selectors
export const selectAllProductListIdsToAdd = createSelector([getState, getSortByType], (state, sortByType) => {
  // Return catalog products and empty categories in the order in which they arrived
  const includeCategoryIds =
    sortByType === ProductListSortByType.Category || sortByType === ProductListSortByType.ListCategory;

  const ids = [] as string[];
  state.categories.ids.forEach((categoryId) => {
    const category = selectAddToCategory(state, categoryId);
    if (category) {
      if (includeCategoryIds) ids.push(category.Id);
      if (!category.IsCollapsed) ids.push(...category.CategoryProductIds);
    }
  });
  return ids;
});

// export const selectAllProductListIdsToAdd = createSelector([getState], (state) => {
//   //Return catalog products and empty categories in the order in which they arrived
//   const ids = [] as string[];
//   state.categories.ids.forEach((categoryId) => {
//     const category = selectAddToCategory(state, categoryId);
//     if (category) ids.push(...[category.Id, ...category.CategoryProductIds]);
//   });
//   return ids;
// });

export const selectProductListGridDataToAdd = createSelector([getState, getId], (state, id) => {
  // Returns categoryProducts and empty categories, takes both CategoryId and ProductCategoryId
  const categoryProduct = selectAddToCategoryProduct(state, id);
  if (categoryProduct) {
    const category = selectAddToCategory(state, categoryProduct.CategoryId);
    const product = selectAddToProductListProduct(state, normalizeProductKey(categoryProduct.ProductId));
    if (product) return { category, categoryProduct, product } as ProductListGridData;
  }

  const category = selectAddToCategory(state, id);
  if (category) return { category: category, categoryProduct: undefined, product: undefined } as ProductListGridData;
  return undefined;
});

interface ProductListAddFromListState {
  apiRequest?: SearchProductListRequest;
  productListLoading: boolean;
  addFromListDialogOpen: boolean;
  categories: EntityState<ProductListCategoryCustom>;
  categoryProducts: EntityState<ProductListCategoryProduct>;
  products: EntityState<ProductListProduct>;
  sortByOptions: ProductListSortOption[];
  categorySelectOptions: ProductListCategory[];
  checkedProducts: EntityState<ProductListProduct>;
  totalProductsUnfiltered: number;
  promptDisabled: boolean;
}

const initialState: ProductListAddFromListState = {
  apiRequest: undefined,
  productListLoading: true,
  addFromListDialogOpen: false,
  categories: categoriesAdapter.getInitialState(),
  categoryProducts: categoryProductsAdapter.getInitialState(),
  products: productsAdapter.getInitialState(),
  sortByOptions: [],
  categorySelectOptions: [],
  checkedProducts: checkedProductsAdapter.getInitialState(),
  totalProductsUnfiltered: 0,
  promptDisabled: false,
};

// Reducers
export const productListAddFromListSlice = createSlice({
  name: 'productListAddFromList',
  initialState: initialState,
  reducers: {
    resetState: () => {
      return initialState;
    },
    resetProductSearchResults: (state: ProductListAddFromListState) => {
      state.apiRequest = initialState.apiRequest;
      state.categories = categoriesAdapter.getInitialState();
      state.categoryProducts = categoryProductsAdapter.getInitialState();
      state.products = productsAdapter.getInitialState();
      state.productListLoading = initialState.productListLoading;
    },
    setProductSearchResults: (
      state: ProductListAddFromListState,
      action: PayloadAction<{
        request: SearchProductListRequest;
        result: SearchProductListResultDetail;
      }>
    ) => {
      const { request, result } = action.payload;

      state.apiRequest = request;

      const data = getNormalizedProductListGridData(result.ProductListCategories, true);
      categoriesAdapter.setAll(state.categories, data.categories);
      categoryProductsAdapter.setAll(state.categoryProducts, data.categoryProducts);
      productsAdapter.setAll(state.products, data.products);
    },
    setProductListLoading: (state: ProductListAddFromListState, action: PayloadAction<boolean>) => {
      state.productListLoading = action.payload;
    },
    toggleAddFromListDialog: (state: ProductListAddFromListState, action: PayloadAction<boolean>) => {
      state.addFromListDialogOpen = action.payload;
    },
    searchProductList: (state: ProductListAddFromListState, action: PayloadAction<SearchProductListResultDetail>) => {
      const data = getNormalizedProductListGridData(action.payload.ProductListCategories, true);
      categoriesAdapter.setAll(state.categories, data.categories);
      categoryProductsAdapter.setAll(state.categoryProducts, data.categoryProducts);
      productsAdapter.setAll(state.products, data.products);
    },
    updateProductIsChecked: (
      state: ProductListAddFromListState,
      action: PayloadAction<{ isChecked: boolean; productId: string }[]>
    ) => {
      action.payload.forEach((item) => {
        const productId = normalizeProductKey(item.productId);
        const product = state.products.entities[productId];
        if (!product) return;

        if (item.isChecked) checkedProductsAdapter.upsertOne(state.checkedProducts, product);
        else checkedProductsAdapter.removeOne(state.checkedProducts, productId);
      });
    },
    updateAddToCategoryIsCollapsed: (
      state: ProductListAddFromListState,
      action: PayloadAction<{ isCollapsed: boolean; categoryId: string }>
    ) => {
      const category = state.categories.entities[action.payload.categoryId];
      if (category) category.IsCollapsed = action.payload.isCollapsed;
    },
    setStandardSortByOptions: (state: ProductListAddFromListState, action: PayloadAction<ProductListSortOption[]>) => {
      state.sortByOptions = action.payload ?? [];
    },
    setCustomCategorySortByOptions: (
      state: ProductListAddFromListState,
      action: PayloadAction<ProductListCategory[]>
    ) => {
      // Note: Intentionally avoiding the use of the custom model here.
      //       Ids are generated and will not be the same between categories in the select and categories in the grid.
      state.categorySelectOptions = action.payload ?? [];
    },
    setTotalProductsUnfiltered: (state: ProductListAddFromListState, action: PayloadAction<number>) => {
      state.totalProductsUnfiltered = action.payload;
    },
    setPromptDisabled: (state: ProductListAddFromListState, action: PayloadAction<boolean>) => {
      state.promptDisabled = action.payload;
    },
  },
});
