import { ServiceResult } from '../models/api-shared.models';
import {
  GetThirdPartyVendorCustomersResult,
  UpdateThirdPartyVendorCustomersRequest,
  UpdateThirdPartyVendorPricesRequest,
  Vendor,
  VendorCreateAndUpdateRequest,
  VendorDeleteRequest,
  VendorListRequest,
  VendorRequest,
} from '../models/vendor-list-detail-models';
import ApiService from './api-service';

class VendorManagementService extends ApiService {
  private static classInstance: VendorManagementService;

  private constructor() {
    super('ThirdPartyVendor');
  }

  public getVendorList(request: VendorListRequest): ServiceResult<Vendor[]> {
    return this.get(`${this.baseUrl}/GetThirdPartyVendors`, request);
  }

  public async getVendor(request: VendorRequest): ServiceResult<Vendor> {
    return this.get(`${this.baseUrl}/GetThirdPartyVendor`, request);
  }

  public async createVendor(request: VendorCreateAndUpdateRequest): ServiceResult<Vendor> {
    return this.post(`${this.persistUrl}/CreateThirdPartyVendor`, request);
  }

  public async updateVendor(request: VendorCreateAndUpdateRequest): ServiceResult<Vendor> {
    return this.post(`${this.baseUrl}/UpdateThirdPartyVendor`, request);
  }

  public async deleteVendor(request: VendorDeleteRequest): ServiceResult<undefined> {
    return this.post(`${this.baseUrl}/DeleteThirdPartyVendor`, request);
  }

  public getThirdPartyVendorCustomers(thirdPartyVendorId: string): ServiceResult<GetThirdPartyVendorCustomersResult> {
    return this.get(`${this.baseUrl}/GetThirdPartyVendorCustomers`, { thirdPartyVendorId });
  }

  public updateThirdPartyVendorCustomers(request: UpdateThirdPartyVendorCustomersRequest): ServiceResult<undefined> {
    return this.post(`${this.baseUrl}/UpdateThirdPartyVendorCustomers`, request);
  }

  public updateVendorPrices(request: UpdateThirdPartyVendorPricesRequest): ServiceResult<undefined> {
    return this.post(`${this.baseUrl}/UpdateThirdPartyVendorPrices`, request);
  }

  public static getInstance(): VendorManagementService {
    if (!this.classInstance) {
      this.classInstance = new VendorManagementService();
    }
    return this.classInstance;
  }
}

export default VendorManagementService;
