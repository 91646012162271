import {
  CostAnalysisRequest,
  CostAnalysisUserOptionsDefaults,
  InventoryConsumptionRequest,
  InventoryConsumptionUserOptionsDefaults,
  InventoryValuationRequest,
  InventoryValuationUserOptionsDefaults,
  InventoryWorksheetOptionDefaults,
  InventoryWorksheetRequest,
} from '../models';
import { FileResult, ServiceResult } from '../models/api-shared.models';
import ApiService from './api-service';

class InventoryReportService extends ApiService {
  private static classInstance: InventoryReportService;

  private constructor() {
    super('InventoryReport');
  }

  public getInventoryWorksheet(request: InventoryWorksheetRequest): ServiceResult<FileResult> {
    return this.post(`${this.baseUrl}/GetInventoryWorksheet`, request);
  }

  public getInventoryWorksheetUserOptions(): ServiceResult<InventoryWorksheetOptionDefaults> {
    return this.get(`${this.baseUrl}/GetInventoryWorksheetUserOptions`);
  }

  public getInventoryValuation(request: InventoryValuationRequest): ServiceResult<FileResult> {
    return this.post(`${this.baseUrl}/GetInventoryValuation`, request);
  }

  public getInventoryValuationUserOptions(): ServiceResult<InventoryValuationUserOptionsDefaults> {
    return this.get(`${this.baseUrl}/GetInventoryValuationUserOptions`);
  }

  public getCostAnalysis(request: CostAnalysisRequest): ServiceResult<FileResult> {
    return this.post(`${this.baseUrl}/GetCostAnalysis`, request);
  }

  public getCostAnalysisUserOptions(): ServiceResult<CostAnalysisUserOptionsDefaults> {
    return this.get(`${this.baseUrl}/GetCostAnalysisUserOptions`);
  }

  public getInventoryConsumption(request: InventoryConsumptionRequest): ServiceResult<FileResult> {
    return this.post(`${this.baseUrl}/GetInventoryConsumption`, request);
  }

  public getInventoryConsumptionUserOptions(): ServiceResult<InventoryConsumptionUserOptionsDefaults> {
    return this.get(`${this.baseUrl}/GetInventoryConsumptionUserOptions`);
  }

  public static getInstance(): InventoryReportService {
    if (!this.classInstance) {
      this.classInstance = new InventoryReportService();
    }
    return this.classInstance;
  }
}

export default InventoryReportService;
