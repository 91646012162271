import { InventoryEntryDetail, UpdateInventoryEntryDetailRequest } from '../models';
import { ServiceResult } from '../models/api-shared.models';
import ApiService from './api-service';

class InventoryEntryDetailService extends ApiService {
  private static classInstance: InventoryEntryDetailService;

  private constructor() {
    super('InventoryEntryDetail');
  }

  public updateInventoryEntryDetail(request: UpdateInventoryEntryDetailRequest): ServiceResult<InventoryEntryDetail> {
    return this.post(`${this.baseUrl}/UpdateInventoryEntryDetail`, request);
  }

  public static getInstance(): InventoryEntryDetailService {
    if (!this.classInstance) {
      this.classInstance = new InventoryEntryDetailService();
    }
    return this.classInstance;
  }
}

export default InventoryEntryDetailService;
