import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { History } from 'history';
import { getProductListCategories, getProductListHeader, productComparisonSlice, productListSlice } from '..';
import { BusinessUnitERPKeyType, UnitOfMeasureType } from '../../api/models/api-shared.enums';
import { APIMessages, CatalogProduct, UnitOfMeasureOrderQuantity } from '../../api/models/api-shared.models';
import { CustomerProductPriceResult } from '../../api/models/customer-product-price.models';
import {
  AdvanceFilter,
  ProductSearchFilter,
  SearchCacheEntry,
  TypeAheadRequest,
  TypeAheadResponse,
} from '../../api/models/product-catalog.models';
import { ProductListDetail } from '../../api/models/product-list-detail.models';
import { SearchProductListCatalogRequest } from '../../api/models/product-list-search.models';
import ProductListDetailService from '../../api/services/product-list-detail.service';
import ProductListSearchService from '../../api/services/product-list-search.service';
import TypeaheadService from '../../api/services/typeahead.service';
import { CfGa } from '../../helpers';
import { getProductListCatalogSearchURL } from '../../helpers/general/routing';
import { useAppInsightsLogger } from '../../logging/AppInsightsLogger';
import { ProductListCatalogSearchMode, ProductListHistoryState } from '../../models/product-list.models';
import { getCustomerProductPrices, updateSelectedCustomerByCustomerId } from '../common/customer.thunks';
import { setErrorDialogContent } from '../common/global.thunks';
import { ProductSearchHistoryState, isAdvanceFilterUnset, updateAdvanceFilterObject } from '../product-search-shared';
import { resetProductComparisons } from '../search/product-compare.thunks';
import { AppDispatch, AppThunk, RootState } from '../store';
import { productListSearchSlice } from './product-list-search.slice';

const productListSearchService = ProductListSearchService.getInstance();
const typeAheadService = TypeaheadService.getInstance();
const productListDetailService = ProductListDetailService.getInstance();
const appInsightsLogger = useAppInsightsLogger();

/**
 * Resets the apiRequest, productSearchResult, products and productListCategories values in the product list search slice to their intial values
 *
 * @returns NULL
 */
export const resetProductListSearchState = (): AppThunk => async (dispatch: AppDispatch) => {
  productListSearchService.abortSearchProductListCatalog();
  dispatch(productListSearchSlice.actions.resetProductSearchState());
};

export const updateProductIsOnList =
  (productListHeaderIds: string[], productKey: string, isOnList: boolean): AppThunk =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    if (!productListHeaderIds.includes(getState().productListSearch.apiRequest?.ViewProductListHeaderId ?? '')) return;
    dispatch(productListSearchSlice.actions.setProductListCatalogProductIsOnList({ productKey, isOnList }));
  };

/**
 * Calls and stores the result of the SearchProductListCatalog API call
 *
 * @param request - Holds the query text, product list header, customer id, search mode, history and page index used to make the API call
 * @returns NULL
 */
export const searchProductListCatalog =
  (
    request: {
      queryText: string;
      productListHeaderId: string;
      customerId: string;
      searchMode: ProductListCatalogSearchMode;
      history: History<ProductListHistoryState & ProductSearchHistoryState>;
      pageIndex?: number;
    },
    onFailure?: () => void
  ): AppThunk =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      dispatch(updateSelectedCustomerByCustomerId(request.customerId));

      // Handle history state
      const history = request.history;
      const historyState = history.location.state;

      let productListHeader = historyState?.productListHeader;

      if (productListHeader === undefined || productListHeader.ProductListHeaderId !== request.productListHeaderId) {
        await dispatch(
          getProductListHeader(
            request.productListHeaderId,
            (data) => {
              productListHeader = data;

              history.replace(
                getProductListCatalogSearchURL(
                  request.customerId,
                  request.productListHeaderId,
                  request.queryText,
                  request.searchMode
                ),
                {
                  ...history.location.state,
                  productListHeader: data,
                }
              );
            },
            (messages) => {
              dispatch(setErrorDialogContent('Error occurred', messages));
              onFailure?.();
            }
          )
        );
      }

      const selectedCustomer = getState().customer.selectedCustomer;

      const advanceFilter = historyState?.advanceFilter ?? {
        IsCriticalItem: undefined,
        IsNewInStock: undefined,
        HasOrderedInLastNumberOfDays: undefined,
        HasPreviousPurchase: undefined,
        Badges: [],
        CategoryIds: [],
        Brand: undefined,
        Brands: [],
        PackSize: undefined,
        StorageTypes: [],
        DeliveryOptions: {},
        Nutritional: {},
        StateOfOriginAbbreviations: [],
        StateOfOriginAbbreviation: undefined,
      };

      const pageSize = 25;
      const pageIndex = request.pageIndex ?? 0;
      const skip = (request.pageIndex === undefined ? 0 : getState().productListSearch.productSearchResult?.Skip) ?? 0;

      const searchValue = request.queryText;

      if (!selectedCustomer || !productListHeader || !advanceFilter) return;

      const apiRequest: SearchProductListCatalogRequest = {
        BusinessUnitKey: selectedCustomer.BusinessUnitKey,
        OperationCompanyNumber: selectedCustomer.OperationCompanyNumber,
        CustomerId: selectedCustomer.CustomerId,
        ViewProductListHeaderId: productListHeader.ProductListHeaderId,
        Skip: skip,
        PageSize: pageSize,
        QueryText: searchValue,
        AdvanceFilter: advanceFilter,
        ProductNumberSearchOnly: false,
        pageIndex,
      };

      if (!getState().productListSearch.apiRequest) dispatch(resetProductComparisons());

      // Data in store is for search with different parameters (excluding page number and skip)
      if (
        JSON.stringify({ ...apiRequest, pageIndex: 0, Skip: 0 }) !==
        JSON.stringify({ ...getState().productListSearch.apiRequest, pageIndex: 0, Skip: 0 })
      ) {
        dispatch(productListSearchSlice.actions.resetProductSearchResults());
      }

      if (
        JSON.stringify({ ...apiRequest, pageIndex, Skip: skip }) ===
        JSON.stringify({ ...getState().productListSearch.apiRequest, pageIndex: 0, Skip: 0 })
      ) {
        return;
      }

      dispatch(
        productListSearchSlice.actions.setPageLoading({
          pageIndex: pageIndex,
          pageSize: pageSize,
          isLoading: true,
        })
      );

      const { data } = await productListSearchService.searchProductListCatalog(apiRequest);

      if (data.IsSuccess) {
        data.ResultObject.pageIndex = apiRequest.pageIndex;

        if (isAdvanceFilterUnset(history.location.state?.advanceFilter)) {
          history.replace(history.location.pathname + history.location.search, {
            ...history.location.state,
            cachedFacets: data.ResultObject.Facets,
          });
        }

        const activeProductIndex = historyState?.activeProductIndex ?? 0;
        const fromProduct = historyState?.findSimilarProducts?.[activeProductIndex]?.product;
        dispatch(
          productListSearchSlice.actions.setProductSearchResults({
            request: apiRequest,
            result: data.ResultObject,
            searchMode: request.searchMode,
            replaceProductOptions: {
              fromProduct: fromProduct,
            },
          })
        );
        if (fromProduct && request.searchMode === 'replace')
          dispatch(
            productComparisonSlice.actions.addComparisonProduct({
              product: fromProduct,
            })
          );
      } else {
        dispatch(setErrorDialogContent('Error occurred', data.ErrorMessages));
        dispatch(
          productListSearchSlice.actions.setPageLoaded({
            pageIndex: pageIndex,
            catalogProducts: [],
          })
        );
      }
    } catch (error) {
      console.error(error);
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
    }
  };

/**
 * Gets the product list's search categories
 *
 * @param productListHeaderId - Id of the product list header to get the categories of
 * @param onSuccess - Method to call on succeess
 * @returns NULL
 */
export const getProductListSearchCategories =
  (productListHeaderId: string, onSuccess?: () => void): AppThunk =>
  async (dispatch: AppDispatch) => {
    dispatch(
      getProductListCategories(productListHeaderId, true, (productListCategories) => {
        dispatch(productListSearchSlice.actions.setProductListCategories(productListCategories));
        const selectedCategoryId = productListCategories?.[0].ProductListCategoryId;
        if (selectedCategoryId)
          dispatch(productListSearchSlice.actions.setSelectedProductListCategoryId(selectedCategoryId));
        onSuccess?.();
      })
    );
  };

/**
 * Sets the product list's search page's loading values
 *
 * @param pageIndex - The page index used to set the page loading
 * @param callback - Method to call at then end of this method
 * @returns NULL
 */
export const setProductListSearchPageLoading =
  (pageIndex: number, callBack?: () => void | Promise<void>): AppThunk =>
  async (dispatch: AppDispatch) => {
    const pageSize = 25;
    dispatch(
      productListSearchSlice.actions.setPageLoading({
        pageIndex: pageIndex,
        pageSize: pageSize,
        isLoading: true,
      })
    );
    callBack?.();
  };

/**
 * Sets the product list's search page's loading values
 *
 * @param history - The history to be used to update the URL
 * @param filters - Has the product search's filters
 * @returns NULL
 */
export const updateProductListSearchFilters =
  (history: History<{ advanceFilter?: AdvanceFilter }>, ...filters: ProductSearchFilter[]): AppThunk =>
  (dispatch: AppDispatch) => {
    dispatch(productListSearchSlice.actions.resetProductSearchResults());
    const advanceFilter = updateAdvanceFilterObject(history.location.state?.advanceFilter, filters);
    history.replace(history.location.pathname + history.location.search, {
      ...history.location.state,
      advanceFilter,
    });
  };

/**
 * Resets the apiRequest, productSearchResult and products values in the slice to their inital values
 *
 * @returns NULL
 */
export const resetProductListSearchResults = (): AppThunk => (dispatch: AppDispatch) => {
  dispatch(productListSearchSlice.actions.resetProductSearchResults());
};

/**
 * Resets the apiRequest, productSearchResult and products values in the slice to their inital values and updates the URL accordingly
 *
 * @param history - The history to be used to update the URL
 * @returns NULL
 */
export const clearProductListSearchFilters =
  (history: History<ProductSearchHistoryState>): AppThunk =>
  (dispatch: AppDispatch) => {
    dispatch(productListSearchSlice.actions.resetProductSearchResults());
    history.replace(history.location.pathname + history.location.search, {
      ...history.location.state,
      advanceFilter: undefined,
    });
  };

/**
 * Calls the CreateProductListDetail API call using list header ids
 *
 * @param headerIds - Array of the header ids
 * @param categoryId - The category's id
 * @param productBusinessUnitERPKey - The product's BU ERP key
 * @param product - Used to get product keys and units of measure
 * @param successCallback - Method to call on success
 * @param errorCallback - Method to call on error
 * @param analytics - Used for event tracking
 * @returns NULL
 */
export const createProductListDetailByListHeaderIds =
  (params: {
    headerIds?: string[];
    categoryId?: string | null;
    productBusinessUnitERPKey: BusinessUnitERPKeyType;
    product: {
      productKey: string | undefined;
      thirdPartyVendorProductId?: string;
      unitsOfMeasure: number[];
    };
    successCallback?: (productListDetails: ProductListDetail[]) => void | Promise<void>;
    errorCallback?: (errors: string[]) => void | Promise<void>;
    analytics?: CfGa;
  }): AppThunk =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      dispatch(productListSlice.actions.setShowNewListDialogLoading(true));
      dispatch(productListSlice.actions.setAddToListDialogLoading(true));
      let listHeaderIds: string[] = [];
      const selectedListHeaderId =
        getState().productListSearch.apiRequest?.ViewProductListHeaderId ??
        getState().productList.productListHeader?.ProductListHeaderId;
      const selectedProductListCategoryId = getState().productListSearch.selectedProductListCategoryId;

      if (params.headerIds) listHeaderIds = params.headerIds;
      else if (selectedListHeaderId) listHeaderIds.push(selectedListHeaderId);
      else return;

      const { data } = await productListDetailService.createProductListDetail({
        CreateProductListDetails: listHeaderIds.map((id) => {
          return {
            ProductListHeaderId: id,
            ProductListCategoryId: params.categoryId || selectedProductListCategoryId,
            ProductBusinessUnitERPKey: params.productBusinessUnitERPKey,
            ThirdPartyVendorProductId: params.product.thirdPartyVendorProductId,
            ProductKey: params.product.productKey,
            UnitOfMeasures: params.product.unitsOfMeasure,
          };
        }),
      });

      if (data.IsSuccess) {
        params.successCallback?.(data.ResultObject);
        listHeaderIds.forEach((headerId) =>
          params.analytics?.event(headerId, 'add_to_list', params.product.productKey)
        );
      } else {
        params.errorCallback?.(data.ErrorMessages);
        dispatch(setErrorDialogContent('Error occured', data.ErrorMessages));
      }
    } catch (error) {
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
    } finally {
      dispatch(productListSlice.actions.setShowNewListDialogLoading(false));
      dispatch(productListSlice.actions.setAddToListDialogLoading(false));
    }
  };

/**
 * Calls the CreateProductListDetail API call using product keys
 *
 * @param headerIds - Array of the header ids
 * @param categoryId - The category's id
 * @param productBusinessUnitERPKey - The product's BU ERP key
 * @param product - Used to get product keys and units of measure
 * @param successCallback - Method to call on success
 * @param errorCallback - Method to call on error
 * @param analytics - Used for event tracking
 * @returns NULL
 */
export const createProductListDetailByProductKeys =
  (params: {
    headerId: string;
    categoryId?: string | null;
    products: {
      thirdPartyVendorProductId: string | undefined;
      productKey: string | undefined;
      productBusinessUnitERPKey: BusinessUnitERPKeyType;
      unitsOfMeasure: UnitOfMeasureType[];
    }[];
    successCallback?: (productListDetails: ProductListDetail[]) => void | Promise<void>;
    errorCallback?: (errors: string[]) => void | Promise<void>;
    analytics?: CfGa;
  }): AppThunk =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      const selectedProductListCategoryId = getState().productListSearch.selectedProductListCategoryId;

      const { data } = await productListDetailService.createProductListDetail({
        CreateProductListDetails: params.products.map((product) => {
          return {
            ProductListHeaderId: params.headerId,
            ProductListCategoryId: params.categoryId || selectedProductListCategoryId,
            ProductBusinessUnitERPKey: product.productBusinessUnitERPKey,
            ProductKey: product.productKey,
            ThirdPartyVendorProductId: product.thirdPartyVendorProductId,
            UnitOfMeasures: product.unitsOfMeasure,
          };
        }),
      });

      if (data.IsSuccess) {
        params.successCallback?.(data.ResultObject);
        params.products?.forEach?.((product) =>
          params.analytics?.event(params.headerId, 'add_to_list', product.productKey)
        );
      } else {
        params.errorCallback?.(data.ErrorMessages);
      }
    } catch (error) {
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
    }
  };

/**
 * Calls and stores the results of the GetTypeAhead API call
 *
 * @param searchValue - Value to search for
 * @param isCalledFromDeleteFlow - Boolean checking if the user is deleting from an input
 * @returns NULL
 */
export const getProductListTypeAheadSearchResults =
  (searchValue: string, isCalledFromDeleteFlow = false): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    const request: TypeAheadRequest = { SearchValue: searchValue };

    const cache = getState().productListSearch.searchCache;
    let isCacheHit = false;
    if (!isCalledFromDeleteFlow && cache) {
      const cacheHitResult = cache.find((c) => c.key.toLowerCase() === request.SearchValue.toLowerCase());
      if (cacheHitResult) {
        isCacheHit = true;
        dispatch(productListSearchSlice.actions.setTypeAheadResults(cacheHitResult.value));
      }
    }
    if (!isCacheHit || isCalledFromDeleteFlow) {
      try {
        const selectedCustomer = getState().customer.selectedCustomer;
        request.BusinessUnitKey = selectedCustomer?.BusinessUnitKey;
        request.CustomerId = selectedCustomer?.CustomerId;
        const { data: result } = await typeAheadService.getTypeAheadSearchResults(request);
        const typeAheadAPIMessages: APIMessages = {
          IsError: false,
        };
        if (result.IsSuccess) {
          const typeAheadResult: TypeAheadResponse = result.ResultObject;
          if (result.InformationMessages && result.InformationMessages.length > 0) {
            typeAheadAPIMessages.InformationMessages = result.InformationMessages;
            dispatch(productListSearchSlice.actions.setTypeAheadAPIMessages(typeAheadAPIMessages));
          }
          if (request.SearchValue.toLowerCase() !== '') {
            dispatch(
              productListSearchSlice.actions.setSearchCache(
                updateCache(cache, isCalledFromDeleteFlow, request.SearchValue, result.ResultObject)
              )
            );
          }

          dispatch(productListSearchSlice.actions.setTypeAheadResults(typeAheadResult));
        } else {
          if (result.ErrorMessages && result.ErrorMessages.length > 0) {
            typeAheadAPIMessages.ErrorMessages = result.ErrorMessages;
            typeAheadAPIMessages.IsError = true;
            dispatch(productListSearchSlice.actions.setTypeAheadAPIMessages(typeAheadAPIMessages));
          }
        }
      } catch (error) {
        appInsightsLogger.trackException({
          exception: error,
          severityLevel: SeverityLevel.Error,
        });
      }
    }
  };

/**
 * Updates the cache
 *
 * @param cache - cache to be updated
 * @param isCalledFromDeleteFlow - Boolean checking if the user is deleting from an input
 * @param searchValue - Value being searched for
 * @param resultObject - the result of the GetTypeAhead API call
 * @returns NULL
 */
const updateCache = (
  cache: SearchCacheEntry[] | undefined,
  isCalledFromDeleteFlow: boolean,
  searchValue: string,
  resultObject: TypeAheadResponse
): SearchCacheEntry[] => {
  // update cache with new data
  let cacheCopy: SearchCacheEntry[] = [];
  if (cache) {
    if (isCalledFromDeleteFlow) {
      cacheCopy = cache.filter((f) => f.key.toLowerCase() !== searchValue);
    } else {
      cacheCopy = cache.map((c) => {
        return c;
      });
    }
  }
  const searcCacheEntry: SearchCacheEntry = {
    key: searchValue,
    value: resultObject,
  };
  cacheCopy.push(searcCacheEntry);
  return cacheCopy;
};

/**
 * Calls the DeleteSearchHistory API call
 *
 * @param searchValue - The value being search for
 * @param userCustomerSearchHistoryId - The id of the search history to be deleted
 * @returns NULL
 */
export const deleteProductListSearchHistory =
  (searchValue: string, userCustomerSearchHistoryId: string): AppThunk =>
  async (dispatch: AppDispatch) => {
    try {
      const { data: result } = await typeAheadService.deleteSearchHistory({
        UserCustomerSearchHistoryId: userCustomerSearchHistoryId,
      });
      if (result.IsSuccess) {
        dispatch(getProductListTypeAheadSearchResults(searchValue, true));
      }
    } catch (error) {
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
    }
  };

/**
 * Calls the SearchProductListCatalog API call
 *
 * @param queryText - The query to the product list catalog
 * @param onSuccess - Method to call on success
 * @param onPricingSuccess - Method to call when GetCustomerProductPrice API call is a success
 * @param onError - Method to call on error
 * @param doNotGetPrice - Stops the method from calling the GetCustomerProductPrice API call
 * @returns NULL
 */
export const getProductListCatalog =
  (
    queryText: string,
    onSuccess?: (product: CatalogProduct) => void,
    onPricingSuccess?: (product: CatalogProduct) => void,
    onError?: (errors: string[]) => void,
    doNotGetPrice?: boolean,
    includeInactiveProducts?: boolean
  ): AppThunk =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      const customer = getState().customer.selectedCustomer;
      if (!customer) return;
      if (!queryText) return;

      const request: SearchProductListCatalogRequest = {
        BusinessUnitKey: customer.BusinessUnitKey,
        OperationCompanyNumber: customer.OperationCompanyNumber,
        CustomerId: customer.CustomerId,
        Skip: 0,
        PageSize: 1,
        QueryText: queryText,
        ProductNumberSearchOnly: true,
        pageIndex: 0,
        IncludeInactiveProducts: includeInactiveProducts,
      };

      const { data } = await productListSearchService.searchProductListCatalog(request);

      if (data.IsSuccess && data.ResultObject.CatalogProducts?.length > 0) {
        const product = data.ResultObject.CatalogProducts[0].Product;
        onSuccess?.(product);
        if (!doNotGetPrice) {
          dispatch(
            getCustomerProductPrices(
              product.UnitOfMeasureOrderQuantities.map((uom) => {
                return { ProductKey: product.ProductKey, UnitOfMeasureType: uom.UnitOfMeasure };
              }),
              (priceResults: CustomerProductPriceResult[]) => {
                let productUom: UnitOfMeasureOrderQuantity | undefined = undefined;

                onPricingSuccess?.({
                  ...product,
                  UnitOfMeasureOrderQuantities: priceResults.map((p, idx) => {
                    productUom = product.UnitOfMeasureOrderQuantities.find(
                      (uom) => uom.UnitOfMeasure === p.UnitOfMeasureType
                    );
                    if (productUom) {
                      return {
                        ...productUom,
                        Price: p.Price,
                      };
                    }
                    return product.UnitOfMeasureOrderQuantities[idx];
                  }),
                });
              }
            )
          );
        }
      } else {
        if (data.IsSuccess) onError?.(data.ErrorMessages);
        else dispatch(setErrorDialogContent('Error occurred', data.ErrorMessages));
      }
    } catch (error) {
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
    } finally {
    }
  };

export const clearProductListSearchTypeAheadResults = (): AppThunk => async (dispatch: AppDispatch) => {
  dispatch(
    productListSearchSlice.actions.setTypeAheadResults({
      Suggestions: [],
      SearchHistory: [],
    })
  );
};
