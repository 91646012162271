import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { History } from 'history';
import { ProductListSortByType } from '../../api/models/api-shared.enums';
import {
  GetCopyToProductListHeadersRequest,
  ProductListHeaderCustom,
} from '../../api/models/product-list-header.models';
import { ProductListSortOption, SearchProductListRequest } from '../../api/models/product-list-search.models';
import ProductListHeaderService from '../../api/services/product-list-header.service';
import ProductListSearchService from '../../api/services/product-list-search.service';
import { getAddFromListURL } from '../../helpers/general/routing';
import { useAppInsightsLogger } from '../../logging';
import { AddFromListHistoryState } from '../../models/product-list.models';
import { updateSelectedCustomerByCustomerId } from '../common/customer.thunks';
import { setErrorDialogContent } from '../common/global.thunks';
import { AppDispatch, AppThunk, RootState } from '../store';
import { productListAddFromListSlice } from './product-list-add-from-list.slice';
import { getProductListCategories } from './product-list-category.thunks';
import { getProductListHeader } from './product-list.thunks';

const appInsightsLogger = useAppInsightsLogger();
const productListHeaderService = ProductListHeaderService.getInstance();
const productListSearchService = ProductListSearchService.getInstance();

/**
 * Resets values stores in the product-list-add-from-list slice to the inital values
 *
 * @returns NULL
 */
export const resetAddFromListState = (): AppThunk => async (dispatch: AppDispatch) => {
  dispatch(productListAddFromListSlice.actions.resetState());
};

/**
 * Sets the productListLoading value stored in the product-list-add-from-list slice
 *
 * @param loading - The value to set productListLoading to
 * @returns NULL
 */
export const setAddFromListIsLoading =
  (loading: boolean): AppThunk =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    if (!getState().productList.productListLoading !== loading) {
      dispatch(productListAddFromListSlice.actions.setProductListLoading(loading));
    }
  };

/**
 * Calls GetCopyToProductListHeaders API call and stores the results
 *
 * @param toProductListHeaderId - The header id of the product list being gotten
 * @param successCallback - Method to call on success
 * @param errorCallback - Method to call on error
 * @returns NULL
 */
export const getCopyToProductListHeaders =
  (
    toProductListHeaderId: string,
    successCallback?: (productLists: ProductListHeaderCustom[]) => void | Promise<void>,
    errorCallback?: (errors: string[]) => void | Promise<void>
  ): AppThunk =>
  async (_dispatch: AppDispatch, getState: () => RootState) => {
    try {
      const customerId = getState().customer.selectedCustomer?.CustomerId;
      if (!customerId) return;

      const request: GetCopyToProductListHeadersRequest = {
        CustomerId: customerId,
        ToProductListHeaderId: toProductListHeaderId,
      };

      const { data } = await productListHeaderService.getCopyToProductListHeaders(request);
      data.IsSuccess ? successCallback?.(data.ResultObject) : errorCallback?.(data.ErrorMessages);
    } catch (error: unknown) {
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
    }
  };

/**
 * Changes the addFromListDialogOpen value in the product-list-add-from slice
 *
 * @param open - The value to set addFromListDialogOpen to
 * @returns NULL
 */
export const toggleAddFromListDialog =
  (open: boolean): AppThunk =>
  async (dispatch: AppDispatch) => {
    dispatch(productListAddFromListSlice.actions.toggleAddFromListDialog(open));
  };

/**
 * Updates the isChecked attribute of the product value in the product-list-add-from slice
 *
 * @param products - Array of product keys and a boolean indicating if the product is checked
 * @returns NULL
 */
export const updateAddFromListProductChecked =
  (products: { isChecked: boolean; productId: string }[]): AppThunk =>
  async (dispatch: AppDispatch) => {
    dispatch(productListAddFromListSlice.actions.updateProductIsChecked(products));
  };

/**
 * Updates all products to have the same checked value
 *
 * @param isChecked - Value to set all products isChecked value to
 * @returns NULL
 */
export const updateAllAddToProductsChecked =
  (isChecked: boolean): AppThunk =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    const products = getState().productListAddFromList.products.ids.map((id) => ({
      isChecked,
      productId: id as string,
    }));
    dispatch(updateAddFromListProductChecked(products));
  };

/**
 * Calls and stores the result of the SearchProductList API call
 *
 * @param request - Holds the information needed to make the call
 * @param onFailure - Method to be called on error
 * @returns NULL
 */
export const searchProductListToAddFrom =
  (
    request: {
      productListHeaderId?: string;
      customerId?: string;
      sortByType: ProductListSortByType;
      q: string;
      fromProductListHeaderId?: string;
      isOnManagedListProductListHeaderId?: string;
      history: History<AddFromListHistoryState>;
    },
    onFailure?: () => void
  ): AppThunk =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      if (!request.customerId || !request.productListHeaderId || !request.fromProductListHeaderId) return onFailure?.();
      dispatch(updateSelectedCustomerByCustomerId(request.customerId));

      // Handle history state
      const history = request.history;
      const historyState = history?.location?.state;

      let dirtyHistoryHeaders = false;
      let productListHeader = historyState?.productListHeader;
      let fromProductListHeader = historyState?.fromProductListHeader;

      if (productListHeader === undefined || productListHeader.ProductListHeaderId !== request.productListHeaderId) {
        dirtyHistoryHeaders = true;
        await dispatch(
          getProductListHeader(
            request.productListHeaderId,
            (data) => {
              productListHeader = data;
            },
            (messages) => {
              dispatch(setErrorDialogContent('Error occurred', messages));
              onFailure?.();
            }
          )
        );
      }
      if (
        fromProductListHeader === undefined ||
        fromProductListHeader.ProductListHeaderId !== request.fromProductListHeaderId
      ) {
        dirtyHistoryHeaders = true;
        await dispatch(
          getProductListHeader(
            request.fromProductListHeaderId,
            (data) => {
              fromProductListHeader = data;
            },
            (messages) => {
              dispatch(setErrorDialogContent('Error occurred', messages));
              onFailure?.();
            }
          )
        );
      }

      if (dirtyHistoryHeaders && productListHeader && fromProductListHeader) {
        history.replace(
          getAddFromListURL(
            request.customerId,
            request.productListHeaderId,
            request.fromProductListHeaderId,
            `${request.sortByType}`,
            request.q
          ),
          {
            ...history.location.state,
            productListHeader: productListHeader,
            fromProductListHeader: fromProductListHeader,
          }
        );
      }

      // Search
      const selectedCustomer = getState().customer.selectedCustomer;
      if (!selectedCustomer || !productListHeader || !fromProductListHeader) return;

      const apiRequest: SearchProductListRequest = {
        CustomerId: selectedCustomer.CustomerId,
        ProductListHeaderId: request.fromProductListHeaderId,
        QueryText: request.q,
        SortByType: Number(request.sortByType),
        IsOnManagedListProductListHeaderId: request.productListHeaderId,
      };

      const stateRequest = getState().productListAddFromList.apiRequest;

      // Data in store is for search with different parameters
      if (JSON.stringify(apiRequest) !== JSON.stringify(stateRequest)) {
        dispatch(productListAddFromListSlice.actions.resetProductSearchResults());
      } else {
        return;
      }

      dispatch(productListAddFromListSlice.actions.setProductListLoading(true));

      const { data } = await productListSearchService.searchProductList(apiRequest);

      if (data.IsSuccess) {
        dispatch(
          productListAddFromListSlice.actions.setProductSearchResults({
            request: apiRequest,
            result: data.ResultObject,
          })
        );

        // Set total unfiltered products for select all checkbox
        if (!apiRequest.QueryText || !getState().productListAddFromList.totalProductsUnfiltered)
          dispatch(
            productListAddFromListSlice.actions.setTotalProductsUnfiltered(
              getState().productListAddFromList.products.ids.length
            )
          );
      } else {
        dispatch(setErrorDialogContent('Error occurred', data.ErrorMessages));
      }
    } catch (error) {
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
    } finally {
      dispatch(productListAddFromListSlice.actions.setProductListLoading(false));
    }
  };

/**
 * Sets the isCollapsed value of a category
 *
 * @param categoryId - The category id of the category being updated
 * @param newCollapseState - The value that isCollapsed is being set to
 * @returns NULL
 */
export const collapseAddFromCateogry =
  (categoryId: string, newCollapseState: boolean): AppThunk =>
  async (dispatch: AppDispatch) => {
    dispatch(
      productListAddFromListSlice.actions.updateAddToCategoryIsCollapsed({
        isCollapsed: newCollapseState,
        categoryId: categoryId,
      })
    );
  };

/**
 * Gets and stores result of GetProductListMaintenanceSort API call
 *
 * @param listId - The id of the list we are getting the sort by options of
 * @param onSuccess - Method to be called on success
 * @returns NULL
 */
export const getStandardProductListSortOptions =
  (listId: string, onSuccess?: (data: ProductListSortOption[]) => void): AppThunk<Promise<void>> =>
  async (dispatch: AppDispatch): Promise<void> => {
    try {
      const { data } = await productListSearchService.getProductListMaintenanceSort(listId);
      if (data.IsSuccess) {
        dispatch(productListAddFromListSlice.actions.setStandardSortByOptions(data.ResultObject));
        onSuccess?.(data.ResultObject);
      }
    } catch (error) {
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
    }
  };

/**
 * Gets and sets a list's category sort options by calling the getProductListCategories method from product-list-category.thunks.ts
 *
 * @param productListHeaderId - The id of the product list header
 * @returns NULL
 */
export const getCustomProductListSortOptions =
  (productListHeaderId: string): AppThunk =>
  async (dispatch: AppDispatch) => {
    dispatch(
      getProductListCategories(
        productListHeaderId,
        true,
        (data) => {
          dispatch(productListAddFromListSlice.actions.setCustomCategorySortByOptions(data));
        },
        (errorMessages) => dispatch(setErrorDialogContent('Error occurred', errorMessages))
      )
    );
  };

/**
 * Sets the value of promptDisabled value in the product-list-add-from-list slice
 *
 * @param params - Includes the value that promptDisabled should be set to and the method to call on success
 * @returns NULL
 */
export const setPromptDisabled =
  (params: { disabled: boolean; successCallback?: () => void | Promise<void> }): AppThunk =>
  async (dispatch: AppDispatch) => {
    await dispatch(productListAddFromListSlice.actions.setPromptDisabled(params.disabled));

    params.successCallback?.();
  };
