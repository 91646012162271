import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { BusinessUnitERPKeyType, UnitOfMeasureType } from '../../api/models/api-shared.enums';
import { APIMessages } from '../../api/models/api-shared.models';
import { Customer } from '../../api/models/customer.models';
import {
  CopyProductListRequest,
  CreateProductListHeaderRequest,
  GetProductListHeadersByProductKeyRequest,
  GetShareProductListHeaderCustomersResponse,
  ProductListHeader,
  ProductListHeaderCustom,
  ProductListHeaderForAddition,
  ProductListHeaderWithProductExistence,
  UpdateProductListCustomerRequest,
  UpdateShareProductListHeader,
} from '../../api/models/product-list-header.models';
import { UpdateProductListNotificationRequest } from '../../api/models/product-list-notification.models';
import ProductListDetailService from '../../api/services/product-list-detail.service';
import ProductListHeaderService from '../../api/services/product-list-header.service';
import ProductListNotificationService from '../../api/services/product-list-notification.service';
import { validateStringEmptyOrMaxLength } from '../../helpers/validation/general';
import { useAppInsightsLogger } from '../../logging/AppInsightsLogger';
import { ProductData, Resolution } from '../../models';
import { NotificationKeys } from '../../models/notifications.models';
import {
  UserActivityAction,
  UserActivityActionSummary,
  UserActivityPageName,
} from '../../models/user-activity-report.models';
import { globalSlice } from '../common/global.slice';
import { addAppNotification, setErrorDialogContent, setInformationDialogContent } from '../common/global.thunks';
import { logUserActivity } from '../common/user-activity.thunks';
import { AppDispatch, AppThunk, RootState } from '../store';
import {
  GetProductListsDetailCountResultData,
  ReplaceProductListDetailForMultiUnitAccountRequest,
  ReplaceProductListDetailRequest,
} from './../../api/models/product-list-detail.models';
import {
  productListManagementSlice,
  selectAllCustomProductListHeaderIds,
  selectAllManagedProductListHeaderIds,
} from './product-list-management.slice';
import { productListSlice } from './product-list.slice';
import { refreshProductListSearchResults } from './product-list.thunks';

const productListHeaderService = ProductListHeaderService.getInstance();
const productListDetailService = ProductListDetailService.getInstance();
const productListNotificationService = ProductListNotificationService.getInstance();
const appInsightsLogger = useAppInsightsLogger();

/**
 * Resets the listsLoading, productListHeaders, shareProductListCustomers, customListsCountsLoading and managedListsCountsLoading values in the slice
 *
 * @returns NULL
 */
export const resetProductListManagementState = (): AppThunk => async (dispatch: AppDispatch) => {
  dispatch(productListManagementSlice.actions.resetState());
};

/**
 * Calls the getAllProductListHeaders, getManagedProductListsCount and getCustomProductListsCount methods
 *
 * @returns NULL
 */
export const getProductListManagementHeadersAndCounts = (): AppThunk => async (dispatch: AppDispatch) => {
  await dispatch(getAllProductListHeaders(false));
  // 07/25/23 comment out list counts
  // dispatch(getManagedProductListsCount());
  // dispatch(getCustomProductListsCount());
};

/**
 * Calls the getProductListsDetailsCount with managed product list header ids
 *
 * @returns NULL
 */
export const getManagedProductListsCount = (): AppThunk => async (dispatch: AppDispatch, getState) => {
  dispatch(productListManagementSlice.actions.setManagedListsCountsLoading(true));
  const headerIds = selectAllManagedProductListHeaderIds(getState());
  await dispatch(getProductListsDetailsCount(headerIds));
  dispatch(productListManagementSlice.actions.setManagedListsCountsLoading(false));
};

/**
 * Calls the getProductListsDetailsCount with custom product list header ids
 *
 * @returns NULL
 */
export const getCustomProductListsCount = (): AppThunk => async (dispatch: AppDispatch, getState) => {
  dispatch(productListManagementSlice.actions.setCustomListsCountsLoading(true));
  const headerIds = selectAllCustomProductListHeaderIds(getState());
  await dispatch(getProductListsDetailsCount(headerIds));
  dispatch(productListManagementSlice.actions.setCustomListsCountsLoading(false));
};

/**
 * Calls and stores the results of the GetProductListHeaders API call
 *
 * @param includeAllProductsList - Boolean indicating if the call to GetProductListHeaders is for order entry
 * @param onSuccess - Method to call on success
 * @returns NULL
 */
export const getAllProductListHeaders =
  (includeAllProductsList: boolean, onSuccess?: () => void): AppThunk<Promise<void>> =>
  async (dispatch: AppDispatch, getState): Promise<void> => {
    try {
      if (!getState().productListManagement.listsLoading)
        dispatch(productListManagementSlice.actions.setListsLoading(true));

      const customerId = getState().customer.selectedCustomer?.CustomerId;

      if (!customerId) return;

      const { data } = await productListHeaderService.getProductListHeaders({
        excludeReadOnlyLists: false,
        isForOrderEntry: includeAllProductsList,
        customerId: customerId,
      });

      if (data.IsSuccess) {
        dispatch(productListManagementSlice.actions.setProductListHeaders(data.ResultObject));
        onSuccess?.();
      }
      // [TODO]: Handle state if IsSuccess === false
    } catch (error: unknown) {
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
      // [TODO]: Handle state if an error occurs here
    } finally {
      dispatch(productListManagementSlice.actions.setListsLoading(false));
    }
  };

/**
 * Calls and stores the results of the GetProductListHeaders API call
 *
 * @param excludeReadOnlyLists - Boolean indicating if readonly lists should be incuded in the result of the API call
 * @param isForOrderEntry - Boolean indicating if the call to GetProductListHeaders is for order entry
 * @param callback - Method to call once this method had completed
 * @returns NULL
 */
export const getProductListHeaders =
  (
    excludeReadOnlyLists: boolean,
    isForOrderEntry: boolean,
    callback?: (productListHeaders: ProductListHeaderCustom[]) => void | Promise<void>
  ): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    try {
      const customerId = getState().customer.selectedCustomer?.CustomerId;
      if (!customerId) return;

      const { data } = await productListHeaderService.getProductListHeaders({
        excludeReadOnlyLists: excludeReadOnlyLists,
        isForOrderEntry: isForOrderEntry,
        customerId: customerId,
      });

      callback?.(data.ResultObject);
      // [TODO]: Handle state if IsSuccess === false
    } catch (error: unknown) {
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
      // [TODO]: Handle state if an error occurs here
    }
  };

/**
 * Calls and stores the results of the GetProductListHeadersForAdditionByProductKey API call
 *
 * @param productKey - The product key of the product who's list header is being looked for
 * @param callback - Method to call once this method had completed
 * @returns NULL
 */
export const getProductListHeadersForAdditionByProductKey =
  (
    productKey: string,
    thirdPartyVendorProductId?: string,
    callback?: (productListHeaders: ProductListHeaderForAddition[]) => void | Promise<void>
  ): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    try {
      const customer = getState().customer.selectedCustomer;
      if (!customer) return;

      const { data } = await productListHeaderService.getProductListHeadersForAdditionByProductKey({
        ProductKey: productKey,
        BusinessUnitERPKey: customer.BusinessUnitERPKey,
        CustomerId: customer.CustomerId,
        ThirdPartyVendorProductId: thirdPartyVendorProductId,
      });

      if (data.IsSuccess) {
        callback?.(data.ResultObject);
      } else {
        dispatch(globalSlice.actions.setErrorDialogContent({ messages: data.ErrorMessages }));
      }
    } catch (error: unknown) {
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
    }
  };

/**
 * Calls and stores the results of the GetProductListDetailsCount API call
 *
 * @param listHeaderId - The list header id that is being counted
 * @returns NULL
 */
export const getProductListDetailsCount =
  (listHeaderId: string): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    try {
      const customerId = getState().customer.selectedCustomer?.CustomerId;

      if (!customerId) return;

      const { data } = await productListDetailService.getProductListDetailsCount({
        customerId: customerId,
        productListHeaderId: listHeaderId,
      });

      if (data.IsSuccess) {
        dispatch(
          productListManagementSlice.actions.updateProductListDetailCount({
            ProductListHeaderId: listHeaderId,
            ProductListDetailCount: data.ResultObject,
          })
        );
      }
    } catch (error: unknown) {
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
      // [TODO]: Handle state if an error occurs here
    }
  };

/**
 * Calls and stores the results of the GetProductListDetailsCount API call
 *
 * @param productListHeaderIds - The list header id that is being counted
 * @returns NULL
 */
export const getProductListsDetailsCount =
  (productListHeaderIds?: string[]): AppThunk<Promise<void>> =>
  async (dispatch: AppDispatch, getState): Promise<void> => {
    try {
      const customerId = getState().customer.selectedCustomer?.CustomerId;

      if (!customerId) return;

      const { data } = await productListDetailService.getProductListsDetailsCount({
        customerId,
        productListHeaderIds: productListHeaderIds ?? [],
      });

      if (data.IsSuccess) {
        data.ResultObject.forEach((countResult: GetProductListsDetailCountResultData) =>
          dispatch(
            productListManagementSlice.actions.updateProductListDetailCount({
              ProductListHeaderId: countResult.ProductListHeaderId,
              ProductListDetailCount: countResult.Count,
            })
          )
        );
      }
    } catch (error: unknown) {
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
    }
  };

/**
 * Calls the GetShareProductListHeaderCustomers API call
 *
 * @param listHeaderId - The list header id that is having shareable customers retrieved
 * @param callback - Method to call once this method has completed
 * @param errorCallback - Method to call on error
 * @returns NULL
 */
export const getShareProductListHeaderCustomers =
  (
    listHeaderId: string,
    callback?: (customers: GetShareProductListHeaderCustomersResponse[]) => void | Promise<void>,
    errorCallback?: (errors: string[]) => void | Promise<void>
  ): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    try {
      const customerId = getState().customer.selectedCustomer?.CustomerId;

      if (!customerId) return;

      const { data } = await productListHeaderService.getShareProductListHeaderCustomers({
        ProductListHeaderId: listHeaderId,
      });

      if (data.IsSuccess) {
        callback?.(data.ResultObject);
      } else {
        errorCallback?.(data.ErrorMessages);
        return undefined;
      }
    } catch (error: unknown) {
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
      // [TODO]: Handle state if an error occurs here
    }
  };

/**
 * Calls the UpdateShareProductListHeader API call
 *
 * @param request - Information needed to make the API call
 * @param successCallback - Method to call once this method has completed
 * @param errorCallback - Method to call on error
 * @returns NULL
 */
export const updateShareProductListHeader =
  (
    request: UpdateShareProductListHeader[],
    successCallback?: (isSuccess: boolean) => void | Promise<void>,
    errorCallback?: (errors: string[]) => void | Promise<void>
  ): AppThunk<Promise<void>> =>
  async (dispatch: AppDispatch, getState): Promise<void> => {
    try {
      const customerId = getState().customer.selectedCustomer?.CustomerId;
      if (!customerId) return;

      const requestData = {
        RequestObject: request,
      };
      const { data } = await productListHeaderService.updateShareProductListHeader(requestData);
      if (data.IsSuccess) {
        successCallback?.(data.IsSuccess);
      } else {
        dispatch(setErrorDialogContent('Error occured', data.ErrorMessages));
        errorCallback?.(data.ErrorMessages);
      }
    } catch (error: unknown) {
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
      // [TODO]: Handle state if an error occurs here
    }
  };

/**
 * Calls the UpdateProductListCustomer API call
 *
 * @param customerId - The id of the custoner being updated
 * @param productListHeaderId - The product header list id needed to make the API call
 * @param disableNotifications - Boolean indicating if the notificiation should be disabled
 * @param isReadOnly - Boolean value the request will use for its isReadOnly attribute
 * @returns NULL
 */
export const updateProductListCustomer =
  (customerId: string, productListHeaderId: string, disableNotifications: boolean, isReadOnly: boolean): AppThunk =>
  async () => {
    try {
      const request: UpdateProductListCustomerRequest = {
        CustomerId: customerId,
        ProductListHeaderId: productListHeaderId,
        DisableNotifications: disableNotifications,
        IsReadOnly: isReadOnly,
      };

      await productListHeaderService.updateProductListCustomer(request);
    } catch (error: unknown) {
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
      // [TODO]: Handle state if an error occurs here
    }
  };

/**
 * Calls the CreateProductListHeader API call
 *
 * @param title - Title of the list header being created
 * @param isPrivate - Boolean indicating if the list header being created is private
 * @param resolution - The resolution used to log activities
 * @param successCallback - Method to call on success
 * @param errorCallback - Method to call on error
 * @returns NULL
 */
export const createProductListHeader =
  (
    title: string,
    isPrivate: boolean,
    resolution: Resolution,
    successCallback?: (productListHeader?: ProductListHeader) => void | Promise<void>,
    errorCallback?: (errors: string[]) => void | Promise<void>
  ): AppThunk =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      dispatch(productListSlice.actions.setShowNewListDialogLoading(true));
      const validationMessages = validateStringEmptyOrMaxLength('Product list title', title, 50);
      if (validationMessages.length > 0) {
        errorCallback?.(validationMessages);
        return;
      }

      const customerId = getState().customer.selectedCustomer?.CustomerId;
      if (!customerId) return;

      dispatch(
        logUserActivity({
          action: UserActivityAction.CreateProductList,
          pageName: UserActivityPageName.ListManagement,
          actionSummary: UserActivityActionSummary.CreateProductList,
          resolution: resolution,
        })
      );

      const request: CreateProductListHeaderRequest = {
        ProductListTitle: title,
        IsPrivate: isPrivate,
        CustomerId: customerId,
      };

      const { data } = await productListHeaderService.createProductListHeader(request);
      if (data.IsSuccess) successCallback?.(data.ResultObject);
      else {
        errorCallback?.(data.ErrorMessages);
        dispatch(setErrorDialogContent('Error occured', data.ErrorMessages));
      }
    } catch (error: unknown) {
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
    } finally {
      dispatch(productListSlice.actions.setShowNewListDialogLoading(false));
    }
  };

/**
 * Calls the CopyProductList API call
 *
 * @param multipleLocationsSelected - Boolean indicating if multiple locations are selected
 * @param productListHeaderId - The id of the product list header being copied
 * @param customerIds - The customer id
 * @param title - The title of the new list
 * @param isPrivate - Boolean indicating if the list is private
 * @param resolution - The resolution used to log activities
 * @param successCallback - Method to call on success
 * @param errorCallback - Method to call on error
 * @returns NULL
 */
export const copyProductListHeader =
  (
    multipleLocationsSelected: boolean,
    productListHeaderId: string,
    customerIds: string[],
    title: string,
    isPrivate: boolean,
    resolution: Resolution,
    successCallback?: (productListHeader?: ProductListHeaderCustom) => void | Promise<void>,
    errorCallback?: (errors: string[]) => void | Promise<void>
  ): AppThunk =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      const validationMessages = validateStringEmptyOrMaxLength('Product list title', title, 50);
      if (validationMessages.length > 0) {
        errorCallback?.(validationMessages);
        return;
      }

      const customerId = getState().customer.selectedCustomer?.CustomerId;
      if (!customerId) return;

      dispatch(
        logUserActivity<{ ProductListHeaderId: string }>({
          action: UserActivityAction.CopyProductList,
          pageName: UserActivityPageName.CopyProductList,
          actionSummary: UserActivityActionSummary.CopyProductList,
          resolution: resolution,
          additionalData: { ProductListHeaderId: productListHeaderId },
        })
      );

      const request: CopyProductListRequest = {
        ProductListHeaderId: productListHeaderId,
        CustomerIds: customerIds,
        ProductListTitle: title,
        IsPrivate: isPrivate,
      };

      const { data } = await productListHeaderService.copyProductList(request);
      if (data.IsSuccess) {
        if (multipleLocationsSelected) {
          dispatch(
            addAppNotification(
              NotificationKeys.GrowlerSuccess,
              '',
              `List was successfully copied to ${customerIds.length} location${customerIds.length > 1 ? 's' : ''}`
            )
          );

          // If a List was copied to the same Customer Location, it should be pushed to slice
          const sameCustomerLocationIndex = customerIds.indexOf(customerId);
          if (sameCustomerLocationIndex >= 0) {
            if (data.ResultObject[sameCustomerLocationIndex]) {
              dispatch(
                productListManagementSlice.actions.addProductListHeader(data.ResultObject[sameCustomerLocationIndex])
              );
            }
          }

          successCallback?.();
        }

        successCallback?.(data.ResultObject[0]);
      } else {
        dispatch(setErrorDialogContent('Error occured', data.ErrorMessages));
        errorCallback?.(data.ErrorMessages);
      }
    } catch (error: unknown) {
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
    }
  };

/**
 * Calls the GetCustomersUserCanManageProductLists API call
 *
 * @param callback - Method to call once this method finishes
 * @returns NULL
 */
export const getCustomersUserCanManageProductLists =
  (callback: (customers: Customer[]) => void | Promise<void>): AppThunk =>
  async () => {
    try {
      const { data } = await productListHeaderService.getCustomersUserCanManageProductLists();
      callback(data.ResultObject);
    } catch (error: unknown) {
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
    }
  };

/**
 * Calls the DeleteProductListHeader API call
 *
 * @param DeleteProductListHeader - Id of the product list header being deleted
 * @param resolution - The resolution used to log activities
 * @returns NULL
 */
export const deleteProductListHeader =
  (productListHeaderId: string, resolution: Resolution): AppThunk<Promise<APIMessages>> =>
  async (dispatch: AppDispatch): Promise<APIMessages> => {
    try {
      dispatch(
        logUserActivity<{ ProductListHeaderId: string }>({
          action: UserActivityAction.DeleteProductList,
          pageName: UserActivityPageName.ProductListDelete,
          actionSummary: UserActivityActionSummary.ProductListDelete,
          resolution: resolution,
          additionalData: { ProductListHeaderId: productListHeaderId },
        })
      );

      const { data } = await productListHeaderService.deleteProductListHeader({
        ProductListHeaderId: productListHeaderId,
      });

      if (data.IsSuccess) {
        dispatch(productListManagementSlice.actions.deleteProductListHeader(productListHeaderId));
        return {
          IsError: false,
        };
      }

      if (data.ErrorMessages?.length > 0)
        dispatch(setErrorDialogContent('Error Deleting Product List', data.ErrorMessages));
      return {
        IsError: true,
        ErrorMessages: data.ErrorMessages,
      };
    } catch (error: unknown) {
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
    }
    return {
      IsError: false,
    };
  };

/**
 * Calls the replaceProductListDetail Method
 *
 * @param fromProduct - The product being replaced
 * @param toProduct - The product replacing the old product
 * @param selectedProductListHeaderIds - The id of the selected product list header
 * @param successCallback - Method called on success
 * @param errorCallback - Method called on error
 * @returns NULL
 */
export const replaceProductListDetailFromStore =
  (
    fromProduct: ProductData,
    toProduct: ProductData,
    selectedProductListHeaderIds: string[],
    successCallback?: () => void | Promise<void>,
    errorCallback?: (errors: string[]) => void | Promise<void>
  ): AppThunk =>
  async (dispatch: AppDispatch) => {
    if (!fromProduct || !fromProduct.ProductKey || !toProduct || !toProduct.ProductKey) {
      return;
    }

    dispatch(
      replaceProductListDetail(
        fromProduct.ProductKey,
        fromProduct.BusinessUnitERPKey,
        selectedProductListHeaderIds,
        toProduct.ProductKey,
        toProduct.BusinessUnitERPKey,
        toProduct.UnitOfMeasureOrderQuantities.map((uom) => uom.UnitOfMeasure),
        successCallback,
        errorCallback
      )
    );
  };

/**
 * Calls the ReplaceProductListDetail API call
 *
 * @param fromProductKey - The key of the product being replaced
 * @param fromProductBusinessUnitERPKey - The BU ERP key of the product being replaced
 * @param productListHeaderIds - Array of product list header ids that the product is being replaced on
 * @param toProductKey - The key of the product replacing the old product
 * @param toProductBusinessUnitERPKey - The BU ERP key of the product replacing the old product
 * @param toProductUnitOfMeasures - The Units of measure of the product replacing the old product
 * @param successCallback - Method to call on success
 * @param errorCallback - Method to call on error
 * @returns NULL
 */
export const replaceProductListDetail =
  (
    fromProductKey: string,
    fromProductBusinessUnitERPKey: BusinessUnitERPKeyType,
    productListHeaderIds: string[],
    toProductKey: string,
    toProductBusinessUnitERPKey: BusinessUnitERPKeyType,
    toProductUnitOfMeasures: UnitOfMeasureType[],
    successCallback?: () => void | Promise<void>,
    errorCallback?: (errors: string[]) => void | Promise<void>
  ): AppThunk<Promise<void>> =>
  async (dispatch: AppDispatch, getState: () => RootState): Promise<void> => {
    try {
      const customerId = getState().customer.selectedCustomer?.CustomerId;
      if (!customerId) return;

      const request: ReplaceProductListDetailRequest = {
        FromProductKey: fromProductKey,
        FromProductBusinessUnitERPKey: fromProductBusinessUnitERPKey,
        ProductListHeaderIds: productListHeaderIds,
        ToProductKey: toProductKey,
        ToProductBusinessUnitERPKey: toProductBusinessUnitERPKey,
        ToProductUnitOfMeasures: toProductUnitOfMeasures,
      };

      const { data } = await productListDetailService.replaceProductListDetail(request);
      if (data.IsSuccess) {
        dispatch(refreshProductListSearchResults());
        dispatch(addAppNotification(NotificationKeys.Toast, '', 'Item replaced on list(s)'));
        successCallback?.();
      } else {
        dispatch(setErrorDialogContent('Error occurred', data.ErrorMessages));
        errorCallback?.(data.ErrorMessages);
      }
    } catch (error: unknown) {
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
    }
  };

/**
 * Calls the ReplaceProductListDetailForCustomers API call
 *
 * @param customerIds - Array of customer ids reflecting the locations across which all custom lists will have the product replaced
 * @param fromProductKey - The key of the product being replaced
 * @param fromProductBusinessUnitERPKey - The BU ERP key of the product being replaced
 * @param toProductKey - The key of the product replacing the old product
 * @param toProductBusinessUnitERPKey - The BU ERP key of the product replacing the old product
 * @param successCallback - Method to call on success
 * @param errorCallback - Method to call on error
 * @returns NULL
 */

export const replaceProductListDetailForMultiUnitAccount =
  (
    customerIds: string[],
    customerGroupHeaderIds: string[],
    fromProductKey: string,
    fromProductBusinessUnitERPKey: BusinessUnitERPKeyType,
    toProductKey: string,
    toProductBusinessUnitERPKey: BusinessUnitERPKeyType,
    toProductUnitOfMeasures: UnitOfMeasureType[],
    successCallback?: () => void | Promise<void>,
    errorCallback?: (errors: string[]) => void | Promise<void>
  ): AppThunk<Promise<void>> =>
  async (dispatch: AppDispatch, getState: () => RootState): Promise<void> => {
    try {
      const customerId = getState().customer.selectedCustomer?.CustomerId;
      if (!customerId) return;
      dispatch(productListManagementSlice.actions.setMultiUnitFindAndReplaceLoading(true));

      const request: ReplaceProductListDetailForMultiUnitAccountRequest = {
        CustomerIds: customerIds,
        CustomerGroupHeaderIds: customerGroupHeaderIds,
        FromProductKey: fromProductKey,
        FromProductBusinessUnitERPKey: fromProductBusinessUnitERPKey,
        ToProductKey: toProductKey,
        ToProductBusinessUnitERPKey: toProductBusinessUnitERPKey,
        ToProductUnitOfMeasures: toProductUnitOfMeasures,
      };

      const { data } = await productListDetailService.replaceProductListDetailForMultiUnitAccount(request);

      if (data.IsSuccess) {
        dispatch(refreshProductListSearchResults());
        if (customerIds.length === 1 && customerIds[0] === customerId) {
          dispatch(addAppNotification(NotificationKeys.Toast, '', 'Item successfully replaced'));
        } else {
          dispatch(addAppNotification(NotificationKeys.Toast, '', 'Item successfully replaced and notification sent'));
        }
        successCallback?.();
        if (data.InformationMessages?.length > 0) {
          dispatch(setInformationDialogContent('Notice', data.InformationMessages));
        }
      } else {
        dispatch(setErrorDialogContent('Error occurred', data.ErrorMessages));
        errorCallback?.(data.ErrorMessages);
      }
    } catch (error: unknown) {
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
    } finally {
      dispatch(productListManagementSlice.actions.setMultiUnitFindAndReplaceLoading(false));
    }
  };

/**
 * Calls the GetProductListHeadersByProductKey API call
 *
 * @param productKey - The key of the product
 * @param successCallback - Method to call on success
 * @param errorCallback - Method to call on error
 * @returns NULL
 */
export const getProductListHeadersByProductKey =
  (
    productData: ProductData,
    successCallback?: (productListHeaders: ProductListHeader[]) => void | Promise<void>,
    errorCallback?: (errors: string[]) => void | Promise<void>
  ): AppThunk =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      const customer = getState().customer.selectedCustomer;
      if (!customer) return;

      const request: GetProductListHeadersByProductKeyRequest = {
        ProductKey: productData.ProductKey,
        ThirdPartyVendorProductId: productData.ThirdPartyVendorProductId,
        BusinessUnitERPKey: customer.BusinessUnitERPKey,
        CustomerId: customer.CustomerId,
        ExcludeReadOnlyLists: true,
      };

      const { data } = await productListHeaderService.getProductListHeadersByProductKey(request);
      if (data.IsSuccess) {
        successCallback?.(data.ResultObject);
      } else {
        dispatch(setErrorDialogContent('Error occurred', data.ErrorMessages));
        errorCallback?.(data.ErrorMessages);
      }
    } catch (error: unknown) {
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
    }
  };

/**
 * Calls the GetProductListHeadersByProductKey API call
 *
 * @param fromProduct - The product being replaced
 * @param toProduct - The product that is replacing the old product
 * @param successCallback - Method to call on success
 * @param errorCallback - Method to call on error
 * @returns NULL
 */
export const getProductListHeadersToReplaceDiscontinuedProduct =
  (
    fromProduct: ProductData,
    toProduct: ProductData,
    successCallback?: (productListHeaders: ProductListHeaderWithProductExistence[]) => void | Promise<void>,
    errorCallback?: (errors: string[]) => void | Promise<void>
  ): AppThunk =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    const customer = getState().customer.selectedCustomer;
    if (!customer) return;

    if (fromProduct && fromProduct.ProductKey && toProduct && toProduct.ProductKey) {
      const fromRequest: GetProductListHeadersByProductKeyRequest = {
        ProductKey: fromProduct.ProductKey,
        BusinessUnitERPKey: customer.BusinessUnitERPKey,
        CustomerId: customer.CustomerId,
        ExcludeReadOnlyLists: true,
      };

      const { data: existingLists } = await productListHeaderService.getProductListHeadersByProductKey(fromRequest);
      if (existingLists.IsSuccess) {
        if (existingLists.ResultObject.length < 1) {
          successCallback?.([]);
          return;
        }

        const toRequest = {
          ...fromRequest,
          ProductKey: toProduct.ProductKey,
        };

        const { data: replaceLists } = await productListHeaderService.getProductListHeadersByProductKey(toRequest);
        if (replaceLists.IsSuccess) {
          const replaceListExists = replaceLists.ResultObject.length > 0;
          const returnList: ProductListHeaderWithProductExistence[] = existingLists.ResultObject.map((header) => {
            return {
              ...header,
              IsOnProductList:
                replaceListExists &&
                replaceLists.ResultObject.some((list) => list.ProductListHeaderId === header.ProductListHeaderId),
            } as ProductListHeaderWithProductExistence;
          });
          successCallback?.(returnList);
        }
      } else {
        dispatch(setErrorDialogContent('Error occurred', existingLists.ErrorMessages));
        successCallback?.(
          existingLists.ResultObject.map(
            (header) =>
              ({
                ...header,
                IsOnProductList: false,
              } as ProductListHeaderWithProductExistence)
          )
        );
        errorCallback?.(existingLists.ErrorMessages);
      }
    }
  };

/**
 * Calls the UpdateProductListNotification API call
 *
 * @param productListHeaderId - The product list header id that is having it's notification updated
 * @param notificationsDisabled - Boolean value indicating if the notification should be disabled
 * @returns NULL
 */
export const toggleProductListNotificationDisabled =
  (productListHeaderId: string, notificationsDisabled: boolean): AppThunk =>
  async (dispatch: AppDispatch, getState): Promise<number | undefined> => {
    try {
      const customerId = getState().customer.selectedCustomer?.CustomerId;
      if (!customerId) return;

      const request: UpdateProductListNotificationRequest = {
        ProductListHeaderId: productListHeaderId,
        CustomerId: customerId,
        DisableNotifications: notificationsDisabled,
      };

      const { data } = await productListNotificationService.updateProductListNotification(request);

      if (data.IsSuccess) {
        dispatch(
          productListManagementSlice.actions.updateProductListNotificationsDisabled({
            productListHeaderId,
            notificationsDisabled,
          })
        );
      } else {
        dispatch(setErrorDialogContent('Error occurred', data.ErrorMessages));
      }
    } catch (error: unknown) {
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
    }
  };
