import { BusinessUnitERPKeyType } from '../../api/models/api-shared.enums';
import { AppRoutes, ProductListCatalogSearchMode, RouteName } from '../../models';
import { normalizeKey } from './string';
import { getQueryParamsString, replaceParams } from './url';

// Product List
export const getProductListURL = (
  customerId: string | undefined,
  productListHeaderId: string | undefined,
  sortByType: string | undefined,
  filterText: string | undefined
): string => {
  const params = [];
  if (filterText) params.push(['q', filterText]);
  if (sortByType) params.push(['sort', sortByType]);
  return `${AppRoutes[RouteName.ProductList].Path}/${productListHeaderId}/${customerId}${getQueryParamsString(params)}`;
};

export const getAddFromListURL = (
  customerId: string | undefined,
  toProductListHeaderId: string | undefined,
  fromProductListHeaderId: string | undefined,
  sortByType: string | undefined,
  q: string | undefined
): string => {
  const params = [];
  if (sortByType) params.push(['sort', sortByType]);
  if (q) params.push(['q', q]);
  return `${
    AppRoutes[RouteName.AddFromList].Path
  }/${toProductListHeaderId}/${customerId}/${fromProductListHeaderId}${getQueryParamsString(params)}`;
};

export const getAddFromVendorURL = (
  customerId: string | undefined,
  productListHeaderId: string | undefined,
  vendorId: string | undefined,
  sortByType: string | undefined,
  q: string | undefined
): string => {
  const params = [];
  if (sortByType) params.push(['sort', sortByType]);
  if (q) params.push(['q', q]);
  return `${
    AppRoutes[RouteName.AddFromVendor].Path
  }/${productListHeaderId}/${customerId}/${vendorId}${getQueryParamsString(params)}`;
};

export const getProductListCatalogSearchURL = (
  customerId: string | undefined,
  productListHeaderId: string | undefined,
  queryText: string,
  searchMode?: ProductListCatalogSearchMode
): string =>
  `${AppRoutes[RouteName.ProductListCatalogSearch].Path}/${productListHeaderId}/${customerId}?q=${encodeURIComponent(
    queryText
  )}${searchMode ? '&searchMode=' + searchMode : ''}`;

export const getProductListDiaryURL = (
  customerId: string | undefined,
  productListHeaderId: string | undefined,
  filterText: string | undefined
): string => {
  const params = [];
  if (filterText) params.push(['q', filterText]);
  return `${replaceParams(RouteName.ProductListDiary, [
    productListHeaderId || '',
    customerId || '',
  ])}${getQueryParamsString(params)}`;
};

export const getSimilarProductSearcURL = (): string => `${AppRoutes[RouteName.SimilarProductSearch].Path}`;

// Finance
export const getAccountReceivableStatementsURL = (filterText: string | undefined): string => {
  return `${AppRoutes[RouteName.AccountsReceivables].Path}${filterText ? getQueryParamsString({ q: filterText }) : ''}`;
};

export const getInvoicesURL = (filterText: string | undefined): string => {
  return `${AppRoutes[RouteName.Invoices].Path}${filterText ? getQueryParamsString({ q: filterText }) : ''}`;
};

export const getInvoiceDetailsURL = (
  invoiceHeaderKey: string | undefined,
  businessUnitERPKey: BusinessUnitERPKeyType,
  operationCompanyNumber: string | undefined,
  fitlerText: string | undefined
): string => {
  const params = [];
  if (fitlerText) params.push(['q', fitlerText]);
  return `${AppRoutes[RouteName.InvoiceDetail].Path}/${normalizeKey(
    invoiceHeaderKey
  )}/${businessUnitERPKey}/${operationCompanyNumber}${getQueryParamsString(params)}`;
};

export const getInsightsReportURL = (searchHeaderId: string, sort?: number): string => {
  const params = [];
  if (sort !== undefined) params.push(['sort', sort.toString()]);
  return `${AppRoutes[RouteName.InsightsReport].Path}/${searchHeaderId}${getQueryParamsString(params)}`;
};

export const getOrderHistoryURL = (filterText: string | undefined): string => {
  return `${AppRoutes[RouteName.OrderHistory].Path}${filterText ? getQueryParamsString({ q: filterText }) : ''}`;
};

export const getRepeatOrderURL = (filterText: string | undefined): string => {
  return `${AppRoutes[RouteName.RepeatOrder].Path}${filterText ? getQueryParamsString({ q: filterText }) : ''}`;
};

//User Management
export const getManageUsersURL = (filterText: string | undefined): string => {
  return `${AppRoutes[RouteName.ManageUsers].Path}${filterText ? getQueryParamsString({ q: filterText }) : ''}`;
};

export const getRemoveMultipleUsersURL = (filterText: string | undefined): string => {
  return `${AppRoutes[RouteName.RemoveMultipleUsers].Path}${filterText ? getQueryParamsString({ q: filterText }) : ''}`;
};
