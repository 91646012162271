export interface Resolution {
  width: number;
  height: number;
}

export interface BrowserInfo {
  name: string;
  version: string;
}

export interface DialogContent {
  title?: string;
  messages: string[];
}

export enum CfDateCardOption {
  UpcomingAndRecent = 'uar',
  Last7Days = '7d',
  Last30Days = '30d',
  Last90Days = '90d',
  Last12Months = '12m',
  YearToDate = 'ytd',
  Custom = 'custom',
}
