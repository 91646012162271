import {
  CreateInventoryEntryHeaderRequest,
  GetInventoryEntryHeaderRequest,
  GetInventoryEntryHeadersRequest,
  InventoryEntryHeader,
  InventorySnapshotHeader,
  ResetInventoryEntryHeaderRequest,
  ServiceResult,
  SubmitInventoryEntryHeaderRequest,
} from '../models';
import ApiService from './api-service';

class InventoryEntryHeaderService extends ApiService {
  private static classInstance: InventoryEntryHeaderService;

  private constructor() {
    super('InventoryEntryHeader');
  }

  public getInventoryEntryHeader(request: GetInventoryEntryHeaderRequest): ServiceResult<InventoryEntryHeader> {
    return this.get(`${this.persistUrl}/GetInventoryEntryHeader`, request);
  }

  public getInventoryEntryHeaders(request: GetInventoryEntryHeadersRequest): ServiceResult<InventoryEntryHeader[]> {
    return this.get(`${this.persistUrl}/GetInventoryEntryHeaders`, request);
  }

  public createInventoryEntryHeader(request: CreateInventoryEntryHeaderRequest): ServiceResult<InventoryEntryHeader> {
    return this.post(`${this.persistUrl}/CreateInventoryEntryHeader`, request);
  }

  public resetInventoryEntryHeader(request: ResetInventoryEntryHeaderRequest): ServiceResult<InventoryEntryHeader> {
    return this.post(`${this.persistUrl}/ResetInventoryEntryHeader`, request);
  }

  public submitInventoryEntryHeader(
    request: SubmitInventoryEntryHeaderRequest
  ): ServiceResult<InventorySnapshotHeader> {
    return this.post(`${this.persistUrl}/SubmitInventoryEntryHeader`, request);
  }

  public static getInstance(): InventoryEntryHeaderService {
    if (!this.classInstance) {
      this.classInstance = new InventoryEntryHeaderService();
    }
    return this.classInstance;
  }
}

export default InventoryEntryHeaderService;
