import {
  InventoryCustomerProduct,
  ResetInventoryCustomerProductUnitOfMeasureRequest,
  ResetInventoryCustomerProductUnitOfMeasuresRequest,
  ServiceResult,
  UpdateInventoryCustomerProductUnitOfMeasureRequest,
} from '../models';
import ApiService from './api-service';

class InventoryCustomerProductService extends ApiService {
  private static classInstance: InventoryCustomerProductService;

  private constructor() {
    super('InventoryCustomerProduct');
  }

  public resetInventoryCustomerProductUnitOfMeasure(
    request: ResetInventoryCustomerProductUnitOfMeasureRequest
  ): ServiceResult<any> {
    return this.post(`${this.baseUrl}/ResetInventoryCustomerProductUnitOfMeasure`, request);
  }

  public resetInventoryCustomerProductUnitOfMeasures(
    request: ResetInventoryCustomerProductUnitOfMeasuresRequest
  ): ServiceResult<any> {
    return this.post(`${this.baseUrl}/ResetInventoryCustomerProductUnitOfMeasures`, request);
  }

  public updateInventoryCustomerProductUnitOfMeasure(
    request: UpdateInventoryCustomerProductUnitOfMeasureRequest
  ): ServiceResult<InventoryCustomerProduct> {
    return this.post(`${this.baseUrl}/UpdateInventoryCustomerProductUnitOfMeasure`, request);
  }

  public static getInstance(): InventoryCustomerProductService {
    if (!this.classInstance) {
      this.classInstance = new InventoryCustomerProductService();
    }
    return this.classInstance;
  }
}
export default InventoryCustomerProductService;
