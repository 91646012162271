import { EntityState, PayloadAction, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { MarketingZone, MarketingZoneEnum, MarketingZoneIncrementer } from '../../api/models/marketing-zones.models';
import { RootState } from '../store';

const marketingZonesAdapter = createEntityAdapter<MarketingZone>({
  selectId: (zone) => zone.MarketingZoneEnum,
});

interface MarketingZonesState {
  marketingZones: EntityState<MarketingZone>;
  loadingMarketingCampaigns: boolean;
  customerId: string | undefined;
}

const initialState: MarketingZonesState = {
  marketingZones: marketingZonesAdapter.getInitialState(),
  loadingMarketingCampaigns: true,
  customerId: undefined,
};

export const marketingZonesSlice = createSlice({
  name: 'marketingZones',
  initialState: initialState,
  reducers: {
    resetState: () => {
      return initialState;
    },
    setLoadingMarketingCampaigns: (state: MarketingZonesState, action: PayloadAction<boolean>) => {
      state.loadingMarketingCampaigns = action.payload;
    },
    setCustomerId: (state, action) => {
      state.customerId = action.payload;
    },
    setMarketingZones: (state, action) => {
      marketingZonesAdapter.setAll(
        state.marketingZones,
        action.payload.map((zone: MarketingZone) => {
          const priorityImages = zone.Images.filter((image) => image.IsPriority === true);
          const priorityImagesIndex = 0;
          const standardImages = zone.Images.filter((image) => image.IsPriority !== true);
          const standardImagesIndex = 0;
          return {
            MarketingZoneEnum: zone.MarketingZoneEnum,
            Images: standardImages,
            PriorityImages: priorityImages,
            AllImages: priorityImages.concat(standardImages),
            ActiveIndex: standardImagesIndex,
            PriorityIndex: priorityImagesIndex,
          };
        })
      );
    },
    incrementImageIndex: (state, action: PayloadAction<MarketingZoneIncrementer>) => {
      const zone = state.marketingZones.entities[action.payload.id];
      const incrementNum = action.payload.incrementBy;
      if (zone) {
        const currentActiveIndex = zone.ActiveIndex;

        // Increment up the length of the array
        const nextActiveIndex = (currentActiveIndex + incrementNum) % zone.Images.length;

        marketingZonesAdapter.updateOne(state.marketingZones, {
          id: action.payload.id,
          changes: { ActiveIndex: nextActiveIndex },
        });
      }
    },
    incrementPriorityImageIndex: (state, action) => {
      const zone = state.marketingZones.entities[action.payload];
      if (zone) {
        const currentPriorityIndex = zone.PriorityIndex;

        // Increment up the length of the array
        const nextPriorityIndex = (currentPriorityIndex + 1) % zone.PriorityImages.length;

        marketingZonesAdapter.updateOne(state.marketingZones, {
          id: action.payload,
          changes: { PriorityIndex: nextPriorityIndex },
        });
      }
    },
    incrementAllImageIndex: (state, action: PayloadAction<MarketingZoneIncrementer>) => {
      const zone = state.marketingZones.entities[action.payload.id];
      const incrementNum = action.payload.incrementBy;
      if (zone) {
        const currentActiveIndex = zone.ActiveIndex;

        // Increment up the length of the array
        const nextActiveIndex = (currentActiveIndex + incrementNum) % zone.AllImages.length;

        marketingZonesAdapter.updateOne(state.marketingZones, {
          id: action.payload.id,
          changes: { ActiveIndex: nextActiveIndex },
        });
      }
    },
  },
});

// Adapter Selectors
export const {
  selectAll: selectAllMarketingZones,
  selectById: selectMarketingZoneId,
  selectIds: selectAllMarketingZoneIds,
} = marketingZonesAdapter.getSelectors<EntityState<MarketingZone>>(
  (marketingZones: EntityState<MarketingZone>) => marketingZones
);

// Custom selectors
export const selectMarketingZones = (state: RootState) => state.marketingZones;

export const selectMarketingZone = (marketingZoneEnum: number) => (state: RootState) => {
  const marketingZone = selectMarketingZoneId(state.marketingZones.marketingZones, marketingZoneEnum);
  const activeIndex = marketingZone ? marketingZone.ActiveIndex : 0;
  const priorityIndex = marketingZone ? marketingZone.PriorityIndex : 0;
  const allImages = marketingZone ? marketingZone.AllImages : [];
  const matchingZoneImages = marketingZone ? marketingZone.Images : [];
  const matchingPriorityImages = marketingZone ? marketingZone.PriorityImages : [];
  return { matchingZoneImages, matchingPriorityImages, activeIndex, priorityIndex, allImages };
};

export const selectHomeMarketingZone0 = () => (state: RootState) => {
  // marketing zone enum for the home page
  const homeMarketingZoneEnum = MarketingZoneEnum.Mz0;

  const marketingZone = selectMarketingZone(homeMarketingZoneEnum)(state);

  const { matchingZoneImages, matchingPriorityImages, activeIndex, priorityIndex } = marketingZone;

  const homeImages = [];

  if (matchingPriorityImages.length >= 2 && matchingZoneImages.length === 0) {
    // If there are only priority images, include the priority image and the next priority image in the array
    homeImages.push(matchingPriorityImages[priorityIndex]);
    homeImages.push(matchingPriorityImages[(priorityIndex + 1) % matchingPriorityImages.length]);
    return homeImages;
  }

  if (matchingPriorityImages.length > 0) {
    // If there are priority images, include the priority image and the corresponding standard image
    homeImages.push(matchingPriorityImages[priorityIndex]);
    homeImages.push(matchingZoneImages[activeIndex]);
  } else if (matchingZoneImages.length > 0) {
    // If no priority images, include the standard image and the next standard image in the array
    homeImages.push(matchingZoneImages[activeIndex]);
    homeImages.push(matchingZoneImages[(activeIndex + 1) % matchingZoneImages.length]);
  }

  return homeImages;
};

export const selectHomeVerticalMarketingZone1 = () => (state: RootState) => {
  // marketing zone enum for the home page, get 2 images
  const homeMarketingZoneEnum = MarketingZoneEnum.Mz1Veritcal;
  const marketingZone = selectMarketingZone(homeMarketingZoneEnum)(state);

  const { activeIndex, allImages } = marketingZone;
  const homeImages = [];

  if (allImages.length >= 2) {
    if (activeIndex + 1 == allImages.length) {
      homeImages.push(allImages[activeIndex]);
      homeImages.push(allImages[0]);
    } else {
      homeImages.push(allImages[activeIndex]);
      homeImages.push(allImages[activeIndex + 1]);
    }
  } else if (allImages.length == 1) {
    homeImages.push(allImages[activeIndex]);
  }

  return homeImages;
};

export const selectHomeHorizontalMarketingZone1 = () => (state: RootState) => {
  // marketing zone enum for the home page
  const homeMarketingZoneEnum = MarketingZoneEnum.Mz1Horizontal;
  const marketingZone = selectMarketingZone(homeMarketingZoneEnum)(state);

  const { activeIndex, allImages } = marketingZone;
  const homeImages = [];

  if (allImages.length >= 2) {
    if (activeIndex + 1 == allImages.length) {
      homeImages.push(allImages[activeIndex]);
      homeImages.push(allImages[0]);
    } else {
      homeImages.push(allImages[activeIndex]);
      homeImages.push(allImages[activeIndex + 1]);
    }
  } else if (allImages.length == 1) {
    homeImages.push(allImages[activeIndex]);
  }
  return homeImages;
};

export default marketingZonesSlice.reducer;
