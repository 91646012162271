export enum BusinessUnitKeyType {
  PerformanceFoodService = 0,
  Vistar = 1,
  PerformanceFoodGroupCustomized = 2,
  ReinhartFoodService = 3,
  Afflink = 4,
  EbyBrown = 5,
}

export enum BusinessUnitERPKeyType {
  PerformanceFoodService = 0,
  Vistar = 1,
  PerformanceFoodGroupCustomized = 2,
  ReinhartFoodService = 3,
  Afflink = 4,
  EbyBrown = 5,
  PfsPresto = 6,
  VistarGemini = 7,
  PfsGemini = 8,
  PfsSomerset = 9,
  PfsIFH = 10,
}

export enum UnitOfMeasureType {
  Case = 0,
  Each = 1,
  Box = 2,
}

export enum ProductSearchFilterType {
  Base = 0,
  NullableBase = 1,
  Badges = 2,
  CategoryIds = 3,
  StorageTypes = 4,
  DeliveryOptions = 5,
  Nutritional = 6,
  Origin = 7,
  Brands = 8,
  StateOfOriginAbbreviations = 9,
  Program = 10,
}

export enum UserManagementFilterType {
  Status = 0,
  LastActive = 1,
}

export enum OrderType {
  OrderEntry = 0,
  Invoice = 1,
  Open = 2,
}

export enum OrderStatusType {
  Active = 0,
  Unsubmitted = 1,
  Submitted = 2,
  Open = 3,
  SubmittedPendingConfirmation = 4,
}

export enum OrderTableMode {
  All = 0,
  Recent = 1,
}

export enum ProductBadgeType {
  CriticalItem = 'Critical Item',
  IsHidden = 'Is Hidden',
  IsRetained = 'Is Retained',
  OnManagedList = 'On Managed List',
  MSDS = 'SDS',
  EarlyCutoff = 'Early Cutoff',
  SpecialOrder = 'Special Order',
  ShipsLater = 'Ships later',
  DirectShip = 'Direct ship',
  VirtualWarehouse = 'Performance Direct item ships separately within 3 days',
  IsTemporarilyOut = 'Temporarily Unavailable',
  HasSubstituteProducts = 'Has Substitute Products',
  History = 'History',
}

export enum ProductDisplayMode {
  List,
  Grid,
}

export enum CustomEditSourceType {
  NotEditable,
  Editable,
}

export enum InvoiceType {
  Invoice = 0,
  Credit = 1,
  Adjustment = 2,
  Other = 3,
}

export enum ProductListSortByType {
  Category = 0,
  LastPurchased = 1,
  OrderEntry = 2,
  ProductDescription = 3,
  ProductNumber = 4,
  ListCategory = 5,
}

export enum VendorProductListSortByType {
  Alphabetical = 0,
  ProductNumber = 1,
}

export enum ActionType {
  AddToList = 0,
  RemoveFromList = 1,
  ReplaceRemove = 2,
  DismissOnly = 3,
}

export enum ProductListNotificationType {
  DiscontinuedProduct = 0,
  ProductAddedToManagedList = 1,
  ProductRemovedFromManagedList = 2,
  ProductRecentlyNotPurchased = 3,
  ForcedSubstitution = 4,
  FindAndReplace = 5,
}

export enum FileTypeName {
  PDF = 'PDF',
  Excel = 'Excel',
  CSV = 'CSV',
  PDFOptimized = 'PDF (Optimized for printing)',
}

export enum FileType {
  PDF = 0,
  Excel = 1,
  CSV = 2,
  PDFOptimized = 3,
}

export enum ManagedUsersFileType {
  CSV = 0,
  Excel = 1,
  PDF = 2,
}

export enum PageOrientation {
  Portrait = 0,
  Landcape = 1,
}

export enum ProductType {
  Standard = 0,
  ThirdPartyVendor = 1,
}
