import { ServiceResult } from '../models/api-shared.models';
import {
  ProductListProduct,
  ProductListSearchFieldType,
  ProductListSortOption,
  SearchProductListCatalogFieldRequest,
  SearchProductListCatalogRequest,
  SearchProductListCatalogResultDetail,
  SearchProductListRequest,
  SearchProductListResultDetail,
} from '../models/product-list-search.models';
import ApiService from './api-service';

class ProductListSearchService extends ApiService {
  private static classInstance: ProductListSearchService;

  private searchProductListAbortController: AbortController;
  private searchProductListCatalogAbortController: AbortController;
  private getProductListOrderEntrySortAbortController: AbortController;
  private getProductListParEntrySortAbortController: AbortController;

  private constructor() {
    super('ProductListSearch');
    this.searchProductListAbortController = new AbortController();
    this.searchProductListCatalogAbortController = new AbortController();
    this.getProductListOrderEntrySortAbortController = new AbortController();
    this.getProductListParEntrySortAbortController = new AbortController();
  }

  public abortSearchProductList = (): void => {
    this.searchProductListAbortController.abort();
  };

  public searchProductList(request: SearchProductListRequest): ServiceResult<SearchProductListResultDetail> {
    this.searchProductListAbortController = new AbortController();
    return this.post(
      `${this.baseUrl}/SearchProductList`,
      request,
      undefined,
      this.searchProductListAbortController.signal
    );
  }

  public abortSearchProductListCatalog = (): void => {
    this.searchProductListCatalogAbortController.abort();
  };

  public searchProductListCatalog(
    request: SearchProductListCatalogRequest
  ): ServiceResult<SearchProductListCatalogResultDetail> {
    this.searchProductListCatalogAbortController = new AbortController();
    return this.post(
      `${this.baseUrl}/SearchProductListCatalog`,
      request,
      'body',
      this.searchProductListCatalogAbortController.signal
    );
  }

  public searchProductListCatalogField(
    request: SearchProductListCatalogFieldRequest
  ): ServiceResult<ProductListProduct[]> {
    return this.post(`${this.baseUrl}/SearchProductListCatalogField`, request);
  }

  public getSearchProductListCatalogFieldDefault(): ServiceResult<ProductListSearchFieldType> {
    return this.post(`${this.baseUrl}/GetSearchProductListCatalogFieldDefault`, {});
  }

  public getProductListMaintenanceSort(productListHeaderId: string): ServiceResult<ProductListSortOption[]> {
    return this.post(
      `${this.baseUrl}/GetProductListMaintenanceSort`,
      { productListHeaderId: productListHeaderId },
      'queryParams'
    );
  }

  public abortGetProductListOrderEntrySort = (): void => {
    this.getProductListOrderEntrySortAbortController.abort();
  };

  public getProductListOrderEntrySort(productListHeaderId: string): ServiceResult<ProductListSortOption[]> {
    this.getProductListOrderEntrySortAbortController = new AbortController();
    return this.post(
      `${this.baseUrl}/GetProductListOrderEntrySort`,
      { productListHeaderId: productListHeaderId },
      'queryParams',
      this.getProductListOrderEntrySortAbortController.signal
    );
  }

  public abortGetProductListParEntrySort = (): void => {
    this.getProductListParEntrySortAbortController.abort();
  };

  public getProductListParEntrySort(productListHeaderId?: string): ServiceResult<ProductListSortOption[]> {
    this.getProductListParEntrySortAbortController = new AbortController();
    return this.post(
      `${this.baseUrl}/GetProductListParEntrySort`,
      { productListHeaderId: productListHeaderId },
      'queryParams',
      this.getProductListParEntrySortAbortController.signal
    );
  }
  public getProductListInventoryEntrySort(productListHeaderId?: string): ServiceResult<ProductListSortOption[]> {
    return this.post(
      `${this.baseUrl}/GetProductListInventoryEntrySort`,
      { productListHeaderId: productListHeaderId },
      'queryParams'
    );
  }
  public static getInstance(): ProductListSearchService {
    if (!this.classInstance) {
      this.classInstance = new ProductListSearchService();
    }
    return this.classInstance;
  }
}

export default ProductListSearchService;
