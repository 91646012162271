export const toCurrencyString = (currencyAmount: number | undefined, fractionDigits?: number): string => {
  if (typeof currencyAmount === 'undefined') return '';
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',

    minimumFractionDigits: 2, // (this suffices for whole numbers, but will print 2500.1 as $2,500.10)
    maximumFractionDigits: fractionDigits || 4, // (causes 2500.99345 to be printed as $2,501.9935 and still trims off 0s)
  });
  return formatter.format(currencyAmount);
};

export const formatCurrency = (currencyAmount: string | undefined): string => {
  if (currencyAmount == null) {
    return '';
  }

  const numberAmount = parseFloat(currencyAmount);

  if (Number.isNaN(numberAmount)) {
    return '';
  }

  return toCurrencyString(numberAmount);
};
