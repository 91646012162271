import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { MarketingZoneEnum, MarketingZoneIncrementer } from '../../api/models/marketing-zones.models';
import MarketingZonesService from '../../api/services/marketing-zones.service';
import { useAppInsightsLogger } from '../../logging';
import { AppDispatch, AppThunk } from '../store';
import { marketingZonesSlice } from './marketing-zones.slice';

const marketingZonesService = MarketingZonesService.getInstance();
const appInsightsLogger = useAppInsightsLogger();

/**
 * Calls and stores the result of the GetMarketingCampaigns API call
 *
 * @param request - Stores customer id, marketing zones for usage in making the API call
 * @param onFailure - Method to call on failure to provide the request data
 * @returns NULL
 */

export const getMarketingCampaigns =
  (customerId: string | undefined): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    try {
      if (customerId === getState().marketingZones.customerId) {
        return;
      }
      dispatch(marketingZonesSlice.actions.setCustomerId(customerId));

      dispatch(marketingZonesSlice.actions.setLoadingMarketingCampaigns(true));

      const { data } = await marketingZonesService.getMarketingCampaigns({ CustomerId: customerId });
      if (data.IsSuccess) {
        const marketingZones = data.ResultObject;
        dispatch(marketingZonesSlice.actions.setMarketingZones(marketingZones));
      }
    } catch (error) {
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
    } finally {
      dispatch(marketingZonesSlice.actions.setLoadingMarketingCampaigns(false));
    }
  };

export const dispatchMarketingZoneClick =
  (MarketingCampaignImageId: string): AppThunk =>
  async (): Promise<any> => {
    try {
      const { data } = await marketingZonesService.updateMarketingCampaignStatisticsCount({ MarketingCampaignImageId });
      if (data.IsSuccess) {
        const responseData = data.ResultObject;
        return Promise.resolve(responseData);
      }
    } catch (error) {
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
    }
  };

export const dispatchMarketingZoneViews =
  (MarketingCampaignImageId: string): AppThunk =>
  async (): Promise<any> => {
    try {
      const { data } = await marketingZonesService.updateMarketingCampaignStatisticsViews({ MarketingCampaignImageId });
      if (data.IsSuccess) {
        const responseData = data.ResultObject;
        return Promise.resolve(responseData);
      }
    } catch (error) {
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
    }
  };

export const incrementImageIndex =
  (payload: MarketingZoneIncrementer): AppThunk =>
  async (dispatch: AppDispatch) =>
    dispatch(marketingZonesSlice.actions.incrementImageIndex(payload));

export const incrementPriorityImageIndex =
  (payload: MarketingZoneEnum): AppThunk =>
  async (dispatch: AppDispatch) =>
    dispatch(marketingZonesSlice.actions.incrementPriorityImageIndex(payload));

export const incrementAllImageIndex =
  (payload: MarketingZoneIncrementer): AppThunk =>
  async (dispatch: AppDispatch) =>
    dispatch(marketingZonesSlice.actions.incrementAllImageIndex(payload));
