import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { openFileInCurrentWindow } from 'web/src/utilities/download-file';
import { UnitOfMeasureType } from '../../api/models/api-shared.enums';
import { CatalogProduct, FileResult } from '../../api/models/api-shared.models';
import { CustomerProductPriceResult } from '../../api/models/customer-product-price.models';
import {
  CustomProductNumberText,
  UpdateCustomProductRequest,
  UpdateCustomProductUOMRequest,
} from '../../api/models/customer-product.models';
import {
  GetProductDetailRequest,
  ReportIncorrectProductInformation,
  RequestProductNutritionalInformation,
} from '../../api/models/product-detail.models';
import { UserCustomer } from '../../api/models/site.models';
import CustomerProductService from '../../api/services/customer-product.service';
import ProductDetailService from '../../api/services/product-detail.service';
import ProductFooterService from '../../api/services/product-footer.service';
import { generateStaticId } from '../../helpers/lookups/AppNotificationsCenter';
import { useAppInsightsLogger } from '../../logging/AppInsightsLogger';
import { NotificationDisplayType, NotificationKeys, NotificationType } from '../../models/notifications.models';
import { AppRoute } from '../../models/routing.models';
import { updateSelectedCustomer } from '../common';
import { globalSlice } from '../common/global.slice';
import { upsertAppNotification } from '../common/global.thunks';
import { parMaintenanceSlice } from '../par-management';
import { productListSearchSlice } from '../product-list-management/product-list-search.slice';
import { productListSlice } from '../product-list-management/product-list.slice';
import { productSearchSlice, similarProductSearchSlice } from '../search';
import { AppDispatch, AppThunk, RootState } from '../store';
import { itemOrderEntrySlice } from './item-order-entry.slice';
import { orderEntrySlice } from './order-entry.slice';
import { orderReviewSlice } from './order-review.slice';
import { getActiveOrder } from './orders.thunks';
import { productDetailSlice } from './product-detail.slice';

//hooks
const productDetailService = ProductDetailService.getInstance();
const customerProductService = CustomerProductService.getInstance();
const productFooterService = ProductFooterService.getInstance();
const appInsightsLogger = useAppInsightsLogger();

// Thunks

/**
 * Calls and stores results from GetProductDetail, GetProductFooter, and GetProductInformationDescription API calls
 *
 * @param customerId - The id of the customer
 * @param productKey - The product who's details are being retrieved
 * @param orderId - The order id from which the product details are supposed to come from
 * @returns NULL
 */
export const getProductDetail =
  (customerId: string, productKey: string, orderId?: string, successCallback?: () => void): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    try {
      if (!getState().productDetail.productDetailLoading)
        dispatch(productDetailSlice.actions.setProductDetailLoading(true));

      let selectedCustomer = getState().customer.selectedCustomer;
      if (selectedCustomer?.CustomerId !== customerId) {
        selectedCustomer = getState().user.userSite?.UserCustomers.find(
          (customer) => customer.CustomerId === customerId
        );
        if (selectedCustomer) dispatch(updateSelectedCustomer(selectedCustomer));
      }

      if (!selectedCustomer) return;

      dispatch(productDetailSlice.actions.setLoadingProductDetail(true));

      const apiRequest: GetProductDetailRequest = {
        ProductKey: productKey,
        CustomerId: customerId,
        ...(orderId && { OrderEntryHeaderId: orderId }),
      };

      const getDetailTask = productDetailService.getProductDetail(apiRequest);
      const getFooterTask = dispatch(getProductFooter(selectedCustomer, productKey));
      const getInfoTask = dispatch(getProductInformationDescription(selectedCustomer, productKey));
      const result = await Promise.all([getDetailTask, getFooterTask, getInfoTask]);

      if (result[0].data.IsSuccess) {
        dispatch(productDetailSlice.actions.setProductDetail(result[0].data.ResultObject));
        successCallback?.();
      }
    } catch (error: unknown) {
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
      // [TODO]: Handle state if an error occurs here
    } finally {
      dispatch(productDetailSlice.actions.setProductDetailLoading(false));
      dispatch(productDetailSlice.actions.setLoadingProductDetail(false));
    }
  };

/**
 * Calls and stores results from GetProductFooter API call
 *
 * @param customer - The customer's information
 * @param productKey - The product who's details are being retrieved
 * @returns NULL
 */
export const getProductFooter =
  (customer: UserCustomer, productKey: string): AppThunk<Promise<void>> =>
  async (dispatch: AppDispatch, getState: () => RootState): Promise<void> => {
    try {
      if (!getState().productDetail.productFooterLoading)
        dispatch(productDetailSlice.actions.setProductFooterLoading(true));
      if (!productKey || !customer.OperationCompanyNumber) return;

      const { data } = await productFooterService.getProductFooter({
        ProductKey: productKey,
        CustomerId: customer.CustomerId,
        OperationCompanyNumber: customer.OperationCompanyNumber,
      });
      if (data.IsSuccess) {
        dispatch(productDetailSlice.actions.setProductFooter(data.ResultObject));
      }
      // [TODO]: Handle state if IsSuccess === false
    } catch (error: unknown) {
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
      // [TODO]: Handle state if an error occurs here
    } finally {
      dispatch(productDetailSlice.actions.setProductFooterLoading(false));
    }
  };

/**
 * Calls and stores results from GetProductInformationDescription API call
 *
 * @param customer - The customer's information
 * @param productKey - The product who's details are being retrieved
 * @returns NULL
 */
export const getProductInformationDescription =
  (customer?: UserCustomer, productKey?: string): AppThunk<Promise<void>> =>
  async (dispatch: AppDispatch, getState: () => RootState): Promise<void> => {
    try {
      if (!customer || !productKey) return;

      const previousRequest = getState().productDetail.productInformationDescriptionRequest;
      const request = {
        BusinessUnitERPKey: customer.BusinessUnitERPKey,
        BusinessUnitKey: customer.BusinessUnitKey,
        ProductKey: productKey,
      };

      if (JSON.stringify(previousRequest) === JSON.stringify(request)) {
        return;
      }

      dispatch(productDetailSlice.actions.setProductInformationDescriptionRequest(request));
      const { data } = await productDetailService.getProductInformationDescription(request);

      if (data.IsSuccess) {
        dispatch(productDetailSlice.actions.setProductInformationDescription(data.ResultObject));
      } else {
        dispatch(globalSlice.actions.setErrorDialogContent({ messages: data.ErrorMessages }));
      }
      // [TODO]: Handle state if IsSuccess === false
    } catch (error: unknown) {
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
      // [TODO]: Handle state if an error occurs here
    }
  };

/**
 * Calls and stores results from GetProductInformationAllergensAndAdditional API call
 *
 * @param customer - The customer's information
 * @param productKey - The product who's details are being retrieved
 * @returns NULL
 */
export const getProductInformationAllergensAndAdditional =
  (customer?: UserCustomer, productKey?: string): AppThunk =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      if (!customer || !productKey) return;

      const previousRequest = getState().productDetail.productInformationAllergensAndAdditionalRequest;
      const request = {
        BusinessUnitERPKey: customer.BusinessUnitERPKey,
        BusinessUnitKey: customer.BusinessUnitKey,
        ProductKey: productKey,
      };

      if (JSON.stringify(previousRequest) === JSON.stringify(request)) {
        return;
      }

      dispatch(productDetailSlice.actions.setProductInformationAllergensAndAdditionalRequest(request));

      const { data } = await productDetailService.getProductInformationAllergensAndAdditional(request);

      if (data.IsSuccess) {
        dispatch(productDetailSlice.actions.setProductInformationAllergensAndAdditional(data.ResultObject));
      }
      // [TODO]: Handle state if IsSuccess === false
    } catch (error: unknown) {
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
      // [TODO]: Handle state if an error occurs here
    }
  };

export const setProductDetailDocumentHeadersLoading =
  (loading: boolean): AppThunk =>
  async (dispatch: AppDispatch) => {
    dispatch(productDetailSlice.actions.setProductDetailDocumentHeadersLoading(loading));
  };

export const getProductDetailDocumentHeaders =
  (customer?: UserCustomer, productKey?: string): AppThunk =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      if (!customer || !productKey) return;

      const previousRequest = getState().productDetail.productDetailDocumentHeadersRequest;
      const request = {
        CustomerId: customer.CustomerId,
        BusinessUnitERPKey: customer.BusinessUnitERPKey,
        BusinessUnitKey: customer.BusinessUnitKey,
        ProductKey: productKey,
      };

      if (JSON.stringify(previousRequest) === JSON.stringify(request)) {
        return;
      }

      dispatch(productDetailSlice.actions.setProductDetailDocumentHeadersRequest(request));

      dispatch(setProductDetailDocumentHeadersLoading(true));
      const { data } = await productDetailService.getProductDetailDocumentHeaders(request);

      if (data.IsSuccess) {
        dispatch(productDetailSlice.actions.setProductDetailDocuments(data.ResultObject));
      }
    } catch (error: unknown) {
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
    } finally {
      dispatch(setProductDetailDocumentHeadersLoading(false));
    }
  };

export const setProductDetailDocumentIsLoading =
  (loading: boolean): AppThunk =>
  async (dispatch: AppDispatch) => {
    dispatch(productDetailSlice.actions.setProductDetailDocumentIsLoading(loading));
  };

export const getProductDetailDocument =
  (documentKey: string, customer?: UserCustomer): AppThunk =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      if (!customer) return;
      const previousRequest = getState().productDetail.productDetailDocumentRequest;

      const request = {
        DocumentKey: documentKey,
        BusinessUnitKey: customer.BusinessUnitKey,
      };

      if (JSON.stringify(previousRequest) === JSON.stringify(request)) {
        return;
      }

      dispatch(productDetailSlice.actions.setProductDetailDocumentRequest(request));
      dispatch(setProductDetailDocumentIsLoading(true));

      const { data } = await productDetailService.getProductDetailDocument(request);

      if (data.IsSuccess) {
        dispatch(productDetailSlice.actions.setProductDetailDocument(data.ResultObject as FileResult));
      } else {
        dispatch(globalSlice.actions.setErrorDialogContent({ messages: data.ErrorMessages }));
      }
    } catch (error) {
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
    } finally {
      dispatch(setProductDetailDocumentIsLoading(false));
    }
  };

export const exportProductDetailDocument =
  (documentKey: string, customer?: UserCustomer): AppThunk =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    if (getState().productDetail.productDetailDocumentExporting) {
      return;
    }
    try {
      if (!customer) return;

      const request = {
        DocumentKey: documentKey,
        BusinessUnitKey: customer.BusinessUnitKey,
      };

      dispatch(productDetailSlice.actions.setProductDetailDocumentRequest(request));
      dispatch(productDetailSlice.actions.setProductDetailDocumentIsExporting(true));

      dispatch(
        upsertAppNotification(
          {
            Id: generateStaticId('toastNotification', ['document-request-toast']),
            NotificationType: NotificationType.Information,
            NotificationDisplayType: NotificationDisplayType.Toast,
            AutoDismiss: 10,
            CanUserDismiss: true,
            Key: NotificationKeys.Toast,
            Message: 'We are preparing your download.',
          },
          0
        )
      );

      const { data } = await productDetailService.getProductDetailDocument(request);

      if (data.IsSuccess) {
        openFileInCurrentWindow(data.ResultObject as FileResult);
      } else {
        dispatch(globalSlice.actions.setErrorDialogContent({ messages: data.ErrorMessages }));
      }
    } catch (error) {
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
    } finally {
      dispatch(productDetailSlice.actions.setProductDetailDocumentIsExporting(false));
    }
  };

export const setProductDetailPdfIsLoading =
  (loading: boolean): AppThunk =>
  async (dispatch: AppDispatch) => {
    dispatch(productDetailSlice.actions.setProductDetailPdfIsLoading(loading));
  };

export const getProductDetailPdf =
  (productKey?: string, customer?: UserCustomer, orderEntryHeaderId?: string): AppThunk =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    if (!customer || !productKey) return;
    try {
      const previousRequest = getState().productDetail.productDetailPdfRequest;
      const request = {
        ProductKey: productKey,
        CustomerId: customer?.CustomerId,
        OrderEntryHeaderId: orderEntryHeaderId,
        TimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      };

      if (JSON.stringify(previousRequest) === JSON.stringify(request)) {
        return;
      }

      dispatch(setProductDetailPdfIsLoading(true));
      dispatch(productDetailSlice.actions.setProductDetailPdfRequest(request));

      const { data } = await productDetailService.getProductDetailPdf(request);
      if (data.IsSuccess) {
        dispatch(productDetailSlice.actions.setProductDetailPdf(data.ResultObject as FileResult));
      } else {
        dispatch(globalSlice.actions.setErrorDialogContent({ messages: data.ErrorMessages }));
      }
    } catch (error) {
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
    } finally {
      dispatch(setProductDetailPdfIsLoading(false));
    }
  };

/**
 * Calls and stores results from GetProductInformationPackingAndStorage API call
 *
 * @param customer - The customer's information
 * @param productKey - The product who's details are being retrieved
 * @returns NULL
 */
export const getProductInformationPackingAndStorage =
  (customer?: UserCustomer, productKey?: string): AppThunk =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      if (!customer || !productKey) return;

      const previousRequest = getState().productDetail.productInformationPackingAndStorageRequest;
      const request = {
        BusinessUnitERPKey: customer.BusinessUnitERPKey,
        BusinessUnitKey: customer.BusinessUnitKey,
        ProductKey: productKey,
      };

      if (JSON.stringify(previousRequest) === JSON.stringify(request)) {
        return;
      }

      dispatch(productDetailSlice.actions.setLoadingProductInformationPackingAndStorage(true));
      dispatch(productDetailSlice.actions.setProductInformationPackingAndStorageRequest(request));

      const { data } = await productDetailService.getProductInformationPackingAndStorage(request);

      if (data.IsSuccess) {
        dispatch(productDetailSlice.actions.setProductInformationPackingAndStorage(data.ResultObject));
      }
      dispatch(productDetailSlice.actions.setLoadingProductInformationPackingAndStorage(false));
    } catch (error: unknown) {
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
    }
  };

/**
 * Sets the breadcrumb for the product details page
 *
 * @param routes - Route to set the breadcrumb to
 * @returns NULL
 */
export const setProductDetailBreadCrumb =
  (routes: AppRoute[]): AppThunk =>
  (dispatch: AppDispatch) => {
    dispatch(productDetailSlice.actions.setBreadCrumb(routes));
  };

/**
 * Resets the custom description, custom product number and updates the product to reflect this
 *
 * @returns NULL
 */
export const resetCustomAttributesToDefault = (): AppThunk => (dispatch: AppDispatch) => {
  dispatch(resetCustomProductValuesToDefault());
  dispatch(updateCustomAttributes());
};

/**
 * Calls and stores the result of the UpdateCustomProduct API call
 *
 * @returns NULL
 */
export const updateCustomAttributes = (): AppThunk => async (dispatch: AppDispatch, getState) => {
  const selectedCustomer = getState().customer.selectedCustomer;
  const { ProductKey: productKey } = getState().productDetail.productDetailHeader || {};
  const productDetail = getState().productDetail.productDetailHeader;
  const newDescription = getState().productDetail.customProductDescription;
  const newProductNumber = getState().productDetail.customProductNumbers;

  if (!productKey || !selectedCustomer || !productDetail) return;
  const canEditDescription = productDetail.CanEditProductDescription;
  try {
    dispatch(productDetailSlice.actions.setLoadingDescriptionDisplay(true));
    dispatch(productDetailSlice.actions.setLoadingNumberDisplay(true));

    const uoms: UpdateCustomProductUOMRequest[] = [];
    newProductNumber
      .filter((uom: CustomProductNumberText) => uom.isChanged)
      .forEach((productNumberDescriptions: CustomProductNumberText) => {
        uoms.push({
          CustomItemNumber: productNumberDescriptions.productNumberText,
          UnitOfMeasure: productNumberDescriptions.uom,
        });
      });

    if (uoms.length <= 0 && newDescription === productDetail.DisplayProductDescription) return;

    const apiRequest: UpdateCustomProductRequest = {
      CustomerId: selectedCustomer.CustomerId,
      ProductKey: productKey,
      ...(typeof newDescription !== 'undefined' && canEditDescription && { CustomProductDescription: newDescription }),
      ...(uoms.length && { UnitOfMeasures: uoms }),
    };

    const productDescriptionResponse = await customerProductService.updateCustomProduct(apiRequest);

    const res = productDescriptionResponse.data;
    if (res.IsSuccess) {
      const productNumberUpdates: { [uom: number]: string } = {};
      if (res.ResultObject.UnitOfMeasures) {
        res.ResultObject.UnitOfMeasures.forEach((req: UpdateCustomProductUOMRequest) => {
          if (req.CustomItemNumber) {
            productNumberUpdates[req.UnitOfMeasure] = req.CustomItemNumber;
          }
        });
      }

      const customDescription = productDescriptionResponse.data.ResultObject.CustomProductDescription;
      dispatch(
        productDetailSlice.actions.updateCustomDescription({
          productDescription: customDescription,
          productNumbers: productNumberUpdates,
        })
      );
      // Refresh shopping cart with custom product #s
      dispatch(getActiveOrder(selectedCustomer.CustomerId));

      dispatch(orderReviewSlice.actions.updateProductCustomAttributes(apiRequest));
      dispatch(orderEntrySlice.actions.updateProductCustomAttributes(apiRequest));
      dispatch(similarProductSearchSlice.actions.updateProductCustomAttributes(apiRequest));
      dispatch(productSearchSlice.actions.updateProductCustomAttributes(apiRequest));
      dispatch(productListSlice.actions.updateProductCustomAttributes(apiRequest));
      dispatch(productListSearchSlice.actions.updateProductCustomAttributes(apiRequest));
      dispatch(itemOrderEntrySlice.actions.updateProductCustomAttributes(apiRequest));
      dispatch(parMaintenanceSlice.actions.updateProductCustomAttributes(apiRequest));
    } else {
      const newNotification = {
        Id: generateStaticId('prodDescription'),
        NotificationType: NotificationType.Error,
        NotificationDisplayType: NotificationDisplayType.Field,
        AutoDismiss: 3,
        CanUserDismiss: true,
        Key: NotificationKeys.TextFieldNotification,
        Message: productDescriptionResponse.data.ErrorMessages.join(', '),
      };
      dispatch(upsertAppNotification(newNotification));
      dispatch(
        globalSlice.actions.setErrorDialogContent({
          title: 'Custom attributes could not be updated',
          messages: res.ErrorMessages,
        })
      );
      dispatch(resetCustomProductValues());
    }
  } catch (error: unknown) {
    appInsightsLogger.trackException({
      exception: error,
      severityLevel: SeverityLevel.Error,
    });
  } finally {
    dispatch(productDetailSlice.actions.setEditCustomAttributesMode(false));
    dispatch(productDetailSlice.actions.setLoadingDescriptionDisplay(false));
    dispatch(productDetailSlice.actions.setLoadingNumberDisplay(false));
  }
};

/**
 * Updates the product's price
 *
 * @param product - The product which is having its price updates
 * @param priceResults - Information about the updated price
 * @returns NULL
 */
export const updateProductDetailPrice =
  (product: CatalogProduct, priceResults: CustomerProductPriceResult[]): AppThunk =>
  (dispatch: AppDispatch) => {
    const _product: CatalogProduct = {
      ...product,
      UnitOfMeasureOrderQuantities: product.UnitOfMeasureOrderQuantities.map((productUom) => {
        const priceUom = priceResults.find((uom) => uom.UnitOfMeasureType === productUom.UnitOfMeasure);
        return {
          ...productUom,
          Price: priceUom?.Price,
          SecondaryUnitPrice: priceUom?.SecondaryUnitPrice,
        };
      }),
    };
    dispatch(productDetailSlice.actions.setProductDetail(_product));
  };

/**
 * Updates the editCustomAttributesMode value in the product details slice
 *
 * @param value - Boolean which editCustomAttributesMode's value will be set to
 * @returns NULL
 */
export const setEditCustomAttributesMode =
  (value: boolean): AppThunk =>
  (dispatch: AppDispatch) => {
    dispatch(productDetailSlice.actions.setEditCustomAttributesMode(value));
  };

export const resetCustomProductValues = (): AppThunk => (dispatch: AppDispatch, getState: () => RootState) => {
  const productDetail = getState().productDetail.productDetailHeader;

  if (!productDetail) return;

  const resetDesc: string = productDetail.DisplayProductDescription || '';
  const resetProdValues: CustomProductNumberText[] = productDetail.UnitOfMeasureOrderQuantities.map((uom) => {
    return {
      productNumberText: uom.ProductNumberDisplay || '',
      uom: uom.UnitOfMeasure,
      isChanged: false,
    };
  });

  dispatch(
    productDetailSlice.actions.resetToDefaultCustomValues({ customDesc: resetDesc, customProdNums: resetProdValues })
  );
};

/**
 * Resets a product's custom attributes to their orignial values
 *
 * @returns NULL
 */
export const resetCustomProductValuesToDefault = (): AppThunk => (dispatch: AppDispatch, getState: () => RootState) => {
  const productDetail = getState().productDetail.productDetailHeader;
  if (!productDetail) return;

  const canEditDescription = productDetail.CanEditProductDescription;
  const canEditItemNumbers = productDetail.CanEditProductNumber;

  const resetDesc: string = productDetail.ProductDescription ?? '';
  const resetProdValues: CustomProductNumberText[] = productDetail.UnitOfMeasureOrderQuantities.map((uom) => {
    return {
      productNumberText: uom.OrignialProductNumber ?? '',
      uom: uom.UnitOfMeasure,
      isChanged: uom.OrignialProductNumber !== uom.ProductNumberDisplay,
    };
  });

  dispatch(
    productDetailSlice.actions.resetToDefaultCustomValues({
      customDesc: canEditDescription ? resetDesc : undefined,
      customProdNums: canEditItemNumbers ? resetProdValues : undefined,
    })
  );
};

/**
 * Sets a product's custom description
 *
 * @param value - The value the custom description will be set to
 * @returns NULL
 */
export const updateCustomProductDescription =
  (value: string): AppThunk =>
  (dispatch: AppDispatch) => {
    dispatch(productDetailSlice.actions.setCustomProductDescription(value));
  };

/**
 * Sets a product's custom product number
 *
 * @param uom - The unit of measure type that will have the corresponding value changed
 * @param val - The value the custom product nymber will be set to
 * @returns NULL
 */
export const updateCustomProductNumber =
  (uom: UnitOfMeasureType, val: string): AppThunk =>
  (dispatch: AppDispatch, getState: () => RootState) => {
    const customNumbers = [...getState().productDetail.customProductNumbers];
    const productDetail = getState().productDetail.productDetailHeader;

    if (!productDetail) return;

    customNumbers[customNumbers.findIndex((cn) => cn.uom === uom)] = {
      productNumberText: val,
      uom: uom,
      isChanged: !productDetail.UnitOfMeasureOrderQuantities.some((uom) => uom.ProductNumberDisplay === val),
    };

    dispatch(productDetailSlice.actions.setCustomProductNumbers(customNumbers));
  };

/**
 * Calls and stores the result of the RequestProductNutritionalInformation API call
 *
 * @param customerId - The customer's id
 * @param requestDescription - The message from the user
 * @param requestEmailAddress - The email the user provides for communication
 * @returns NULL
 */
export const requestProductNutritionalInformation =
  (customerId: string | undefined, requestDescription: string, requestEmailAddress: string): AppThunk =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    if (!requestDescription || !requestEmailAddress) return;

    const apiRequest: RequestProductNutritionalInformation = {
      CustomerId: customerId ?? '',
      ProductKey: getState().productDetail.productDetailHeader?.ProductKey ?? '',
      ProductBrand: getState().productDetail.productDetailHeader?.ProductBrand ?? '',
      ProductDescription: getState().productDetail.productDetailHeader?.ProductDescription ?? '',
      ProductNumber: getState().productDetail.productDetailHeader?.ProductNumber ?? '',
      RequestDetails: requestDescription,
      ContactEmailAddress: requestEmailAddress,
    };

    try {
      const { data } = await productDetailService.requestProductNutritionalInformation(apiRequest);
      if (!data.IsSuccess) {
        dispatch(
          globalSlice.actions.setErrorDialogContent({
            title: 'Request for nutritional information could not be completed',
            messages: data.ErrorMessages,
          })
        );
      }
    } catch (error: unknown) {
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
    }
  };

/**
 * Calls and stores the result of the ReportIncorrectProductInformation API call
 *
 * @param customerId - The customer's id
 * @param incorrectReasonDetails - The message from the user
 * @param contactEmailAddress - The email the user provides for communication
 * @returns NULL
 */
export const reportIncorrectProductInformation =
  (customerId: string | undefined, incorrectReasonDetails: string, contactEmailAddress: string): AppThunk =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    if (!incorrectReasonDetails || !contactEmailAddress) return;

    const apiRequest: ReportIncorrectProductInformation = {
      CustomerId: customerId ?? '',
      ProductKey: getState().productDetail.productDetailHeader?.ProductKey ?? '',
      ProductBrand: getState().productDetail.productDetailHeader?.ProductBrand ?? '',
      ProductDescription: getState().productDetail.productDetailHeader?.ProductDescription ?? '',
      ProductNumber: getState().productDetail.productDetailHeader?.ProductNumber ?? '',
      IncorrectReason: incorrectReasonDetails,
      ContactEmailAddress: contactEmailAddress,
    };

    try {
      const { data } = await productDetailService.reportIncorrectProductInformation(apiRequest);
      if (!data.IsSuccess) {
        dispatch(
          globalSlice.actions.setErrorDialogContent({
            title: 'Report of incorrect information could not be completed',
            messages: data.ErrorMessages,
          })
        );
      }
    } catch (error: unknown) {
      appInsightsLogger.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
      });
    }
  };

/**
 * Resets all values in the product details slice to their original values
 *
 * @returns NULL
 */
export const resetProductDetail = (): AppThunk => async (dispatch: AppDispatch) =>
  dispatch(productDetailSlice.actions.resetProductDetail());
