// Header
export interface GetInventorySnapshotHeaderRequest {
  InventorySnapshotHeaderId: string;
}

export interface GetInventorySnapshotHeadersRequest {
  CustomerId: string;
  StartDate: string | Date | undefined;
  EndDate: string | Date | undefined;
}

export interface InventorySnapshotHeader {
  InventorySnapshotHeaderId: string;
  CustomerId: string;
  InventoryName: string;
  InventoryDate: Date | string;
  InventoryStatusType: number;
  IsMerged: boolean;
  ChangeUserDisplay: string;
  CreateUserDisplay: string;
  TotalQuantity: number;
  TotalPrice: number;
  ChangeDate: Date | string;
  CreateDate: Date | string;

  IsChecked?: boolean;
}
// Detail

export interface UpdateInventorySnapshotDetailRequest {
  InventorySnapshotHeaderId: string;
  ProductKey: string;
  ProductBusinessUnitERPKeyType: number;
  UnitOfMeasureDisplay: string;
  ThirdPartyVendorProductId?: string;
  QuantityFull?: number | undefined;
  QuantityPartial?: number | undefined;
  CustomUnitsPerFull?: number;
  CustomUnitsPerFullDescription?: string;
}

export interface InventorySnapshotDetail {
  InventorySnapshotDetailId: string;
  InventorySnapshotHeaderId: string;
  ProductType: number;
  ProductKey: string;
  ProductBusinessUnitERPKeyTypeEnum: number;
  UnitOfMeasureDisplay: string;
  ThirdPartyVendorProductId?: string;
  CustomUnitsPerFull?: number;
  CustomUnitsPerFullDescription?: string;
  CustomPackSize?: string;
  UnitsPerFull?: number;
  QuantityFull: number | undefined;
  QuantityPartial: number | undefined;
  ProductDescription: string;
  ProductBrand: string;
  ProductNumber: string;
  ProductPackSize: string;
  ProductAverageWeight: number;
  Price?: number;
  ExtendedPrice?: number;

  Note?: string;
}

export interface UpdateInventorySnapshotProductMessageRequest {
  InventorySnapshotHeaderId: string;
  ProductKey: string;
  ThirdPartyVendorProductId?: string;
  ProductBusinessUnitERPKeyType: number;
  Note: string;
}

// Search

export interface InventorySnapshotSearchProduct {
  ProductId: string;
  InventorySnapshotHeaderId: string;
  ProductType: number;
  ProductKey: string;
  ProductBusinessUnitERPKeyTypeEnum: number;
  ThirdPartyVendorProductId?: string;
  ProductDescription: string;
  CustomProductDescription?: string;
  ProductBrand: string;
  Note?: string;
  InventorySnapshotSearchUnitOfMeasures: InventorySnapshotSearchUnitOfMeasure[];
}

export interface InventorySnapshotSearchUnitOfMeasure {
  InventorySnapshotDetailId: string;
  UnitOfMeasureDisplay: string;
  CustomUnitsPerFull?: number;
  CustomUnitsPerFullDescription?: string;
  CustomPackSize?: string;
  UnitsPerFull?: number;
  QuantityFull?: number;
  QuantityPartial?: number;

  Price?: number;
  ExtendedPrice?: number;

  ProductNumber: string;
  CustomProductNumber?: string;
  ProductPackSize: string;
  ProductAverageWeight: number;
  ProductIsCatchWeight: boolean;
}

export const UnitOfMeasureShortDescriptionLookup: { [shortDescription: string]: { type: number; name: string } } = {
  CS: {
    type: 0,
    name: 'Case',
  },
  EA: {
    type: 1,
    name: 'Each',
  },
  BX: {
    type: 2,
    name: 'Box',
  },
};

export interface GetInventorySnapshotSearchRequest {
  InventorySnapshotHeaderId: string;
  QueryText: string;
  SortBy: InventorySnapshotSearchSortByType;
}

export enum InventorySnapshotSearchSortByType {
  InventoryEntry = 0,
  ProductNumber = 1,
  ProductDescription = 2,
}

export interface InventorySnapshotSearchSortOption {
  Title?: string;
  Value: InventorySnapshotSearchSortByType;
}

export interface GetInventorySnapshotSearchResultDetail {
  HasLoadMore: boolean;
  TotalCount: number;
  InventorySnapshotSearchProducts: InventorySnapshotSearchProduct[];
}

export interface UpdateInventorySnapshotHeaderRequest {
  InventorySnapshotHeaderId: string;
  InventoryName: string;
  InventoryDate?: string | Date | undefined;
}

export interface ApproveReviewInventorySnapshotHeaderRequest {
  InventorySnapshotHeaderId: string;
}

export interface DeleteInventorySnapshotHeaderRequest {
  InventorySnapshotHeaderId: string;
  CustomerId: string;
}

export interface MergeInventorySnapshotHeadersRequest {
  CustomerId: string;
  InventorySnapshotHeaderIds: string[];
  InventoryName: string;
  DeleteSnapshotsOnMerge: boolean;
}

export interface GetInventorySnapshotLogRequest {
  InventorySnapshotHeaderId: string;
  StartDate?: string;
  EndDate?: string;
  ActionType?: number;
}

export interface InventorySnapshotLog {
  InventorySnapshotLogId: string;
  ActionType: InventorySnapshotLogActionType;
  ActionName: string;
  ActionSummary: string;
  ChangeByUser: string;
  ChangeDate: string | Date;
  AdditionalData: InventorySnapshotLogAdditionalData[];
}

type InventorySnapshotLogAdditionalData = InventorySnapshotMergeLogAdditionalData | undefined;

type InventorySnapshotMergeLogAdditionalData = {
  InventorySnapshotHeaderId: string;
  InventoryDate?: string | Date;
  InventoryName?: string;
  ChangeUserDisplay?: string;
};

export enum InventorySnapshotLogActionType {
  SubmitInventory = 0,
  UpdateInventory = 1,
  MergeInventory = 2,
  ApproveInventory = 3,
  ReviewInventory = 4,
  DeleteInventory = 5,
}

export interface ResetInventorySnapshotDetailCustomPackSizesRequest {
  InventorySnapshotHeaderId: string;
  ProductKey: string;
  ProductBusinessUnitERPKeyType: number;
}

export interface ResetInventorySnapshotDetailsCustomPackSizesRequest {
  InventorySnapshotHeaderId: string;
}
