import {
  ApproveReviewInventorySnapshotHeaderRequest,
  DeleteInventorySnapshotHeaderRequest,
  GetInventorySnapshotHeaderRequest,
  GetInventorySnapshotHeadersRequest,
  GetInventorySnapshotLogRequest,
  InventorySnapshotHeader,
  InventorySnapshotLog,
  MergeInventorySnapshotHeadersRequest,
  ServiceResult,
  UpdateInventorySnapshotHeaderRequest,
} from '../models';
import ApiService from './api-service';

class InventorySnapshotHeaderService extends ApiService {
  private static classInstance: InventorySnapshotHeaderService;

  private constructor() {
    super('InventorySnapshotHeader');
  }
  public getInventorySnapshotHeader(
    request: GetInventorySnapshotHeaderRequest
  ): ServiceResult<InventorySnapshotHeader> {
    return this.get(`${this.persistUrl}/GetInventorySnapshotHeader`, request);
  }

  public getInventorySnapshotHeadersInReview(
    request: GetInventorySnapshotHeadersRequest
  ): ServiceResult<InventorySnapshotHeader[]> {
    return this.get(`${this.persistUrl}/GetInventorySnapshotHeadersInReview`, request);
  }

  public getInventorySnapshotHeadersCompleted(
    request: GetInventorySnapshotHeadersRequest
  ): ServiceResult<InventorySnapshotHeader[]> {
    return this.get(`${this.persistUrl}/GetInventorySnapshotHeadersCompleted`, request);
  }

  public approveInventorySnapshotHeader(
    request: ApproveReviewInventorySnapshotHeaderRequest
  ): ServiceResult<InventorySnapshotHeader> {
    return this.post(`${this.persistUrl}/ApproveInventorySnapshotHeader`, request);
  }

  public reviewInventorySnapshotHeader(
    request: ApproveReviewInventorySnapshotHeaderRequest
  ): ServiceResult<InventorySnapshotHeader> {
    return this.post(`${this.persistUrl}/ReviewInventorySnapshotHeader`, request);
  }
  public updateInventorySnapshotHeader(
    request: UpdateInventorySnapshotHeaderRequest
  ): ServiceResult<InventorySnapshotHeader> {
    return this.post(`${this.persistUrl}/UpdateInventorySnapshotHeader`, request);
  }

  public deleteInventorySnapshotHeader(request: DeleteInventorySnapshotHeaderRequest): ServiceResult<undefined> {
    return this.post(`${this.persistUrl}/DeleteInventorySnapshotHeader`, request);
  }

  public mergeInventorySnapshotHeaders(
    request: MergeInventorySnapshotHeadersRequest
  ): ServiceResult<InventorySnapshotHeader> {
    return this.post(`${this.persistUrl}/MergeInventorySnapshotHeaders`, request);
  }

  public getInventorySnapshotLogs(request: GetInventorySnapshotLogRequest): ServiceResult<InventorySnapshotLog[]> {
    return this.get(`${this.persistUrl}/GetInventorySnapshotLogs`, request);
  }

  public static getInstance(): InventorySnapshotHeaderService {
    if (!this.classInstance) {
      this.classInstance = new InventorySnapshotHeaderService();
    }
    return this.classInstance;
  }
}

export default InventorySnapshotHeaderService;
