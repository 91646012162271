import { OrderEntryType } from '../../models/order.enums';
import { BusinessUnitERPKeyType, BusinessUnitKeyType } from './api-shared.enums';

export interface GetOrderConfirmationRequest {
  orderEntryHeaderId: string;
  isDummyConfirmation?: boolean;
}

export interface SubmittedOrderData {
  CustomerId: string | undefined;
  OrderId: string | undefined;
}

export interface OrderConfirmationResult {
  OrderEntryHeaderId: string;
  ConfirmationHeaderKey?: string;
  BusinessUnitKey: BusinessUnitKeyType;
  BusinessUnitERPKey: BusinessUnitERPKeyType;
  OperationCompanyNumber?: string;
  DeliveryDate: Date | string;
  PurchaseOrderNumber?: string;
  OrderNumber?: string;
  TotalQuantityAccepted: number;
  TotalLinesAccepted: number;
  TotalQuantitySubmitted: number;
  TotalLinesSubmitted: number;
  OrderTotal: number;
  OrderTax: number;
  ConfirmationStatus: ConfirmationStatusType;
  HasMultipleShipments: boolean;
  ConfirmationErrorMessages: string[];
  ConfirmationInformationMessages: string[];
  OrderConfirmationProducts: OrderConfirmationProduct[];
  SubmittedByUserDisplayName?: string;
  SubmittedDateTime: Date | string;
  OrderName?: string;
  IsConfirmationReady: boolean;
  OrderCutoffDateTimeUTC: Date | string;
  OrderType?: OrderEntryType;
  FutureOrderSubmissionDate: Date | undefined;
}

export interface OrderConfirmationProduct {
  UnitOfMeasures: OrderConfirmationProductUnitOfMeasure[];
  LineTotalPrice: number;
  ErrorMessages: string[];
  InformationMessages: string[];
  BusinessUnitERPKey: number;
  ProductKey?: string;
  DisplayProductNumber?: string;
  DisplayProductDescription?: string;
  ProductBrand?: string;
  ProductPackSizes: string[];
  ProductImageUrlThumbnail?: string;
  IsShippedSeparately: boolean;
  MinCreateDate: Date | string;
  IsSpecialOrder: boolean;
  IsDropShip: boolean;
  IsVirtualWarehouse: boolean;
  ProductCutOff: string | Date | undefined;
  IsTemporarilyUnavaliable: boolean;
  HasMSDS: boolean;
  HasSubstituteProducts: boolean;
  CanOrder: boolean;
  ShipLaterMaxEstimatedDays: number;
  CanEditProductNumber: boolean;
  CanEditProductDescription: boolean;
  StateOfOriginAbbreviation?: string;
}

export interface OrderConfirmationProductUnitOfMeasure {
  UnitOfMeasure: number;
  ProductIsCatchWeight: boolean;
  PackSize?: string;
  Price?: number;
  ExtendedPrice?: number;
  ProductNumberDisplay?: string;
  QuantitySubmitted: number;
  QuantityAccepted: number;
}

export enum ConfirmationStatusType {
  Confirmed = 0,
  ConfirmedWithInformation = 1,
  ConfirmedWithExceptions = 2,
  Refused = 3,
  AwaitingConfirmation = 4,
  Cancelled = 5,
}

// Notifications
export interface AddOrderConfirmationNotificationRequest {
  CustomerId: string;
  Email?: string;
}

export interface DeleteOrderConfirmationNotificationRequest {
  OrderConfirmationNotificationId: string;
}

export interface GetOrderConfirmationNotificationsRequest {
  CustomerId: string;
}

export interface OrderConfirmationNotification {
  OrderConfirmationNotificationId: string;
  NotifyType: number;
  NotifyTypeDisplay?: string;
  NotifyValue?: string;
}

export interface OrderConfirmationNotifications {
  ResultObject: OrderConfirmationNotification[];
}

export interface UpdateOrderConfirmationNotificationRequest {
  OrderConfirmationNotificationId: string;
  Email?: string;
}

// Report
export interface GetOrderConfirmationReportRequest {
  orderEntryHeaderId: string;
  timeZone: string;
  isDummyConfirmation?: boolean;
}

export interface OrderConfirmationReport {
  FileContentType: string | null;
  FileName: string | null;
  FileData: string | null;
}
